import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./collegeProfile.module.css";
import {
  ERROR_MESSAGES,
  INSTITUTE_ERROR_MESSAGES,
} from "../../constants/formConstants.ts";
import {
  useGetLoggedInInstituteDetailQuery,
  useRegisterInstituteDetailMutation,
} from "../../slices/collegeApiSlice.ts";
import LoadingSpinner from "../../components/loading/LoadingSpinner.tsx";
import loading from "../../assets/images/loading.webp";
import { toast } from "sonner";

interface CollegeFormData {
  name: string;
  location: string;
  contactNumber: string;
  maxEducationLevel?: string;
  educationLevels: EducationLevel[];
  educationPrograms: EducationProgram[];
  foundedYear: number;
  longDescription?: string;
  hasOpenApplicationForms?: boolean;
  hostelAvailable: boolean;
  transportationAvailable: boolean;
  logoUrl?: string;
  imageUrl?: string;
  websiteUrl?: string;
  coverPhoto: File | null;
  logoPhoto: File | null;
}

enum EducationLevel {
  HIGH_SCHOOL = "HIGH_SCHOOL",
}

enum EducationProgram {
  SCIENCE = "SCIENCE",
  MANAGEMENT = "MANAGEMENT",
  HUMANITIES = "HUMANITIES",
  A_LEVELS = "A_LEVELS",
}

const data = {
  HIGH_SCHOOL: ["SCIENCE", "MANAGEMENT", "HUMANITIES", "A_LEVELS"],
};

const CollegeProfile = () => {
  const [isLogoLoading, setIsLogoLoading] = useState(true);
  const [isCollImgLoading, setIsCollImgLoading] = useState(true);

  const {
    data: profileData,
    error: getError,
    isLoading: getLoading,
    refetch,
  } = useGetLoggedInInstituteDetailQuery();

  const [hasData, setHasData] = useState<boolean>();

  const [logo, setLogo] = useState();
  const [collImg, setCollImg] = useState();

  useEffect(() => {
    if (profileData && profileData.data) {
      setFormData(profileData.data);
      setLogo(profileData.data.logoUrl);
      setCollImg(profileData.data.imageUrl);
      setHasData(true);
    }
  }, [profileData]);

  const [updateInstituteDetail] = useRegisterInstituteDetailMutation();

  const [formData, setFormData] = useState<CollegeFormData>({
    name: "",
    location: "",
    contactNumber: "",
    maxEducationLevel: "",
    educationLevels: [],
    educationPrograms: [],
    foundedYear: 0,
    longDescription: "",
    hasOpenApplicationForms: false,
    hostelAvailable: false,
    transportationAvailable: false,
    logoUrl: "",
    websiteUrl: "",
    coverPhoto: null,
    logoPhoto: null,
  });

  const [selectedEducationLevel, setSelectedEducationLevel] =
    useState<string>("");

  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const file = files ? files[0] : null;
      const url = file ? URL.createObjectURL(file) : null;
      setFormData((prevState) => ({
        ...prevState,
        [name]: file,
      }));

      if (name === "coverPhoto") {
        setCollImg(url);
      } else if (name === "logoPhoto") {
        setLogo(url);
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleProgrammeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      educationPrograms: prevState.educationPrograms.includes(value)
        ? prevState.educationPrograms.filter((item) => item !== value)
        : [...prevState.educationPrograms, value],
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors: Record<string, string> = {};
    if (!formData.name.trim()) {
      validationErrors.name = INSTITUTE_ERROR_MESSAGES.nameRequired;
    }
    if (!formData.location.trim()) {
      validationErrors.location = INSTITUTE_ERROR_MESSAGES.locationRequired;
    }
    if (!formData.foundedYear) {
      validationErrors.foundedYear = INSTITUTE_ERROR_MESSAGES.yearRequired;
    }
    if (!formData.coverPhoto) {
      validationErrors.coverPhoto = INSTITUTE_ERROR_MESSAGES.coverRequired;
    }
    if (!formData.logoPhoto) {
      validationErrors.logoPhoto = INSTITUTE_ERROR_MESSAGES.logoRequired;
    }

    if (!formData.maxEducationLevel) {
      validationErrors.maxEducationLevel =
        INSTITUTE_ERROR_MESSAGES.educationLevelRequired;
    }

    if (!formData.contactNumber.trim()) {
      validationErrors.contactNumber = ERROR_MESSAGES.phoneRequired;
    } else if (!isValidPhone(formData.contactNumber.trim())) {
      validationErrors.contactNumber = ERROR_MESSAGES.invalidPhone;
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length === 0) {
      const postData = new FormData();

      const { coverPhoto, logoPhoto, ...instituteDetails } = formData;

      console.log(formData);

      postData.append(
        "institute",
        new Blob([JSON.stringify(instituteDetails)], {
          type: "application/json",
        })
      );

      if (coverPhoto instanceof File) {
        postData.append("instituteImage", coverPhoto, coverPhoto.name);
      }
      if (logoPhoto instanceof File) {
        postData.append("instituteLogo", logoPhoto, logoPhoto.name);
      }

      toast.promise(
        updateInstituteDetail(postData).unwrap(),
        {
          loading: 'Creating profile...',
          success: 'Profile added successfully!',
          error: 'Profile error'
        }
      );      
    }
  };

  const isValidPhone = (phone: string): boolean => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const handleEducationLevelChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedLevel = e.target.value;
    setSelectedEducationLevel(selectedLevel);
    setFormData((prevState) => ({
      ...prevState,
      maxEducationLevel: selectedLevel,
    }));
  };

  if (getLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles.container}>
      <div
        className={classNames("container", styles.formContainer)}
        style={{ marginTop: "50px" }}
      >
        <form
          className={classNames("row g-3", styles.rowContainer)}
          onSubmit={handleSubmit}
        >
          <div className="col-md-6">
            <label
              htmlFor="name"
              className={classNames("form-label", styles.boldLabel)}
            >
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              disabled={hasData}
              className={classNames("form-control", styles.formControl)}
            />
            {errors.name && <span className={styles.error}>{errors.name}</span>}
          </div>

          <div className="col-md-6">
            <label
              htmlFor="location"
              className={classNames("form-label", styles.boldLabel)}
            >
              Location:
            </label>
            <input
              type="text"
              id="location"
              name="location"
              disabled={hasData}
              value={formData.location}
              onChange={handleChange}
              className={classNames("form-control", styles.formControl)}
            />
            {errors.location && (
              <span className={styles.error}>{errors.location}</span>
            )}
          </div>
          <div className="col-md-6">
            <label
              htmlFor="email"
              className={classNames("form-label", styles.boldLabel)}
            >
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              disabled={hasData}
              className={classNames("form-control", styles.formControl)}
            />
          </div>

          <div className="col-md-6">
            <label
              htmlFor="contactNumber"
              className={classNames("form-label", styles.boldLabel)}
            >
              Contact Number:
            </label>
            <input
              type="tel"
              id="contactNumber"
              name="contactNumber"
              value={formData.contactNumber}
              disabled={hasData}
              onChange={handleChange}
              className={classNames("form-control", styles.formControl)}
            />
            {errors.contactNumber && (
              <span className={styles.error}>{errors.contactNumber}</span>
            )}
          </div>

          <div className="col-md-4">
            <label
              htmlFor="maxEducationLevel"
              className={classNames("form-label", styles.boldLabel)}
            >
              Max Education Level:
            </label>
            <select
              id="maxEducationLevel"
              name="maxEducationLevel"
              value={formData.maxEducationLevel}
              disabled={hasData}
              onChange={handleEducationLevelChange}
              className={classNames("form-select", styles.formControl)}
            >
              <option value="">Select Education Level</option>
              <option value="HIGH_SCHOOL">High School</option>
            </select>
            {errors.maxEducationLevel && (
              <span className={styles.error}>{errors.maxEducationLevel}</span>
            )}
          </div>
          <div className="col-md-3">
            <label
              htmlFor="foundedYear"
              className={classNames("form-label", styles.boldLabel)}
            >
              Founded Year:
            </label>
            <input
              type="number"
              id="foundedYear"
              name="foundedYear"
              value={formData.foundedYear}
              onChange={handleChange}
              disabled={hasData}
              className={classNames("form-control", styles.formControl)}
            />
            {errors.foundedYear && (
              <span className={styles.error}>{errors.foundedYear}</span>
            )}
          </div>

          <div className="col-md-12">
            <label
              htmlFor="educationPrograms"
              className={classNames(
                "form-label",
                styles.boldLabel,
                styles.programmes
              )}
            >
              Education Programs:
            </label>
            <div className={classNames(styles.checkboxContainer)}>
              {data.HIGH_SCHOOL.map((subject) => (
                <div key={subject} className={classNames(styles.checkboxItem)}>
                  <input
                    type="checkbox"
                    id={subject}
                    name={subject}
                    value={subject}
                    disabled={hasData}
                    className={styles.checkbox}
                    checked={formData.educationPrograms.includes(subject)}
                    onChange={handleProgrammeChange}
                  />
                  <label htmlFor={subject}>{subject}</label>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.checkboxes}>
            {/* <div className="col-md-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="hasOpenApplicationForms"
                  name="hasOpenApplicationForms"
                  disabled={hasData}
                  checked={formData.hasOpenApplicationForms}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      hasOpenApplicationForms: e.target.checked,
                    }))
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="hasOpenApplicationForms"
                >
                  Has Open Application Forms
                </label>
              </div>
            </div> */}

            <div className="col-md-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={hasData}
                  id="hostelAvailable"
                  name="hostelAvailable"
                  checked={formData.hostelAvailable}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      hostelAvailable: e.target.checked,
                    }))
                  }
                />
                <label className="form-check-label" htmlFor="hostelAvailable">
                  Hostel Available
                </label>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="transportationAvailable"
                  name="transportationAvailable"
                  checked={formData.transportationAvailable}
                  disabled={hasData}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      transportationAvailable: e.target.checked,
                    }))
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="transportationAvailable"
                >
                  Transportation Available
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <label
              htmlFor="longDescription"
              className={classNames("form-label", styles.boldLabel)}
            >
              Description:
            </label>
            <textarea
              id="longDescription"
              name="longDescription"
              disabled={hasData}
              value={formData.longDescription}
              onChange={handleChange}
              className={classNames("form-control", styles.formControl)}
            ></textarea>
            {errors.longDescription && (
              <span className={styles.error}>{errors.longDescription}</span>
            )}
          </div>

          {!hasData && (
            <>
              <div className="col-md-6">
                <label
                  htmlFor="logoPhoto"
                  className={classNames("form-label", styles.boldLabel)}
                >
                  Upload Logo:
                </label>
                <input
                  type="file"
                  id="logoPhoto"
                  className={classNames("form-control", styles.formControl)}
                  name="logoPhoto"
                  onChange={handleChange}
                />
                {errors.logoPhoto && (
                  <span className={styles.error}>{errors.logoPhoto}</span>
                )}
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="coverPhoto"
                  className={classNames("form-label", styles.boldLabel)}
                >
                  Upload Cover Photo:
                </label>
                <input
                  type="file"
                  id="coverPhoto"
                  className={classNames("form-control", styles.formControl)}
                  name="coverPhoto"
                  onChange={handleChange}
                />
                {errors.coverPhoto && (
                  <span className={styles.error}>{errors.coverPhoto}</span>
                )}
              </div>



              <div className={classNames("col-12", styles.submitButton)}>
                <button
                  type="submit"
                  className={classNames("btn btn-success", styles.Submitbutton)}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </form>
      </div>

      {logo && collImg && (
        <div className={styles.pictureAndLabel}>
          <div className={styles.collegeImage}>
            <div className={styles.profilePictureContainer}>
              <img src={logo} 
              alt="Logo" 
              className={styles.profilePicture} 
              onLoad={(e) => e.currentTarget.style.opacity = '1'} />
            </div>
          </div>

          <div className={styles.profilePictureContainer}>
            <img
              src={collImg}
              alt="Cover Photo"
              className={styles.profilePicture}
              onLoad={(e) => e.currentTarget.style.opacity = '1'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CollegeProfile;

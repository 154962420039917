/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import styles from "./profile.module.css";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUploadMarkSheetsMutation,
} from "../../slices/profileApiSlice";
import avatar from "../../assets/images/DefaultUserAvatar.png";
import SEEMarksheet from "../../assets/images/seeMarksheet.png";
import LoadingSpinner from "../loading/LoadingSpinner.tsx";
import loading from "../../assets/images/loading.webp";
import { toast } from "sonner";

import {
  EducationLevel,
  Gender,
  SchoolExaminationBoard,
} from "../../enums/common";
import ConfirmationModal from "../../components/modal/confirmationModal/ConfirmationModal";
import { Alert } from "react-bootstrap";
import CollegeForm from "../../components/form/CollegeForm.tsx";
import classNames from "classnames";
interface ProfileProps {
  forConfirmation?: boolean;
}
interface StudentCreateDto {
  firstName: string;
  lastName: string;
  gender: Gender;
  dob: number;
  email: string;
  phoneNumber: string;
  educationLevel: EducationLevel;
  schoolExaminationBoard: SchoolExaminationBoard;
  schoolFaculty: string;
  schoolName: string;
  schoolLocation: string;
  schoolPassOutYear: number;
  schoolExpectedMarks?: string;
  schoolMarks?: string;
  fathersName?: string;
  fathersProfession?: string;
  mothersName?: string;
  mothersProfession?: string;
}

const Profile: React.FC<ProfileProps> = ({ forConfirmation }) => {

  const {
    data: profileData,
    error: getError,
    isLoading: getLoading,
    refetch,
  } = useGetProfileQuery();

  const [updateProfile, {}] = useUpdateProfileMutation();

  const [uploadMarkSheets, {}] = useUploadMarkSheetsMutation();

  const initialFormData: StudentCreateDto = {
    firstName: "",
    lastName: "",
    gender: "",
    dob: 0,
    email: "",
    phoneNumber: "",
    educationLevel: "",
    schoolExaminationBoard: "",
    schoolFaculty: "",
    schoolName: "",
    schoolLocation: "",
    schoolPassOutYear: 0,
    schoolExpectedMarks: "",
    schoolMarks: "",
    fathersName: "",
    fathersProfession: "",
    mothersName: "",
    mothersProfession: "",
  };

  useEffect(() => {
    if (profileData && profileData.data) {
      setFormData(profileData.data);
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
  }, [profileData]);

  const [formData, setFormData] = useState({ ...initialFormData });

  const initialAcademicData = {
    schoolName: "",
    schoolLocation: "",
    educationLevel: "",
    passOutYear: "",
    expectedMarks: "",
  };

  const [firstName, setFirstName] = useState(formData.firstName);
  const [lastName, setLastName] = useState(formData.lastName);
  const [email, setEmail] = useState(formData.email);
  const [phone, setPhone] = useState(formData.phoneNumber);
  const [gender, setGender] = useState(formData.gender);
  const [dateOfBirth, setDateOfBirth] = useState(formData.dob);

  const [academicFormData, setAcademicFormData] = useState({
    ...initialAcademicData,
  });
  const [schoolName, setSchoolName] = useState(academicFormData.schoolName);
  const [schoolLocation, setSchoolLocation] = useState(
    academicFormData.schoolLocation
  );
  const [educationLevel, setEducationLevel] = useState(
    academicFormData.educationLevel
  );
  const [passOutYear, setPassOutYear] = useState(academicFormData.passOutYear);
  const [expectedMarks, setExpectedYear] = useState(academicFormData.expectedMarks);

  const [schoolMarkSheet, setSchoolMarkSheet] = useState(null);
  const [profilePic, setProfilePic] = useState(null);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const [isEditable, setIsEditable] = useState(false);

  const validateForm = () => {
    let newErrors: Record<string, string> = {};

    if (!formData.firstName.trim())
      newErrors.firstName = "First name is required.";
    if (!formData.lastName.trim())
      newErrors.lastName = "Last name is required.";
    if (!formData.phoneNumber || !/^\d{10}$/.test(formData.phoneNumber))
      newErrors.phoneNumber = "A valid phone number is required.";
    if (!formData.gender.trim()) newErrors.gender = "Gender is required.";
    if (!formData.dob) newErrors.dob = "Date of birth is required.";
    if (!formData.fathersName.trim())
      newErrors.fathersName = "Fathers Name is required.";
    if (!formData.fathersProfession.trim())
      newErrors.fathersProfession = "Fathers Profession is required.";
    if (!formData.mothersName.trim())
      newErrors.mothersName = "Mothers Name is required.";
    if (!formData.mothersProfession.trim())
      newErrors.mothersProfession = "Mothers Profession is required.";

    if (!formData.schoolName.trim())
      newErrors.schoolName = "School name is required.";
    if (!formData.schoolLocation.trim())
      newErrors.schoolLocation = "School location is required.";
    if (!formData.educationLevel.trim())
      newErrors.educationLevel = "Education level is required.";
    if (!formData.schoolExaminationBoard.trim())
      newErrors.schoolExaminationBoard = "Examination Board is required.";
    if (!formData.schoolPassOutYear && formData.schoolPassOutYear === 0)
      newErrors.schoolPassOutYear = "Pass out year is required.";
    if (!formData.schoolExpectedMarks)
      newErrors.schoolExpectedMarks = "Expected Marks is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const enableEditing = () => {
    setIsEditable(true);
  };

  const saveChanges = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setIsEditable(false);
      const formDataObj = new FormData();
      if (profilePic) {
        formDataObj.append("profilePicture", profilePic);
      }
      if (schoolMarkSheet) {
        formDataObj.append("schoolMarkSheet", schoolMarkSheet);
      }
      const studentDetail = {
        ...formData,
        dob: formData.dob
          ? Math.floor(new Date(formData.dob).getTime() / 1000)
          : undefined,
        schoolPassOutYear: formData.schoolPassOutYear
          ? Number(formData.schoolPassOutYear)
          : undefined,
      };

      const studentDetailBlob = new Blob([JSON.stringify(studentDetail)], {
        type: "application/json",
      });

      formDataObj.append(
        "studentDetail",
        studentDetailBlob,
        "studentDetail.json"
      );

      const updateProfilePromise = () => updateProfile(formDataObj).unwrap();

      try {
        await updateProfile(formDataObj).unwrap();
        toast.success("Profile saved successfully!");
        setIsEditable(false);
      } catch (err) {
        const errorMessage = err.data?.message || err.message || "An unknown error occurred";
        toast.error(`Profile update failed! ${errorMessage}`);
        setIsEditable(true);
      }
    } else {
      toast.error("Validation error!");
    }
  };

  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    if (Object.keys(initialFormData).includes(name)) {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    }
  };

  // const handleCancel = () => {
  //   setIsEditable(false);
  //   setFormData({ ...initialFormData });
  //   setErrors({});
  // };

  const handleFileChange = (event) => {
    const { files } = event.target;
    if (files[0]) setProfilePic(files[0]);
  };

  const handleAddFileChange = (event) => {
    const { files } = event.target;
    if (files[0]) setSchoolMarkSheet(files[0]);
  };


  const handleMarkSheetChange = async (event) => {
    const { files } = event.target;
    if (files && files[0]) {
      setSchoolMarkSheet(files[0]);
      const formData = new FormData();
      formData.append("schoolMarkSheet", files[0]);
  
      const toastId = toast.loading('Uploading mark sheets...');
  
      uploadMarkSheets(formData)
        .unwrap()
        .then(() => {
          toast.dismiss(toastId);
          toast.success('Marksheet uploaded successfully!');
        })
        .catch((err) => {
          toast.dismiss(toastId);
          const errorMessage = err.data?.message || err.message || "An unknown error occurred";
          toast.error(`Failed to upload marksheet! ${errorMessage}`);
        });
    } else {
      toast.error("No file selected. Please select a file to upload.");
    }
  };
  
  

  function epochToDate(epochInSeconds) {
    const date = new Date(epochInSeconds * 1000);
    const formattedDate =
      date.getFullYear().toString() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");
    return formattedDate;
  }

  if (getLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      {!forConfirmation && isEditable && (
        <Alert key="primary" variant="primary">
          After submission, modifying your details won't be possible. However,
          you'll have the option to re-upload your marksheet.
        </Alert>
      )}

      {forConfirmation && isEditable && (
        <Alert key="primary" variant="primary">
          You need to submit your details in{" "}
          <Alert.Link href="/profile">Profile</Alert.Link> page to continue
        </Alert>
      )}
      {(!isEditable || (isEditable && !forConfirmation)) && (
        <div className={styles.profileContainer}>
          {/* <ConfirmationModal isOpen={false} onClose={function (): void {
        throw new Error("Function not implemented.");
      } } onSubmit={function (bulkRemarks: string): void {
        throw new Error("Function not implemented.");
      } }/> */}

          {isEditable && (
            <div className={styles.buttonContainers}>
              <button
                type="submit"
                className={styles.saveButton}
                onClick={saveChanges}
              >
                Submit
              </button>
            </div>
          )}
          <div className={styles.profilePicAndBasicInfoContainer}>
            <form onSubmit={saveChanges} className={styles.basicInfo}>
              {!forConfirmation &&   <h2>Basic Information {profileData.data ? `[${profileData.data.studentNo}]` : ''}</h2>}
              <div className="row g-3">
                <div className="col-md-6">
                  <div className={styles.field}>
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      disabled={!isEditable}
                      onChange={handleChange}
                    />
                    {errors.firstName && (
                      <div className={styles.error}>{errors.firstName}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={styles.field}>
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      disabled={!isEditable}
                      onChange={handleChange}
                    />
                    {errors.lastName && (
                      <div className={styles.error}>{errors.lastName}</div>
                    )}
                  </div>
                </div>
              </div>
              {!isEditable && (
                <div className={styles.field}>
                  <label>Email Address</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    disabled={!isEditable}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div className={styles.field}>
                <label>Phone</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
                {errors.phoneNumber && (
                  <div className={styles.error}>{errors.phoneNumber}</div>
                )}
              </div>
              <div className="row g-3">
                <div className="col-md-6">
                  <div className={styles.field}>
                    <label>Gender</label>
                    <select
                      name="gender"
                      value={formData.gender}
                      disabled={!isEditable}
                      onChange={handleChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHERS">Other</option>
                    </select>
                    {errors.gender && (
                      <div className={styles.error}>{errors.gender}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={styles.field}>
                    <label>Date Of Birth</label>
                    <input
                      type="date"
                      name="dob"
                      value={
                        !isEditable ? epochToDate(formData.dob) : formData.dob
                      }
                      disabled={!isEditable}
                      onChange={handleChange}
                    />
                    {errors.dob && (
                      <div className={styles.error}>{errors.dob}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={styles.field}>
                    <label>Father's Name</label>
                    <input
                      type="text"
                      name="fathersName"
                      value={formData.fathersName}
                      disabled={!isEditable}
                      onChange={handleChange}
                    />
                    {errors.fathersName && (
                      <div className={styles.error}>{errors.fathersName}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={styles.field}>
                    <label>Mother's Name</label>
                    <input
                      type="text"
                      name="mothersName"
                      value={formData.mothersName}
                      disabled={!isEditable}
                      onChange={handleChange}
                    />
                    {errors.mothersName && (
                      <div className={styles.error}>{errors.mothersName}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={styles.field}>
                    <label>Father's Profession</label>
                    <input
                      type="text"
                      name="fathersProfession"
                      value={formData.fathersProfession}
                      disabled={!isEditable}
                      onChange={handleChange}
                    />
                    {errors.fathersProfession && (
                      <div className={styles.error}>
                        {errors.fathersProfession}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={styles.field}>
                    <label>Mother's Profession</label>
                    <input
                      type="text"
                      name="mothersProfession"
                      value={formData.mothersProfession}
                      disabled={!isEditable}
                      onChange={handleChange}
                    />
                    {errors.mothersProfession && (
                      <div className={styles.error}>
                        {errors.mothersProfession}
                      </div>
                    )}
                  </div>
                </div>

                { isEditable && <div className="col-md-6">
                  <div className={styles.field}>
                    <label>Upload profile picture</label>
                    <input
                      type="file"
                      id="imgUpload"
                      name="profilePicture"
                      className={classNames("form-control", styles.formControl)}
                      onChange={handleFileChange}
                      disabled={!isEditable}
                    />
                  </div>
                </div>}
              </div>
            </form>

            {!forConfirmation && formData.profilePicture && (
              <>
                {formData.profilePicture && (
                  <div className={styles.verticalLine}></div>
                )}
                <div className={styles.pictureAndLabel}>
                  <div className={styles.profilePictureContainer}>
                    <img
                      src={
                        formData.profilePicture
                      }
                      alt="Profile"
                      className={`${styles.profilePicture} ${
                        isEditable ? styles.editableProfilePicture : ""
                      }`}
                      onLoad={(e) => (e.currentTarget.style.opacity = "1")}
                    />
                  </div>
                  <div className={styles.profileName}>
                    {formData.firstName
                      ? formData.firstName + " " + formData.lastName
                      : ""}
                  </div>
                  <div className={styles.profileDescription}>
                    {academicFormData.educationLevel
                      ? academicFormData.educationLevel + " Graduate"
                      : ""}
                  </div>
                </div>
              </>
            )}
          </div>
          <hr className={styles.horizontalLine} />
          <div className={styles.profilePicAndBasicInfoContainer}>
            <div className={styles.academicInfo}>
              {!forConfirmation && <h2>Academic Information</h2>}
              <div className="row g-3">
                <div className="col-md-6">
                  <div className={styles.field}>
                    <label>School Name</label>
                    <input
                      type="text"
                      name="schoolName"
                      value={formData.schoolName}
                      disabled={!isEditable}
                      onChange={handleChange}
                    />
                    {errors.schoolName && (
                      <div className={styles.error}>{errors.schoolName}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={styles.field}>
                    <label>School Location</label>
                    <input
                      type="text"
                      name="schoolLocation"
                      value={formData.schoolLocation}
                      disabled={!isEditable}
                      onChange={handleChange}
                    />
                    {errors.schoolLocation && (
                      <div className={styles.error}>
                        {errors.schoolLocation}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-md-6">
                  <div className={styles.field}>
                    <div>
                      <label>Education Level</label>
                      <select
                        name="educationLevel"
                        value={formData.educationLevel}
                        disabled={!isEditable}
                        onChange={handleChange}
                      >
                        <option value="">Select Level</option>
                        <option value="SCHOOL">School</option>
                        <option value="HIGH_SCHOOL">Highschool</option>
                        <option value="DIPLOMA">Diploma</option>
                        <option value="B_TECH">BTECH</option>
                      </select>
                      {errors.educationLevel && (
                        <div className={styles.error}>
                          {errors.educationLevel}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={styles.field}>
                    <div>
                      <label>Examination Board</label>
                      <select
                        name="schoolExaminationBoard"
                        value={formData.schoolExaminationBoard}
                        disabled={!isEditable}
                        onChange={handleChange}
                      >
                        <option value="">Select Board</option>
                        <option value="NEB">NEB</option>
                        <option value="CBSE">CBSE</option>
                        <option value="O_LEVELS">O-Levels</option>
                        <option value="ICSE">ICSE</option>
                      </select>
                      {errors.schoolExaminationBoard && (
                        <div className={styles.error}>
                          {errors.schoolExaminationBoard}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className={styles.field}>
                    <label htmlFor="schoolPassOutYear">Passout Year:</label>
                    <select
                      id="schoolPassOutYear"
                      name="schoolPassOutYear"
                      value={formData.schoolPassOutYear}
                      disabled={!isEditable}
                      onChange={handleChange}
                    >
                      <option value="">Select Passout Year</option>
                      {[...Array(5)].map((_, index) => {
                        const year = 2020 + index;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                    {errors.schoolPassoutYear && (
                      <div className={styles.error}>
                        {errors.schoolPassoutYear}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className={styles.field}>
                    <label>Expected Marks</label>
                    <input
                      type="number"
                      name="schoolExpectedMarks"
                      min="0.5"
                      max="4.0"
                      step="0.1"
                      value={formData.schoolExpectedMarks}
                      disabled={!isEditable}
                      onChange={handleChange}
                    />
                    {errors.schoolExpectedMarks && (
                      <div className={styles.error}>
                        {errors.schoolExpectedMarks}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.field}>
                    <label>Current Marks</label>
                    <input
                      type="number"
                      name="schoolMarks"
                      min="0.5"
                      max="4.0"
                      step="0.1"
                      value={formData.schoolMarks}
                      disabled={!isEditable}
                      onChange={handleChange}
                    />
                    {errors.schoolMarks && (
                      <div className={styles.error}>{errors.schoolMarks}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className={styles.field}>
                    <label>Upload School Marksheet</label>
                    <input
                      type="file"
                      id="markSheetUpload"
                      name="markSheetUpload"
                      className={classNames("form-control", styles.formControl)}
                      onChange={!isEditable ? handleMarkSheetChange : handleAddFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {formData.schoolMarkSheetUrl && (
              <>
                <div className={styles.verticalLine}></div>
                <div className={styles.allMarkSheetContainer}>
                  <div
                    className={styles.marksheetContainer}
                    style={{ cursor: "pointer" }}
                  >
                    <iframe
                      src={formData.schoolMarkSheetUrl}
                      frameBorder="0"
                      width="100%"
                      height="600"
                      className={styles.marksheetImage}
                      aria-label="SEE Marksheet"
                    >
                      Your browser does not support iframes.
                    </iframe>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;

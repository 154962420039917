import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Payment.module.css';
import qr from '../../../../assets/images/paymentQR.jpg';
import { Header } from '../../../../components/header/Header';

const Payment = () => {
  const location = useLocation();
  const { course } = location.state;

  return (
    <><Header page={'Payment'} /><div className={styles.paymentContainer}>
      <div className={styles.qrContainer}>
        <img src={qr} alt="QR Code" className={styles.qrImage} />
      </div>
      <div className={styles.detailsContainer}>
        <h2 className={styles.courseTitle}>{course.name}</h2>
        <h4 className={styles.courseDuration}>Course duration: {course.lengthDescription}</h4>
        <h4 className={styles.instructorName}>Instructor: {course.instructorName}</h4>
        <p className={styles.registrationDate}>Final Registration Date: {new Date(course.finalRegistrationDate).toLocaleDateString()}</p>
        <h3 className={styles.paymentAmount}>Amount: Nrs. {course.discountedPrice}</h3>
        <div className={styles.paymentInstructions}>
          <h3>Payment Instructions:</h3>
          <ol>
            <li>Scan the QR code on the left using your preferred payment app.</li>
            <li>Complete the payment process.</li>
            <li>Take a screenshot of the payment confirmation.</li>
            <li>Email the screenshot to <a href="mailto:eduversenepal@gmail.com">eduversenepal@gmail.com</a>.</li>
            <li>Include your personal email in the remarks.</li>
          </ol>
        </div>

        <div className={styles.additionalInfo}>
          <p>If you need assistance with the payment process or have any questions, feel free to reach out to us:</p>
          <ul>
            <li>Phone: +977 9766540865 / +977 9766540865 </li>
            <li>Email: <a href="mailto:eduversenepal@gmail.com.">eduversenepal@gmail.com.</a></li>
          </ul>
        </div>
      </div>
    </div></>
  );
};

export default Payment;

import React from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import styles from "./CourseCard.module.css";
import { styled } from "@mui/material/styles";
import { CourseDto } from "../../../../interfaces/course";
import graphicDesign from "../../../../assets/images/graphicDesign.png";
import fullStack from "../../../../assets/images/fullStack.webp";

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  border: "1px solid #eee",
  boxShadow: "none",
  margin: theme.spacing(1),
  width: "90%",
  height: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  padding: "0px",
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  }
}));


const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString(undefined, {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
};

const StyledCardActionArea = styled(CardActionArea)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: "auto",
  marginBottom: "auto",
  height: "100%",
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  width: "30%",
  height: "22.5%",
  objectFit: "contain",
  flexShrink: 0,
  maxWidth: "320px",
  maxHeight: "240px",
  minWidth: "160px",
  minHeight: "120px",
  marginTop: "auto",
  marginBottom: "auto",
}));

const StyledPricing = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  padding: theme.spacing(1),
  marginLeft: "auto",
  paddingRight: "30px",
  marginTop: "auto",
  marginBottom: "auto",
}));

const OriginalPrice = styled(Typography)(({ theme }) => ({
  textDecoration: "line-through",
  color: theme.palette.grey[500],
  textDecorationColor: "red",
  fontSize: "1.25rem",
}));

const DiscountedPrice = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1.4rem",
}));

const DiscountPercentage = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  padding: theme.spacing(0.5, 1),
  borderRadius: "4px",
  fontSize: "1rem",
  marginBottom: theme.spacing(1),
}));

const StyledButtonContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(1),
}));

const CourseCard: React.FC<{
  course: CourseDto;
  onCardClick: () => void;
  onPurchase: () => void;
}> = ({ course, onCardClick, onPurchase }) => {
  return (
    <StyledCard>
      <StyledCardActionArea disableRipple>
        <StyledCardMedia
          onClick={onCardClick}
          component="img"
          className={styles.courseImg}
          image={
            course.thumbnail ?? ""
          }
          alt={course.name}
        />
        <StyledCardContent onClick={onCardClick}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ fontWeight: "500" }}
            className={styles.courseName}
          >
            {course.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Final Registration Date: {formatDate(course.finalRegistrationDate)}
          </Typography>
        </StyledCardContent>

        <StyledPricing>
          {course.discountPercentage && (
            <DiscountPercentage>
              {course.discountPercentage}% off
            </DiscountPercentage>
          )}
          <OriginalPrice>{`Rs.${course.actualPrice.toFixed(2)}`}</OriginalPrice>
          <DiscountedPrice>{`Rs.${course.discountedPrice.toFixed(
            2
          )}`}</DiscountedPrice>
          <Button
            variant="contained"
            color="primary"
            onClick={onPurchase}
            style={{ marginTop: "5px" }}
          >
            Purchase
          </Button>
        </StyledPricing>
      </StyledCardActionArea>
      <StyledButtonContainer></StyledButtonContainer>
    </StyledCard>
  );
};

export default CourseCard;

import React, { useMemo } from "react";
import Modal from "react-modal";
import styles from "./collegeModal.module.css";
import classNames from "classnames";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import AdmissionTable from "../table/admissionTable";
import loading from "../../assets/images/loading.webp";

Modal.setAppElement("#root");

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  image: string;
  location: string;
  courses: string;
  description: string;
}

const CollegeModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  title,
  image,
  location,
  courses,
  description,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modalContent}
      overlayClassName={styles.modalBackdrop}
    >
      <div className={styles.modalHeader}>
        <button onClick={onClose} className={styles.closeButton}>
          &times;
        </button>
      </div>
      <div className={styles.modalBody}>
        <div className={styles.aboutCollege}>
          <div className={styles.modalImageContainer}>
            <img
              src={image}
              alt={title}
              className={styles.modalImage}
              onLoad={(e) => (e.currentTarget.style.opacity = "1")}
            />
          </div>

          <div className={styles.modalDetails}>
            <h2 className={styles.modalTitle}>{title}</h2>
            <p className={styles.modalLocation}>{location}</p>
            <p className={styles.modalCourses}>{courses}</p>
            <p className={styles.modalDescription}>
              Description: {description}
            </p>
          </div>
          <div />
        </div>
        <div className={styles.tableContainer}>
          <AdmissionTable selectedCollege={title} />
        </div>
      </div>
    </Modal>
  );
};

export default CollegeModal;

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FilterContextType {
  showFilter: boolean;
  toggleShowFilter: () => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const FilterProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const toggleShowFilter = () => setShowFilter(prev => !prev);

  return (
    <FilterContext.Provider value={{ showFilter, toggleShowFilter, searchQuery, setSearchQuery }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
};

import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
  flexRender,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFilter } from "../context/FilterContext.tsx";
import styles from "./addmissionTable.module.css";
import { Button, Form, Offcanvas } from "react-bootstrap";
import FilterOffcanvas from "../offcanvas/filterOffcanvas.tsx";
import ConfirmationOffcanvas from "../offcanvas/ConfirmationOffcanvas.tsx";
import {
  useGetApplicationCollegeViewQuery,
  useUpdateAdmissionApplicationMutation,
} from "../../slices/applicationSlice.ts";
import LoadingSpinner from "../../components/loading/LoadingSpinner.tsx";
import Error from "../../components/error/Error.tsx";
import NoDataFound from "../../components/noDataFound/NoDataFound.tsx";
import { toast } from "sonner";

interface Application {
  id: number;
  applicationName: string;
  instituteName: string;
  description: string;
  educationLevel: string;
  educationProgram: string;
  openDate: number;
  deadlineDate: number;
  eligibilityCriteria: string;
  fees: number;
  noticeUrl: string;
  examinationNoticeUrl: string;
}

interface AdmissionTableProps {
  selectedCollege?: string;
}

const coursesList: string[] = [
  "SCIENCE",
  "MANAGEMENT",
  "HUMANITIES",
  "A_LEVELS",
];

export default function AdmissionTableCollegeView({
  selectedCollege,
}: AdmissionTableProps) {
  
  const [applicationData, setApplicationData] = useState<Application[]>([]);
  const noticeFileInputRef = useRef(null);
  const examinationFileInputRef = useRef(null);

  const [admissionApplication, setAdmissionApplication] = useState({});
  const [admissionNotice, setAdmissionNotice] = useState(null);
  const [examinationNotice, setExaminationNotice] = useState(null);
  const [updateAdmissionApplication, { isLoading }] =
    useUpdateAdmissionApplicationMutation();

  const {
    data: apiData,
    error: getError,
    isLoading: getLoading,
  } = useGetApplicationCollegeViewQuery();

  useEffect(() => {
    if (apiData) {
      setApplicationData(apiData.data.content);
    }
  }, [apiData]);

  const [selectedApplication, setSelectedApplication] =
    useState<Application | null>(null);
  const { showFilter, toggleShowFilter } = useFilter();
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const handleApplyClick = (application: Application) => {
    setSelectedApplication(application);
    setShowConfirm(true);
  };

  const toggleShowConfirm = () => {
    setSelectedApplication(null);
    setShowConfirm(!showConfirm);
  };

  const filteredData = useMemo(() => {
    if (!selectedCollege) return applicationData;
    return applicationData.filter(
      (application) => application.instituteName === selectedCollege
    );
  }, [selectedCollege, applicationData]);

  const handleCourseSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const course = event.target.name;
    setSelectedCourses((prevSelectedCourses) =>
      prevSelectedCourses.includes(course)
        ? prevSelectedCourses.filter((c) => c !== course)
        : [...prevSelectedCourses, course]
    );
  };

  const handleFileInput = (info, fileKey) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";
    fileInput.onchange = (event) => handleChangeFile(event, info, fileKey);

    document.body.appendChild(fileInput);
    fileInput.click();
    fileInput.parentNode.removeChild(fileInput);
  };

  const handleChangeFile = async (event, info, fileKey) => {
    event.preventDefault();
    const { files } = event.target;

    if (files && files[0]) {
      const formData = new FormData();
      const jsonBlob = new Blob(
        [
          JSON.stringify({
            id: info.row.original.id,
            instituteApplicationNo: info.row.original.instituteApplicationNo,
            deadlineDate: info.row.original.deadlineDate,
          }),
        ],
        { type: "application/json" }
      );

      formData.append("admissionApplication", jsonBlob);
      formData.append(fileKey, files[0]);

      toast.promise(updateAdmissionApplication(formData).unwrap(), {
        loading: "Updating admission application...",
        success: "Admission application updated successfully!",
        error: "Failed to update admission application.",
      });
    } else {
      toast.error("No file selected");
    }
  };

  function epochToDate(epochInSeconds: number) {
    const date = new Date(epochInSeconds * 1000);
    const formattedDate =
      date.getFullYear().toString() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");
    return formattedDate;
  }

  const applyFilter = async () => {
    console.log("Applying filters with selected courses:", selectedCourses);
  };

  const columns: ColumnDef<Application>[] = useMemo(
    () => [
      {
        header: "Application Name",
        accessorKey: "applicationName",
      },

      {
        header: "Application Id",
        accessorKey: "instituteApplicationNo",
      },
      {
        header: "Open Date",
        accessorKey: "openDate",
        cell: (info) => `${epochToDate(info.row.original.openDate)}`,
      },
      {
        header: "Deadline",
        accessorKey: "deadlineDate",
        cell: (info) => `${epochToDate(info.row.original.deadlineDate)}`,
      },
      {
        header: "Form Fee",
        accessorKey: "fees",
        cell: (info) => `Rs.${info.row.original.fees}`,
      },
      {
        header: "Program",
        accessorKey: "educationProgram",
      },

      {
        header: "Eligibility Criteria",
        accessorKey: "eligibilityCriteria",
        cell: (info) => (
          <textarea
            rows={info.getValue() ? "2" : "1"}
            style={{
              width: "100%",
              border: "none",
              resize: "none",
              color: info.getValue() ? "initial" : "#888",
              fontStyle: info.getValue() ? "normal" : "italic",
              padding: "2px",
            }}
            title="Click and drag to view full eligibility criteria"
            disabled={true}
            defaultValue={info.getValue() || "No criteria"}
          />
        ),
      },

      // {
      //   header: "Description",
      //   accessorKey: "description",
      //   cell: (info) => (
      //     <textarea
      //       rows={info.getValue() ? "2" : "1"}
      //       style={{
      //         width: "100%",
      //         border: "none",
      //         resize: "none",
      //         color: info.getValue() ? "initial" : "#888",
      //         fontStyle: info.getValue() ? "normal" : "italic",
      //         padding: "2px",
      //       }}
      //       disabled={true}
      //       defaultValue={info.getValue() || "No description"}
      //     />
      //   ),
      // },

      {
        header: "Admission Notice",
        accessorKey: "noticeUrl",
        cell: (info) => {
          const noticeUrl = info.row.original.noticeUrl;
          return (
            <div className={classNames(styles.centerAlign)}>
              {noticeUrl ? (
                <>
                  <a href={noticeUrl} target="_blank" rel="noopener noreferrer">
                    <i
                      className={classNames(
                        "fa-solid fa-eye",
                        styles.noticeUrl
                      )}
                    ></i>
                  </a>
                  <i
                    className={classNames("fa fa-upload", styles.editButton)}
                    onClick={() => handleFileInput(info, "admissionNotice")}
                    title="Edit/Upload Notice"
                  ></i>
                </>
              ) : (
                <span></span>
              )}
            </div>
          );
        },
      },
      {
        header: "Examination Notice",
        accessorKey: "examinationNoticeUrl",
        cell: (info) => {
          const examinationNoticeUrl = info.row.original.examinationNoticeUrl;
          return (
            <div className={classNames(styles.centerAlign)}>
                <>
                  <a
                    href={examinationNoticeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className={classNames(
                        "fa-solid fa-eye",
                        styles.noticeUrl
                      )}
                    ></i>
                  </a>
                  <i
                    className={classNames("fa fa-upload", styles.editButton)}
                    onClick={() => handleFileInput(info, "examinationNotice")}
                    title="Edit/Upload Notice"
                  ></i>
                </>
            </div>
          );
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (getLoading) {
    return <LoadingSpinner />;
  }

  if (getError) {
    return <Error />;
  }

  if (apiData && apiData.data.content.length === 0) {
    return <NoDataFound text="No content" />;
  }

  return (
    <div className={styles.tableContainer}>
      {/* <ConfirmationOffcanvas
        showConfirmation={showConfirm}
        toggleShowConfirmation={toggleShowConfirm}
        applicationId={selectedApplication ? selectedApplication.id : 0}
        applicationName={
          selectedApplication ? selectedApplication.applicationName : ""
        }
        instituteName={
          selectedApplication ? selectedApplication.instituteName : ""
        }
        formFee={selectedApplication ? selectedApplication.fees : 0}
        program={
          selectedApplication ? selectedApplication.educationProgram : ""
        }
      /> */}
      <FilterOffcanvas
        showFilter={showFilter}
        toggleShowFilter={toggleShowFilter}
        selectedCourses={selectedCourses}
        handleCourseSelectionChange={handleCourseSelectionChange}
        applyFilter={applyFilter}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        dateFrom={dateFrom}
        dateTo={dateTo}
        coursesList={coursesList}
      />
      <table className={styles.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {typeof header.column.columnDef.header === "function"
                    ? header.column.columnDef.header(header.getContext())
                    : header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {cell.column.id === "apply" ? (
                    <div className={classNames(styles.centerAlign)}>
                      <button
                        className={styles.applyButton}
                        onClick={() => handleApplyClick(row.original)}
                      >
                        Apply
                      </button>
                    </div>
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseUrl } from '../constants/routeConstants';
import Cookies from 'js-cookie';


enum RoleType {
    STUDENT = "STUDENT",
    INSTITUTE = "INSTITUTE",
    ADMIN = "ADMIN",
    TEACHER = "TEACHER"
}

interface LoggedInUserDto {
    accountId: string;
    name: string;
    emailId: string;
    roles: RoleType[];
}

interface assignCourseToStudentsPayload{
    email: string;
    domainType: string; 
    domainId: string;
    paymentMethod: string; 
    transactionStatus: string; 
    amountPaid: number;
    refundedAmount: number; 
}

interface UpdateRoleResponse {
    data: LoggedInUserDto;
    message: string;
    success: boolean;
}

export const userApiSlice = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({ baseUrl: BaseUrl }),
    endpoints: (builder) => ({
        updateRoles: builder.mutation<UpdateRoleResponse, LoggedInUserDto>({
            query: (userDetails) => ({
                url: '/user/updateRole',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
                },
                body: userDetails,
            }),
        }),

        assignToStudent: builder.mutation<void, assignCourseToStudentsPayload>({
            query: (assignDetails) => ({
                url: '/transactions/addUserTransaction',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
                },
                body: assignDetails,
            }),
        }),

    }),
});

export const { useUpdateRolesMutation, useAssignToStudentMutation } = userApiSlice;

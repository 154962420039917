import React, { useState } from "react";
import SideBar from "../../components/sideBar/sidebar.tsx";
import {
  collegeMenuIcons,
  collegeMenuItems,
  collegeMenuHref,
  menuHref,
  menuIcons,
  menuItems,
} from "../../constants/sideBarConstants.ts";
import { Header } from "../../components/header/Header.tsx";
import AppliedAdmissionTable from "../../components/table/appliedAdmissionTable.tsx";
import { useUser } from "../../components/context/userContext";
import AppliedAdmissionStudentTable from "../../components/table/appliedAdmissionTableStudent.tsx";


export default function CollegePage() {

  const { user, name } = useUser();

  const isStudent = user?.isStudent || false;
  const isInstitute = user?.isInstitute || false;
  const isTeacher = user?.isTeacher || false;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = (data: any) => {
    setIsSidebarOpen(data);
  };

  return (
    <div className="page-container">
      <div className="sidebar-container">
        <SideBar
          menuItems={isStudent||isTeacher ? menuItems : collegeMenuItems}
          menuHrefs={isStudent||isTeacher ? menuHref : collegeMenuHref}
          menuIcons={isStudent||isTeacher ? menuIcons : collegeMenuIcons}
          userName= {name ? name : "Verse user"}
          userProfileUrl="https://preview.redd.it/mphoqu32zps71.png?auto=webp&s=b65bdbce745ba021669216c28fa454219ce08a35"
          currentTab={isStudent||isTeacher ? 5 : 3}
          isSideBarOpen={toggleSidebar}
        />
      </div>
      <div
        className={`content-container ${isSidebarOpen ? "sidebar-open" : ""}`}
      >
        <Header
          page="APPLIED ADMISSION"
          searchBar
          placeholder="Search Applications..."
        ></Header>
          {isStudent ? <AppliedAdmissionStudentTable /> : isInstitute ? <AppliedAdmissionTable /> : null}
      </div>
    </div>
  );
}

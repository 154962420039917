import React, { useState } from "react";
import SideBar from "../../components/sideBar/sidebar.tsx";
import {
  collegeMenuIcons,
  collegeMenuItems,
  collegeMenuHref,
  menuHref,
  menuIcons,
  menuItems,
  teacherMenuItems,
  teacherMenuHref,
  teacherMenuIcons
} from "../../constants/sideBarConstants.ts";
import { Header } from "../../components/header/Header.tsx";
import CourseGrid from "./components/CourseGrid/CourseGrid.tsx";
import { useUser } from "../../components/context/userContext";


export default function CoursePage() {

  const { user, name } = useUser();

  const isStudent = user?.isStudent || false;
  const isTeacher = user?.isTeacher || false;
  const isInstitute = user?.isInstitute || false;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = (data: any) => {
    setIsSidebarOpen(data);
  };


  return (
    <div className="page-container">
      <div className="sidebar-container">
        <SideBar
          menuItems={isStudent ? menuItems : (isTeacher ? teacherMenuItems : collegeMenuItems)}
          menuHrefs={isStudent ? menuHref : (isTeacher ? teacherMenuHref : collegeMenuHref)}
          menuIcons={isStudent ? menuIcons : (isTeacher ? teacherMenuIcons : collegeMenuIcons)}
          userName={name ? name : "Verse user"}
          userProfileUrl="https://preview.redd.it/mphoqu32zps71.png?auto=webp&s=b65bdbce745ba021669216c28fa454219ce08a35"
          currentTab={isStudent ? 2 : (isTeacher ? 1 : 3)}
          isSideBarOpen={toggleSidebar}
        />
      </div>
      <div
        className={`content-container ${isSidebarOpen ? "sidebar-open" : ""}`}
      >
        <Header
          page="Courses"
        ></Header>
        {!isStudent ? (
          <CourseGrid/>
        ) : (
          <CourseGrid/>
        )}
      </div>
    </div>
  );
}

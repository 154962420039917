import React, { useEffect, useState } from "react";
import styles from "./MyCourseLayout.module.css";
import { CourseDto } from "../../../../interfaces/course";
import { CourseMessageDto } from "../../../../interfaces/courseMessage";
import { MyCourseCard } from "../MyCoursesCard/MyCoursesCard";
import { MyCourseNoticeBoard } from "../MyCourseNoticeBoard/MyCourseNoticeBoard";
import {
  useAddMessageForCourseMutation,
  useGetCourseMessagesQuery,
  useGetCourseStudentsQuery,
} from "../../../../slices/courseSlice";
import Modal from "react-modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import NoDataFound from "../../../../components/noDataFound/NoDataFound";
import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";

Modal.setAppElement("#root");

const MyCourseLayout: React.FC<MyCourseLayoutProps> = ({ courses }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<CourseDto | null>(null);
  const [selectedCourseId, setSelectedCourseId] = useState<string>("");
  const [courseMessages, setCourseMessages] = useState<CourseMessageDto[]>([]);
  const [courseStudents, setCourseStudents] = useState([]);
  const [courseTitle, setCourseTitle] = useState<string>("");
  const [message, setMessage] = useState("");
  const [meetLink, setMeetLink] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [addMessageForCourse, { isLoading: isAddingMessage }] =
    useAddMessageForCourseMutation();
  const { data: messagesData, isLoading: isLoadingMessages } =
    useGetCourseMessagesQuery({
      courseId: selectedCourseId,
      pageNo: page,
      pageSize: rowsPerPage,
    });

  const { data: studentData, isLoading: isLoadingStudents } =
    useGetCourseStudentsQuery(selectedCourseId);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage-1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmitMessage = async () => {
    if (
      selectedCourse &&
      selectedCourse.id &&
      message.trim() !== "" &&
      meetLink.trim() !== ""
    ) {
      try {
        await addMessageForCourse({
          courseId: selectedCourse.id,
          message: message.trim(),
          meetLink: meetLink.trim(),
        }).unwrap();

        setMessage("");
        setMeetLink("");
        closeModal();
      } catch (error) {
        console.error("Failed to add message:", error);
      }
    } else {
      console.error("Message and Meet Link are required.");
    }
  };

  useEffect(() => {
    if (selectedCourseId && !isLoadingMessages && messagesData) {
      setTotalElements(messagesData.data.totalElements);
      setTotalPages(messagesData.data.totalPages);
      setCourseMessages(messagesData);
    }
  }, [selectedCourseId, messagesData, isLoadingMessages]);

  useEffect(() => {
    if (selectedCourseId && !isLoadingStudents && studentData) {
      setCourseStudents(studentData);
    }
  }, [selectedCourseId, studentData, isLoadingStudents]);

  const openModal = (course: CourseDto) => {
    setSelectedCourse(course);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setMessage("");
    setMeetLink("");
  };

  useEffect(() => {
    if (courses.length > 0 && !selectedCourseId) {
      const firstCourse = courses[0];
      setSelectedCourseId(firstCourse.id);
      setCourseMessages(firstCourse.messages || []);
      setCourseTitle(firstCourse.name);
    }
  }, [courses, selectedCourseId]);

  const handleCardClick = (course: CourseDto) => {
    setSelectedCourse(course);
    setSelectedCourseId(course.id);
  };

  if (courses && courses.length === 0) {
    return <NoDataFound text="No content" />
  }

  return (
    <div className={styles.layout}>
      <div className={styles.cardContainer}>
        <h1 className={styles.noticeBoardTitle}>Courses</h1>
        {courses.map((course: CourseDto) => (
          <div key={course.id} onClick={() => handleCardClick(course)}>
            <MyCourseCard
              course={course}
              isSelected={course.id === selectedCourseId}
            />
          </div>
        ))}
      </div>
      <div className={styles.noticeBoardContainer}>
        <MyCourseNoticeBoard
          messages={courseMessages?.data || []}
          students={courseStudents?.data || []}
          title={courseTitle}
          onAddMessage={() => openModal(selectedCourse ?? courses[0])}
          page={page}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          totalElements={totalElements}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
        contentLabel="Course Details"
      >
        <div className={styles.modalHeader}>
          <button onClick={closeModal} className={styles.closeButton}>
            &times;
          </button>
        </div>
        <div className={styles.modalBody}>
          {selectedCourse && (
            <div>
              <h2>{selectedCourse.name}</h2>
              <p>{selectedCourse.longDescription}</p>
              <TextField
                multiline
                rows={2}
                variant="outlined"
                placeholder="Enter message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
              />

              <TextField
                multiline
                rows={1}
                variant="outlined"
                placeholder="Paste your meeting link here"
                value={meetLink}
                onChange={(e) => setMeetLink(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                onClick={handleSubmitMessage}
                disabled={
                  isAddingMessage ||
                  message.trim() === "" ||
                  meetLink.trim() === ""
                }
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MyCourseLayout;

export const ERROR_MESSAGES = {
    firstNameRequired: "First name is required!!",
    lastNameRequired: "Last name is required!!",
    genderRequired: "Gender is required!!",
    dobRequired: "Date of Birth is required!!",
    emailRequired: "Email is required!!",
    invalidEmail: "Invalid email address!!",
    phoneRequired: "Phone number is required!!",
    invalidPhone: "Invalid phone number!!",
    gpaRequired: "GPA/Expected GPA is required!!",
    invalidGpa: "Invalid GPA!!",
    gpaRange: "GPA must be between 0.5 and 4.0!!",
  };
  
  export const INSTITUTE_ERROR_MESSAGES = {
    nameRequired: "Institution name is required!!",
    locationRequired: "Institution location is required!!",
    yearRequired: "Founded year is required!!",
    descriptionRequired: "Short description is required!!",
    logoRequired: "Logo is required!!",
    coverRequired: "Cover photo is required!!",
    educationLevelRequired: "Education level is required!!",
    applicationNameRequired: "Application Name is required!!",
    openDateRequired: "Open date is required!!",
    deadlineRequired: "Deadline is required!!",
    specificIdRequired: "Specific Id is required!!",
    programRequired: "Program is required!!"
  }

  export const FORM_TYPES = {
    plusTwo : "+2",
    SEE : "SEE"
  }
import { configureStore } from "@reduxjs/toolkit";
import { searchApiSlice } from "../slices/searchAPiSlice";
import { profileApiSlice } from "../slices/profileApiSlice";
import { authApiSlice } from "../slices/authApiSlice";
import { instituteApiSlice } from "../slices/collegeApiSlice";
import { applicationApiSlice } from "../slices/applicationSlice";
import { paymentSlice } from "../slices/paymentSlice";
import { courseApiSlice } from "../slices/courseSlice";
import { TransactionApiSlice } from "../slices/transactionSlice";
import { userApiSlice } from '../slices/adminSlice';
import { instructorApiSlice } from "../slices/instructorSlice";

export const store = configureStore({
  reducer: {
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    [searchApiSlice.reducerPath]: searchApiSlice.reducer,
    [profileApiSlice.reducerPath]: profileApiSlice.reducer,
    [instituteApiSlice.reducerPath]: instituteApiSlice.reducer,
    [applicationApiSlice.reducerPath]: applicationApiSlice.reducer,
    [paymentSlice.reducerPath]: paymentSlice.reducer,
    [courseApiSlice.reducerPath]: courseApiSlice.reducer,
    [TransactionApiSlice.reducerPath] : TransactionApiSlice.reducer,
    [userApiSlice.reducerPath] : userApiSlice.reducer,
    [instructorApiSlice.reducerPath] : instructorApiSlice.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApiSlice.middleware,
      searchApiSlice.middleware,
      profileApiSlice.middleware,
      instituteApiSlice.middleware,
      applicationApiSlice.middleware,
      paymentSlice.middleware,
      courseApiSlice.middleware,
      TransactionApiSlice.middleware,
      userApiSlice.middleware,
      instructorApiSlice.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import React, { useState } from "react";
import styles from "./MyCourseNoticeBoard.module.css";
import { CourseMessageDto } from "../../../../interfaces/courseMessage";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { Button, Pagination, TablePagination } from "@mui/material";
import { useUser } from "../../../../components/context/userContext";

interface MyCourseNoticeBoardProps {
  messages: CourseMessageDto[];
  students: string[];
  title: string;
  onAddMessage: () => void;
  page: number;
  rowsPerPage: number;
  totalPages: number;
  totalElements: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const MyCourseNoticeBoard: React.FC<MyCourseNoticeBoardProps> = ({
  messages,
  students,
  title,
  onAddMessage,
  page,
  rowsPerPage,
  totalPages,
  totalElements,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const messageDataList = messages?.content || [];
  const studentDataList = students || [];

  const [tabValue, setTabValue] = useState(0);

  const { user } = useUser();
  const isStudent = user?.isStudent || false;
  const isTeacher = user?.isTeacher || false;

  const formatDate = (date: string | number | Date) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Date(date).toLocaleString(undefined, options);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const cleanString = (input: string) => {
    return input.replace(/^"|"$/g, "");
  };

  if (!messageDataList.length) {
    return (
      <div className={styles.tabContainer}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="course tabs">
          <Tab label="Notice Board" />
          <Tab label="Subscribed Students" />
        </Tabs>

        {tabValue === 0 && (
          <div className={styles.noticeBoard}>
            <div className={styles.noticeBoardHeader}>
              <h1 className={styles.noticeBoardTitle}>No Messages</h1>
            </div>
            {!isStudent && (
              <Button
                variant="contained"
                color="primary"
                onClick={onAddMessage}
                style={{ marginTop: "20px" }}
              >
                Add Today's Link
              </Button>
            )}
          </div>
        )}

        {tabValue === 1 && (
          <div className={styles.noticeBoard}>
            <div className={styles.noticeBoardHeader}>
              <h1 className={styles.noticeBoardTitle}>
                Subscribed students [ {studentDataList.length} ]
              </h1>
            </div>
            {studentDataList.map((notice, index) => (
              <div key={index} className={styles.noticeSection}>
                <p className={styles.message}>
                  {index + 1}. {notice ? notice : "No subscribers"}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.tabContainer}>
      <Tabs value={tabValue} onChange={handleChange} aria-label="course tabs">
        <Tab label="Notice Board" />
        <Tab label="Subscribed Students" />
      </Tabs>

      {tabValue === 0 && (
        <div className={styles.noticeBoard}>
          <div className={styles.noticeBoardHeader}>
            <h1 className={styles.noticeBoardTitle}>Meeting Links</h1>
          </div>
          <div className={styles.messageBody}>
            {messageDataList.map((notice, index) => (
              <div key={index} className={styles.noticeSection}>
                <div className={styles.noticeContent}>
                  <span className={styles.noticeDate}>
                    {formatDate(notice.createdDate)}
                  </span>
                </div>
                <p className={styles.message}>
                  {notice.message
                    ? cleanString(notice.message)
                    : "No message provided."}
                </p>
                <a
                  href={cleanString(notice.meetLink)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.noticeLink}
                >
                  Join Meeting
                </a>
              </div>
            ))}
            {isTeacher && (
              <Button
                variant="contained"
                color="primary"
                onClick={onAddMessage}
                style={{ marginTop: "20px" }}
              >
                Add Today's Link
              </Button>
            )}
            <div className={styles.paginationControls}>
              <Pagination
                count={totalPages}
                page={page + 1}
                onChange={onPageChange}
                shape="rounded"
                sx={{ marginTop: 2 }}
              />

              <TablePagination
                component="div"
                count={totalElements}
                page={page}
                onPageChange={onPageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={onRowsPerPageChange}
                sx={{
                  ".MuiTablePagination-toolbar p": {
                    marginBottom: 0,
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}

      {tabValue === 1 && (
        <div className={styles.noticeBoard}>
          <div className={styles.noticeBoardHeader}>
            <h1 className={styles.noticeBoardTitle}>
              Subscribed students [ {studentDataList.length} ]
            </h1>
          </div>
          {studentDataList.map((notice, index) => (
            <div key={index} className={styles.noticeSection}>
              <p className={styles.message}>
                {index + 1}. {notice ? notice : "No subscribers"}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

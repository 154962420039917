import { createApi, fetchBaseQuery, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { BaseUrl } from '../constants/routeConstants';
import Cookies from 'js-cookie';

interface VerifyTokenPayload {
  token: string;
}

interface VerifyTokenResponse {
  data: any;
  userId: string;
}

interface LoggedInUserResponse {
  data: any;
  userData: any; 
}

export const authApiSlice = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: BaseUrl }),
  endpoints: (builder) => ({
    verifyGoogleToken: builder.mutation<VerifyTokenResponse, string>({
      query: (tokenPayload) => ({
        url: '/auth/google',
        method: 'POST',
        params: {
          token: tokenPayload,
        },
      }),
    }),
    fetchLoggedInUserDetails: builder.query<LoggedInUserResponse, void>({
      query: () => ({
        url: '/auth/loggedInUser',
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
    }),

    logoutUser: builder.mutation<void, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
    }),

  }),
});

export const { useVerifyGoogleTokenMutation, useFetchLoggedInUserDetailsQuery, useLogoutUserMutation } = authApiSlice;


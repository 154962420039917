import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseUrl } from '../constants/routeConstants';
import { Gender, EducationLevel, SchoolExaminationBoard } from '../enums/common';
import Cookies from 'js-cookie';

interface StudentCreateDto {
  firstName: string;
  lastName: string;
  gender: Gender;
  dob: number;
  email: string;
  phoneNumber: string;
  educationLevel: EducationLevel;
  schoolExaminationBoard: SchoolExaminationBoard;
  schoolFaculty: string;
  schoolName: string;
  schoolLocation: string;
  schoolPassOutYear: number;
  schoolExpectedGpa?: string;
  schoolGpa?: string;
  fathersName?: string;
  fathersProfession?: string;
  mothersName?: string;
  mothersProfession?: string;
}


export const profileApiSlice = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({ baseUrl: BaseUrl }),
  tagTypes: ['Profile'],
  endpoints: (builder) => ({
    getProfile: builder.query<StudentCreateDto, void>({
      query: () => ({
        url: "/student/",
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      providesTags: ['Profile'],
    }),
    updateProfile: builder.mutation<void, FormData>({
      query: (formData) => {
        return {
          url: '/student/create',
          method: 'POST',
          body: formData,
          headers: {
            'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
          },
        };
        
      },
      invalidatesTags: ['Profile'],
    }),
    uploadMarkSheets: builder.mutation<void, FormData>({
      query: (formData) => {
        return {
          url: '/student/upload',
          method: 'POST',
          body: formData,
          headers: {
            'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE') || '',
          },
        };
      },
      invalidatesTags: ['Profile'],
      transformResponse: (response: any, meta: any) => {
        if (!response.success) {
          throw new Error(response.message || "Unknown error during upload");
        }
        return response;
      },
    }),
    
  }),
});

export const { useGetProfileQuery, useUpdateProfileMutation, useUploadMarkSheetsMutation  } = profileApiSlice;

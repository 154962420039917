import React from 'react'
import Lottie from "lottie-react";
import noDataFound from "../../assets/animation/404Panda.json";
import styles from "./NotFound.module.css";

export default function NotFoundPage() {
  return (
    <div className={styles.noDataFoundContainer}>
        <Lottie loop={true} animationData={noDataFound} className={styles.lottieAnimation} />
        <a href="https://www.eduversenepal.com"><p className={styles.noDataText}>Let's Redirect you to the correct page</p></a>
    </div>
  )
}

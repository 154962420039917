import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import LoadingSpinner from "../../components/loading/LoadingSpinner.tsx";
import NoDataFound from "../../components/noDataFound/NoDataFound.tsx";
import { useGetApplicationsStudentViewQuery } from "../../slices/applicationSlice.ts";
import { useFilter } from "../context/FilterContext.tsx";
import FilterOffcanvas from "../offcanvas/filterOffcanvas.tsx";
import styles from "./appliedAdmissionTable.module.css";
import ApplicationDetailModal from "../../components/modal/applicationDetailModal/ApplicationDetailModal.tsx";
import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import Error from "../../components/error/Error.tsx";

interface Application {
  applicationId: number;
  applicationName: string;
  studentId: number;
  studentEmail: string;
  studentRegistrationNumber: string;
  remarks: string;
  examinationDate: string;
}

const coursesList: string[] = ["Science", "Management", "Arts", "Engineering"];

export default function AppliedAdmissionStudentTable() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState<
    string | null
  >(null);

  const { showFilter, toggleShowFilter } = useFilter();
  const [formState, setFormState] = useState({});

  const { searchQuery } = useFilter();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [filteredPrograms, setFilteredPrograms] = useState<string[]>([]);

  const {
    data: applicationData,
    isLoading,
    error: getError,
  } = useGetApplicationsStudentViewQuery({
    applicationName: searchQuery,
    educationPrograms: filteredPrograms,
    pageNo: page,
    pageSize: rowsPerPage,
  });

  const statusColorMap = {
    APPLIED: "#ebc474",
    COLLEGE_APPROVED: "#86e49d",
    COLLEGE_REJECTED: "#b30021",
  };

  const statusColor = {
    APPLIED: "black",
    COLLEGE_APPROVED: "#006b21",
    COLLEGE_REJECTED: "white",
  };

  const statusMessage = {
    APPLIED: "Awaiting response",
    COLLEGE_APPROVED: "Approved by institute",
    COLLEGE_REJECTED: "Rejected by institute",
  };

  useEffect(() => {
    if (applicationData && applicationData.data.content) {
      const initialFormState = applicationData.data.content.reduce(
        (acc, item) => ({
          ...acc,
          [item.applicationId]: {
            studentEmail: item.studentEmail,
            applicationName: item.applicationName,
            studentRegistrationNumber: item.studentRegistrationNumber || "",
            remarks: item.remarks || "",
            examinationDate: item.examinationDate || "",
          },
        }),
        {}
      );
      setFormState(initialFormState);

      setTotalElements(applicationData.data.totalElements);
      setTotalPages(applicationData.data.totalPages);
    }
  }, [applicationData]);

  const handleCourseSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const course = event.target.name;
    setSelectedCourses((prevSelectedCourses) =>
      prevSelectedCourses.includes(course)
        ? prevSelectedCourses.filter((c) => c !== course)
        : [...prevSelectedCourses, course]
    );
  };

  function epochToDate(epochInMilliseconds) {
    const date = new Date(epochInMilliseconds);
    const formattedDate =
      date.getFullYear().toString() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");
    return formattedDate;
  }
  

  const data = useMemo(
    () => (applicationData ? applicationData.data.content : []),
    [applicationData]
  );

  const applyFilter = async () => {
    setFilteredPrograms(selectedCourses);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns: ColumnDef<Application>[] = useMemo(
    () => [
      {
        header: "Institute Name",
        accessorKey: "instituteName",
      },
      {
        header: "Application Name",
        accessorKey: "applicationName",
        cell: (info) => (
          <span
            onClick={() => openModal(info.row.original.applicationId)}
            className={styles.cellLink}
          >
            {info.getValue()}
          </span>
        ),
      },
      {
        header: "Applied on",
        accessorKey: "appliedDate",
        cell: (info) => `${epochToDate(info.row.original.appliedDate)}`,
      },
      {
        header: "Amount Paid",
        accessorKey: "amountPaid",
        cell: (info) => `Rs.${info.row.original.amountPaid}`,
      },
      {
        header: "Registration Number",
        accessorKey: "studentRegistrationNo",
        cell: (info) =>
          info.getValue() ? (
            info.getValue()
          ) : (
            <span className={styles.defaultText}>
              To be entered by institute
            </span>
          ),
      },
      {
        header: "Remarks",
        accessorKey: "instituteRemarks",
        cell: (info) => (
          <textarea
            rows={info.getValue() ? "1" : "1"}
            style={{
              width: "100%",
              border: "none",
              
              color: info.getValue() ? "initial" : "#888",
              fontStyle: info.getValue() ? "normal" : "italic",
              padding: '3px',
            }}
            title="Click and drag to view full remarks"
            disabled={true}
            defaultValue={info.getValue() || "To be entered by institute"}
          />
        ),
      },
      {
        header: () => <div style={{ textAlign: "center" }}>Status</div>,
        accessorKey: "status",
        cell: (info) => (
          <div
            style={{
              backgroundColor: statusColorMap[info.getValue()] || "transparent",
              padding: ".4rem 0",
              maxWidth: "150px",
              borderRadius: "2rem",
              textAlign: "center",
              fontSize: "12.5px",
              margin: "0 auto",
              color: statusColor[info.getValue()] || "black",
            }}
          >
            {statusMessage[info.getValue()]}
          </div>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const openModal = (applicationId: string) => {
    setSelectedApplicationId(applicationId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedApplicationId(null);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (getError) {
    return (
      <>
        <FilterOffcanvas
          showFilter={showFilter}
          toggleShowFilter={toggleShowFilter}
          selectedCourses={selectedCourses}
          handleCourseSelectionChange={handleCourseSelectionChange}
          applyFilter={applyFilter}
          coursesList={coursesList}
        />
        <Error />
      </>
    );
  }

  if (applicationData && applicationData.data.content.length === 0) {
    return (
      <>
        <FilterOffcanvas
          showFilter={showFilter}
          toggleShowFilter={toggleShowFilter}
          selectedCourses={selectedCourses}
          handleCourseSelectionChange={handleCourseSelectionChange}
          applyFilter={applyFilter}
          coursesList={coursesList}
        />
        <NoDataFound text="No content" />
      </>
    );
  }

  return (
    <div className={styles.tableContainer}>
      <FilterOffcanvas
        showFilter={showFilter}
        toggleShowFilter={toggleShowFilter}
        selectedCourses={selectedCourses}
        handleCourseSelectionChange={handleCourseSelectionChange}
        applyFilter={applyFilter}
        coursesList={coursesList}
      />
      <table className={styles.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {typeof header.column.columnDef.header === "function"
                    ? header.column.columnDef.header(header.getContext())
                    : header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {selectedApplicationId && (
        <ApplicationDetailModal
          applicationId={selectedApplicationId}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        />
      )}
      <div className={styles.paginationControls}>
        <Pagination
          count={totalPages}
          shape="rounded"
          page={page}
          onChange={(event, value) => {
            setPage(value - 1);
          }}
        />
        <TablePagination
          component="div"
          count={totalElements}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-toolbar p": {
              marginBottom: 0,
            },
          }}
        />
      </div>
    </div>
  );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { InstructorDto, RestResponse } from '../interfaces/common';
import Cookies from 'js-cookie';
import { BaseUrl } from '../constants/routeConstants';

export const instructorApiSlice = createApi({
  reducerPath: 'instructorApi',
  baseQuery: fetchBaseQuery({ baseUrl: BaseUrl }),
  tagTypes: ['Instructor'],
  endpoints: (builder) => ({
    createInstructor: builder.mutation({
      query: (formData) => {
        return {
          url: '/instructor/create',
          method: 'POST',
          body: formData,
          headers: {
            'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
          },
        };
      },
      invalidatesTags: ['Instructor'],
    }),

    findOneInstructor: builder.query<RestResponse<InstructorDto>, string>({
      query: (id) => ({
        url: `/instructor/${id}`,
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      providesTags: ['Instructor'],
    }),

    findAllInstructors: builder.query<RestResponse<InstructorDto[]>, void>({
      query: () => ({
        url: '/instructor/findAll',
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      providesTags: ['Instructor'],
    }),
  }),
});

export const {
  useCreateInstructorMutation,
  useFindOneInstructorQuery,
  useFindAllInstructorsQuery,
} = instructorApiSlice;

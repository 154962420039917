import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseUrl } from "../constants/routeConstants";
import Cookies from "js-cookie";

interface InstituteCreateDto {
  name: string;
  location: string;
  email: string;
  contactNumber: string;
  maxEducationLevel: string;
  foundedYear: number;
  shortDescription: string;
  longDescription?: string;
  hasOpenApplicationForms: boolean;
  hostelAvailable: boolean;
  transportationAvailable: boolean;
  logoUrl: string;
  websiteUrl?: string;
  programmes: string[];
}

export const instituteApiSlice = createApi({
  reducerPath: "instituteApi",
  baseQuery: fetchBaseQuery({ baseUrl: BaseUrl }),
  tagTypes: ["Institute"],
  endpoints: (builder) => ({
    getLoggedInInstituteDetail: builder.query<InstituteCreateDto, void>({
      query: () => ({
        url: "/institute/",
        method: "GET",
        headers: {
          "X-DASHVERSE-TOKEN": Cookies.get("X-DASHVERSE"),
        },
      }),
      providesTags: ["Institute"],
    }),
    RegisterInstituteDetail: builder.mutation<
      InstituteCreateDto,
      Partial<InstituteCreateDto>
    >({
      query: (instituteDetails) => ({
        url: "/institute/register",
        method: "POST",
        headers: {
          "X-DASHVERSE-TOKEN": Cookies.get("X-DASHVERSE"),
        },
        body: instituteDetails,
      }),
      invalidatesTags: ["Institute"],
    }),
    getAllInstituteDetails: builder.query({
      query: ({
        query = "",
        educationPrograms = [],
        pageNumber = 0,
        pageSize = 25,
      }) => {
        const searchParams = new URLSearchParams({
          query,
          pageNumber: pageNumber.toString(),
          pageSize: pageSize.toString(),
        });
        educationPrograms.forEach((program) => {
          searchParams.append("educationProgram", program);
        });
        return {
          url: `/institute/search/?${searchParams}`,
          method: "GET",
          headers: {
            Accept: "application/json",
            "X-DASHVERSE-TOKEN": Cookies.get("X-DASHVERSE"),
          },
        };
      },
      providesTags: ["Institute"],
    }),
  }),
});

export const {
  useGetLoggedInInstituteDetailQuery,
  useRegisterInstituteDetailMutation,
  useGetAllInstituteDetailsQuery,
} = instituteApiSlice;

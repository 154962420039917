import React from "react";
import ReactModal from "react-modal";
import styles from "./ApplicationDetailModal.module.css";
import classNames from "classnames";
import { useGetApplicationByIdQuery } from "../../../slices/applicationSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import Error from "../../../components/error/Error";

interface ApplicationDetailModalProps {
  applicationId: string | null;
  isOpen: boolean;
  onRequestClose: () => void;
}

const ApplicationDetailModal: React.FC<ApplicationDetailModalProps> = ({
  applicationId,
  isOpen,
  onRequestClose,
}) => {
  const { data, error, isLoading } = useGetApplicationByIdQuery(
    applicationId ?? skipToken
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Application Details"
      ariaHideApp={false}
      className={styles.modalContent}
      overlayClassName={styles.modalBackdrop}
      
    >
      <div className={styles.modalHeader}>
        <h2>
          {isLoading
            ? "Loading..."
            : data
            ? "Application Details"
            : "No Data"}
        </h2>
        <button onClick={onRequestClose} className={styles.closeButton}>
          &times;
        </button>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <Error/>
      ) : (
        data && (
          <div className={styles.modalBody}>
            <div className="row">
              <div className={classNames("col-md-6", styles.colDetail)}>
                <p>
                  <span className={styles.label}>Application Name:</span>{" "}
                  <span className={styles.value}>
                    {data?.data?.applicationName}
                  </span>
                </p>
                <p>
                  <span className={styles.label}>Institute Name:</span>{" "}
                  <span className={styles.value}>
                    {data?.data?.instituteName}
                  </span>
                </p>
                <p>
                  <span className={styles.label}>Education Level:</span>{" "}
                  <span className={styles.value}>
                    {data?.data?.educationLevel}
                  </span>
                </p>
              </div>
              <div className={classNames("col-md-6", styles.colDetail)}>
                <p>
                  <span className={styles.label}>Education Program:</span>{" "}
                  <span className={styles.value}>
                    {data?.data?.educationProgram}
                  </span>
                </p>
                <p>
                  <span className={styles.label}>Fees:</span>{" "}
                  <span className={styles.value}>
                    Rs.{data?.data?.fees.toFixed(2)}
                  </span>
                </p>
              </div>
              <div className="col-md-12">
                <p>
                  <span className={styles.label}>Eligibility Criteria:</span>{" "}
                  <span className={styles.value}>
                    {data?.data?.eligibilityCriteria}
                  </span>
                </p>
                <p>
                  <span className={styles.label}>Description:</span>{" "}
                  <span className={styles.value}>
                    {data?.data?.description}
                  </span>
                </p>
              </div>
              <div className={styles.noticeLinks}>
                <a
                  href={data.data.noticeUrl}
                  className={styles.noticeLinkItem}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-solid fa-download"></i> &nbsp; Application Notice
                </a>
                <a
                  href={data.data.examinationNoticeUrl}
                  className={styles.noticeLinkItem}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-solid fa-download"></i> &nbsp; Examination Notice
                </a>
              </div>
            </div>
          </div>
        )
      )}
    </ReactModal>
  );
};

export default ApplicationDetailModal;

import React from "react";
import styles from "./LandingPage.module.css";
import backgroundImg from "../../assets/images/background.jpg";
import apply from "../../assets/animation/apply.json";
import login from "../../assets/animation/login.json";
import chill from "../../assets/animation/chill.json";
import study from "../../assets/animation/course.json"
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../components/context/userContext";
import CourseGrid from "../../pages/CoursePage/components/CourseGrid/CourseGrid";
import Session from "../../components/session/Session.tsx";

const LandingPage = () => {
  const navigate = useNavigate();

  const { user, name } = useUser();
  const mainContentStyle = {
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  function handleClick() {
    user?.isInstitute ? 
    navigate("/newapplication") :
    navigate("/institute");
  }

  return (
    <div className={styles.fullContainer}>
      <div className={styles.container}>
        <main className={styles.mainContent} style={mainContentStyle}>
          <div className={styles.overlay}></div>
          <section className={styles.hero}>
            <h2 className={styles.heroTitle}>Start Your High School Journey</h2>
            <p className={styles.heroText}>
              Connect with your school today. Start your application process in
              4 steps.
            </p>
            <button className={styles.heroBtn} onClick={handleClick}>
              Get Started
            </button>
          </section>
        </main>
      </div>

      <div className={styles.featuredSchoolsHeader}>
        <h3>Our Courses</h3>
      </div>
      <CourseGrid />

      <div className={styles.featuredSchoolsHeader}>
        <h3>Join Our Free Sessions</h3>
      </div>
      <Session />

      <div className={styles.featuredSchoolsHeader}>
        <h3>What We Do</h3>
      </div>

      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <div
            style={{ width: "100%", maxHeight: "200px", overflow: "hidden" }}
          >
            <Lottie
              loop={true}
              animationData={login}
              style={{ width: "100%", height: "250px" }}
            />
          </div>
          <div className={styles.cardLabel}>Login</div>
        </div>
        <div className={styles.card}>
          <div
            style={{ width: "100%", maxHeight: "200px", overflow: "hidden" }}
          >
            <Lottie
              loop={true}
              animationData={apply}
              style={{ width: "100%", height: "250px" }}
            />
          </div>
          <div className={styles.cardLabel}>Apply</div>
        </div>
        <div className={styles.card}>
          <div
            style={{ width: "100%", maxHeight: "200px", overflow: "hidden" }}
          >
            <Lottie
              loop={true}
              animationData={study}
              style={{ width: "100%", height: "250px" }}
            />
          </div>
          <div className={styles.cardLabel}>Online classes</div>
        </div>
        <div className={styles.card}>
          <div
            style={{ width: "100%", maxHeight: "200px", overflow: "hidden" }}
          >
            <Lottie loop={true} animationData={chill} />
          </div>
          <div className={styles.cardLabel}>Admit</div>
        </div>
      </div>

      <div className={styles.aboutUsContainer}>
        <div className={styles.featuredSchoolsHeader}>
          <h3>About Us</h3>
        </div>
        <p>
          Welcome to our platform, a dedicated space designed to simplify the
          high school application process for students across the globe. Our
          mission is to provide comprehensive support and guidance at every step
          of your high school journey. From the moment you begin exploring your
          options to the time you submit your applications, and even beyond, we
          are here to ensure a smooth and supportive journey. We believe that
          education is a cornerstone of personal development, and our goal is to
          make access to high-quality educational opportunities as
          straightforward as possible. Good luck!
        </p>
      </div>

      {/* <div className={styles.featuredSchoolsHeader}>
        <h3>Featured Schools</h3>
      </div>

      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <div className={styles.cardLabel}>hIgH sChOoL 1</div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardLabel}>hIgH sChOoL 2</div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardLabel}>hIgH sChOoL 3</div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardLabel}>hIgH sChOoL 4</div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardLabel}>hIgH sChOoL 5</div>
        </div>
      </div> */}
    </div>
  );
};

export default LandingPage;

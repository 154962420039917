import React, { useState } from "react";
import classNames from "classnames";
import styles from "./form.module.css";
import {
  ERROR_MESSAGES,
  INSTITUTE_ERROR_MESSAGES,
} from "../../constants/formConstants.ts";
import { useCreateApplicationMutation } from "../../slices/applicationSlice.ts";
import { toast } from "sonner";

interface ApplicationFormData {
  applicationName: string;
  instituteApplicationNo: string;
  description: string;
  educationLevel: string;
  educationProgram: string;
  openDate: number;
  deadlineDate: number;
  eligibilityCriteria: string;
  fees: number;
  noticeUrl: string;
  examinationNoticeUrl: string;
}

const ApplicationForm = () => {
  const [formData, setFormData] = useState<ApplicationFormData>({
    applicationName: "",
    instituteApplicationNo: "",
    description: "",
    educationLevel: "",
    educationProgram: "",
    openDate: 0,
    deadlineDate: 0,
    eligibilityCriteria: "",
    fees: 0,
    noticeUrl: "",
    examinationNoticeUrl: "",
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [selectedEducationLevel, setSelectedEducationLevel] =
    useState<string>("");

  const [admissionNotice, setAdmissionNotice] = useState(null);
  const [examinationNotice, setExaminationNotice] = useState(null);

  const data = {
    HIGH_SCHOOL: ["SCIENCE", "MANAGEMENT", "HUMANITIES", "A_LEVELS"],
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [createApplication, {}] = useCreateApplicationMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors: Record<string, string> = {};
    if (!formData.applicationName.trim()) {
      validationErrors.applicationName =
        INSTITUTE_ERROR_MESSAGES.applicationNameRequired;
    }
    if (!formData.openDate) {
      validationErrors.openDate = INSTITUTE_ERROR_MESSAGES.openDateRequired;
    }
    if (!formData.deadlineDate) {
      validationErrors.deadline = INSTITUTE_ERROR_MESSAGES.deadlineRequired;
    }
    if (!formData.educationLevel.trim()) {
      validationErrors.educationLevel =
        INSTITUTE_ERROR_MESSAGES.educationLevelRequired;
    }
    if (!formData.instituteApplicationNo) {
      validationErrors.instituteSpecificApplicationId =
        INSTITUTE_ERROR_MESSAGES.specificIdRequired;
    }
    if (!formData.educationProgram) {
      validationErrors.program = INSTITUTE_ERROR_MESSAGES.programRequired;
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const formDataObj = new FormData();
      if (admissionNotice) {
        formDataObj.append("admissionNotice", admissionNotice);
      }
      if (examinationNotice) {
        formDataObj.append("examinationNotice", examinationNotice);
      }
      const admissionApplication = {
        ...formData,
        openDate: formData.openDate
          ? Math.floor(new Date(formData.openDate).getTime() / 1000)
          : undefined,
          deadlineDate: formData.deadlineDate
          ? Math.floor(new Date(formData.deadlineDate).getTime() / 1000)
          : undefined,
      };
      const admissionApplicationBlob = new Blob([JSON.stringify(admissionApplication)], {
        type: "application/json",
      });
      formDataObj.append(
        "admissionApplication",
        admissionApplicationBlob,
        "admissionApplication.json"
      );
      
      const createApplicationPromise = () => createApplication(formDataObj).unwrap();

      toast.promise(
        createApplicationPromise(),
        {
          loading: 'Creating application...',
          success: (result) => {
            window.location.reload();
            return 'Created application successfully!';
          },
          error: (err) => `Create application failed! ${err.message || "An unknown error occurred"}`
        }
      );
      
      
    }
  };


  const handleProgrammeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      educationProgram: value,
    }));
  };

  const handleAdmissionNoticeChange = (event) => {
    const { files } = event.target;
    if (files[0]) setAdmissionNotice(files[0]);
  };

  const handleExaminationNoticeChange = (event) => {
    const { files } = event.target;
    if( files[0]) setExaminationNotice(files[0]);
  };

  const handleEducationLevelChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedLevel = e.target.value;
    setSelectedEducationLevel(selectedLevel);
    setFormData((prevState) => ({
      ...prevState,
      educationLevel: selectedLevel,
    }));
  };

  return (
    <div
      className={classNames("container", styles.formContainer)}
      style={{ maxWidth: "1200px", marginTop: "50px" }}
    >
      <form
        className={classNames("row g-3", styles.rowContainer)}
        onSubmit={handleSubmit}
      >
        <div className="col-md-6">
          <label
            htmlFor="applicationName"
            className={classNames("form-label", styles.boldLabel)}
          >
            Application Name:
          </label>
          <input
            type="text"
            id="applicationName"
            name="applicationName"
            value={formData.applicationName}
            onChange={handleChange}
            className={classNames("form-control", styles.formControl)}
          />
          {errors.applicationName && (
            <span className={styles.error}>{errors.applicationName}</span>
          )}
        </div>

        <div className="col-md-6">
          <label
            htmlFor="instituteApplicationNo"
            className={classNames("form-label", styles.boldLabel)}
          >
            Institution Specific Id:
          </label>
          <input
            type="text"
            id="instituteApplicationNo"
            name="instituteApplicationNo"
            value={formData.instituteApplicationNo}
            onChange={handleChange}
            className={classNames("form-control", styles.formControl)}
          />
          {errors.instituteApplicationNo && (
            <span className={styles.error}>
              {errors.instituteApplicationNo}
            </span>
          )}
        </div>

        <div className="col-md-6">
          <label
            htmlFor="openDate"
            className={classNames("form-label", styles.boldLabel)}
          >
            Application open date:
          </label>
          <input
            type="date"
            id="openDate"
            name="openDate"
            value={formData.openDate}
            onChange={handleChange}
            className={classNames("form-control", styles.formControl)}
          />
          {errors.openDate && (
            <span className={styles.error}>{errors.openDate}</span>
          )}
        </div>

        <div className="col-md-6">
          <label
            htmlFor="deadlineDate"
            className={classNames("form-label", styles.boldLabel)}
          >
            Application Deadline:
          </label>
          <input
            type="date"
            id="deadlineDate"
            name="deadlineDate"
            value={formData.deadlineDate}
            onChange={handleChange}
            className={classNames("form-control", styles.formControl)}
          />
          {errors.deadlineDate && (
            <span className={styles.error}>{errors.deadlineDate}</span>
          )}
        </div>

        <div className="col-md-6">
          <label
            htmlFor="EducationLevel"
            className={classNames("form-label", styles.boldLabel)}
          >
            Education Level:
          </label>
          <select
            id="educationLevel"
            name="educationLevel"
            value={formData.educationLevel}
            onChange={handleEducationLevelChange}
            className={classNames("form-select", styles.formControl)}
          >
            <option value="">Select Education Level</option>
            <option value="HIGH_SCHOOL">High School</option>
          </select>
          {errors.educationLevel && (
            <span className={styles.error}>{errors.educationLevel}</span>
          )}
        </div>
        <div className="col-md-4">
          <label
            htmlFor="foundedYear"
            className={classNames("form-label", styles.boldLabel)}
          >
            Application Form Fee (Nrs.):
          </label>
          <input
            type="number"
            id="fees"
            name="fees"
            value={formData.fees}
            onChange={handleChange}
            className={classNames("form-control", styles.formControl)}
          />
        </div>

        {selectedEducationLevel === "HIGH_SCHOOL" && (
          <div className="col-md-12">
            <label
              htmlFor="educationProgram"
              className={classNames("form-label", styles.boldLabel)}
            >
              Programme:
            </label>
            <div className={classNames(styles.checkboxContainer)}>
              {data.HIGH_SCHOOL.map((subject) => (
                <div key={subject} className={classNames(styles.checkboxItem)}>
                  <input
                    type="radio"
                    id={subject}
                    name="educationProgram"
                    value={subject}
                    className={styles.checkbox}
                    checked={formData.educationProgram === subject} 
                    onChange={handleProgrammeChange}
                  />
                  <label htmlFor={subject}>{subject}</label>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="col-md-12">
          <label
            htmlFor="eligibilityCriteria"
            className={classNames("form-label", styles.boldLabel)}
          >
            Admission Criteria:
          </label>
          <textarea
            id="eligibilityCriteria"
            name="eligibilityCriteria"
            value={formData.eligibilityCriteria}
            onChange={handleChange}
            className={classNames("form-control", styles.formControl)}
            title="Click and drag to view full eligibility criteria"
          ></textarea>
        </div>
        <div className="col-md-12">
          <label
            htmlFor="description"
            className={classNames("form-label", styles.boldLabel)}
          >
            Description:
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            title="Click and drag to view full description"
            className={classNames("form-control", styles.formControl)}
          ></textarea>
        </div>

        <div className="col-md-3">
          <label
            htmlFor="admissionNotice"
            className={classNames("form-label", styles.boldLabel)}
          >
            Upload Admission Notice:
          </label>
          <input
            type="file"
            id="admissionNotice"
            className={classNames("form-control", styles.formControl)}
            name="admissionNotice"
            onChange={handleAdmissionNoticeChange}
          />
        </div>
        <div className="col-md-3">
          <label
            htmlFor="examinationNotice"
            className={classNames("form-label", styles.boldLabel)}
          >
            Upload Examination Notice:
          </label>
          <input
            type="file"
            id="examinationNotice"
            className={classNames("form-control", styles.formControl)}
            name="examinationNotice"
            onChange={handleExaminationNoticeChange}
          />
        </div>

        <div className={classNames("col-md-6", styles.submitButton)}>
          <button
            type="submit"
            className={classNames("btn btn-success", styles.Submitbutton)}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ApplicationForm;

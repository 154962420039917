export const menuItems = [
    'Dashboard',
    'Profile', 
    'Courses', 
    'Institute', 
    'Admissions', 
    'Applied Admissions', 
    'My courses',
]

export const collegeMenuItems = [

    'Dashboard',
    'Profile', 
    'Admissions', 
    'Applied Admissions', 
    'New Application'
]

export const teacherMenuItems = [
    'Dashboard',
    'Courses',
    'My courses',
]

export const menuHref = [
    '/dashboard',
    '/profile', 
    '/courses', 
    '/institute', 
    '/admissions', 
    '/applied', 
    '/mycourses',
]

export const collegeMenuHref = [
    '/dashboard',
    '/profile', 
    '/admissions', 
    '/applied', 
    '/newapplication'
]

export const teacherMenuHref = [
    '/dashboard',
    '/courses', 
    '/mycourses',
]

export const menuIcons = [
    "fa-solid fa-chart-line",
    "fa-solid fa-user", 
    "fa-solid fa-person-chalkboard",
    "fa-solid fa-graduation-cap",    
    "fa-solid fa-list", 
    "fa-solid fa-school-circle-check",
    "fa-solid fa-database",
]

export const collegeMenuIcons = [
    "fa-solid fa-chart-line",
    "fa-solid fa-user",   
    "fa-solid fa-list", 
    "fa-solid fa-school-circle-check",
    "fa-solid fa-file-signature"
]


export const teacherMenuIcons = [
    "fa-solid fa-chart-line",
    "fa-solid fa-person-chalkboard",
    "fa-solid fa-database",
]
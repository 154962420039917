import React, {useState } from "react";
import SideBar from "../../components/sideBar/sidebar.tsx";
import {
  collegeMenuIcons,
  collegeMenuItems,
  collegeMenuHref,
  menuHref,
  menuIcons,
  menuItems,
} from "../../constants/sideBarConstants.ts";
import { Header } from "../../components/header/Header.tsx";
import ApplicationForm from "../../components/form/ApplicationForm.tsx";
import { useUser } from "../../components/context/userContext";
import NoDataFound from "../../components/noDataFound/NoDataFound.tsx";
interface ApplicationPage {
  userName: string;
  userProfileUrl: string;
  currentTab: number;
}

export default function ApplicationPage() {

  const { user, name } = useUser();
  const isStudent = user?.isStudent || false;
  const isTeacher = user?.isTeacher || false;
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = (data:any) => {
    setIsSidebarOpen(data);
  };
  
  return (
    <div className="page-container">
      <div className="sidebar-container">
        <SideBar
          menuItems={isStudent || isTeacher ? menuItems : collegeMenuItems}
          menuHrefs={isStudent || isTeacher ? menuHref : collegeMenuHref}
          menuIcons={isStudent || isTeacher ? menuIcons: collegeMenuIcons}
          u userName= {name ? name : "Verse user"}
          userProfileUrl="https://preview.redd.it/mphoqu32zps71.png?auto=webp&s=b65bdbce745ba021669216c28fa454219ce08a35"
          currentTab={4}
          isSideBarOpen={toggleSidebar}
        />
      </div>
      <div className={`content-container ${isSidebarOpen ? "sidebar-open" : ""}`}>
      <Header page="NEW ADMISSION APPLICATION"></Header>
      
      { !isStudent ? <ApplicationForm/> : <NoDataFound text="No content" />}
      </div>
    </div>
  );
}

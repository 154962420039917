import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { BaseUrl } from '../constants/routeConstants';

export const TransactionApiSlice = createApi({
  reducerPath: 'transactionApi',
  baseQuery: fetchBaseQuery({ baseUrl: BaseUrl }),
  endpoints: (builder) => ({
    
    getEsewaPaymentResponse: builder.query({
      query: (encodedData) => ({
        url: `/transactions/esewa?data=${encodeURIComponent(encodedData)}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
    }),

  }),
});

export const {
  useGetEsewaPaymentResponseQuery,
} = TransactionApiSlice;

export function createRoute(
  routes: string[],
  params: { [key: string]: string | number } = {}
): string {
  let routePath = routes.join("/");

  Object.keys(params).forEach((key) => {
    const placeholder = `:${key}`;
    const value = `${params[key]}`;
    routePath = routePath.replace(new RegExp(placeholder, "g"), value);
  });

  const queryParams = Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  if (queryParams) {
    routePath += `?${queryParams}`;
  }

  return routePath;
  //for example: if routes is ['college','details'] ani params is {sortBy: location} -> path will be college/details?sortBy=location
  //if route is ['student', 'profile'] ani params is {studentId: 12} -> path will be student/profile?studentId=12
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseUrl } from '../constants/routeConstants';
import Cookies from 'js-cookie';
import { AppliedApplicationStatus } from '../enums/common';

interface ApplicationCreateDto {
  name: string;
  location: string;
  email: string;
  contactNumber: string;
  maxEducationLevel: string;
  foundedYear: number;
  shortDescription: string;
  longDescription?: string;
  hasOpenApplicationForms: boolean;
  hostelAvailable: boolean;
  transportationAvailable: boolean;
  logoUrl: string;
  websiteUrl?: string;
  programmes: string[];
}

interface AppliedApplicationInstituteResponseDto {
  appliedApplicationId: string;
  studentRegistrationNumber: string;
  examinationDate?: number;
  instituteRemarks?: string;
  status?: AppliedApplicationStatus;
}

interface IdDisplayValueDto {
  id: string;
  displayValue: string;
}

export const applicationApiSlice = createApi({
  reducerPath: 'applicationApi',
  baseQuery: fetchBaseQuery({ baseUrl: BaseUrl }),
  tagTypes: ['application'],
  endpoints: (builder) => ({

    getApplication: builder.query({
      query: ({
        applicationName = "",
        educationPrograms = [],
        pageNumber = 0,
        pageSize = 25,
      }) => {
        const searchParams = new URLSearchParams({
          applicationName,
          pageNo: pageNumber.toString(),
          pageSize: pageSize.toString(),
        });
        educationPrograms.forEach((program) => {
          searchParams.append("educationPrograms", program);
        });
        return {
          url: `/admissionApplication/search/?${searchParams}`,
          method: "GET",
          headers: {
            Accept: "application/json",
            "X-DASHVERSE-TOKEN": Cookies.get("X-DASHVERSE"),
          },
        };
      },
      providesTags: ['application'],
    }),

    getApplicationCollegeView: builder.query<ApplicationCreateDto, void>({
      query: () => ({
        url: "/admissionApplication/institute/myApplications",
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      providesTags: ['application'],
    }),

    createApplication: builder.mutation<any, Partial<FormData>>({
      query: (admissionApplication) => ({
        url: "/admissionApplication/create",
        method: 'POST',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
        body: admissionApplication,
      }),
      invalidatesTags: ['application'],
    }),
    applyApplication: builder.mutation<any, { applicationId: string }>({
      query: ({ applicationId }) => ({
        url: "/apply",
        method: 'POST',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
        params: {
          applicationId,
        },
      }),
      invalidatesTags: ['application'],
    }),
    confirmStudentApply: builder.mutation<void, AppliedApplicationInstituteResponseDto[]>({
      query: (appliedApplicationInstituteResponseList) => ({
        url: "/appliedApplication/",
        method: 'POST',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
        body: appliedApplicationInstituteResponseList,
      }),
      invalidatesTags: ['application'],
    }),

    getApplicationsCollegeView: builder.query({
      query: ({ studentId, applicationId, status, pageNo = 0, pageSize = 25 }) => ({
        url: `/appliedApplication/appliedStudents/`,
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
        params: {
          studentId,
          applicationId,
          status,
          pageNo,
          pageSize,
        },
      }),
    }),

    getApplicationById: builder.query({
      query: (id) => ({
        url: `/admissionApplication/${id}`,
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      })
    }),

    updateAdmissionApplication: builder.mutation({
      query: (formData) => ({
        url: "/admissionApplication/update",
        method: 'POST',
        body: formData,
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      invalidatesTags: ['application'],
    }),

    getApplicationsStudentView: builder.query({
      query: ({
        applicationName = "",
        educationPrograms = [],
        pageNo = 0,
        pageSize = 25,
      }) => {
        const searchParams = new URLSearchParams({
          applicationName,
          pageNo: pageNo.toString(),
          pageSize: pageSize.toString(),
        });
        educationPrograms.forEach((program) => {
          searchParams.append("educationProgram", program);
        });
        return {
          url: `/appliedApplication/search/?${searchParams}`,
          method: "GET",
          headers: {
            Accept: "application/json",
            "X-DASHVERSE-TOKEN": Cookies.get("X-DASHVERSE"),
          },
        };
      },
      providesTags: ['application'],
    }),

    getAllInstitutesHavingAdmissionApplications: builder.query<IdDisplayValueDto[], void>({
      query: () => ({
        url: '/admissionApplication/filter/institute',
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
    }),


  }),
});

export const {
  useGetApplicationQuery,
  useGetApplicationCollegeViewQuery,
  useCreateApplicationMutation,
  useApplyApplicationMutation,
  useConfirmStudentApplyMutation,
  useGetApplicationsCollegeViewQuery,
  useGetApplicationsStudentViewQuery,
  useGetApplicationByIdQuery,
  useGetAllInstitutesHavingAdmissionApplicationsQuery,
  useUpdateAdmissionApplicationMutation,
} = applicationApiSlice;

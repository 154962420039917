import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { CourseDto } from '../interfaces/course';
import { BaseUrl } from '../constants/routeConstants';
import { CourseSessionCreateDto } from 'interfaces/courseSession';


interface RestResponse<T> {
  data: T;
  success: boolean;
  errorMessage?: string;
  errorCode?: string;
  meta?: Record<string, any>;
}

export const courseApiSlice = createApi({
  reducerPath: 'courseApi',
  baseQuery: fetchBaseQuery({ baseUrl: BaseUrl }),
  tagTypes: ['Course'],
  endpoints: (builder) => ({
    
    getCourseById: builder.query<CourseDto, string>({
      query: (id) => ({
        url: `/course/${id}`,
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      providesTags: ['Course'],
    }),

    createCourse: builder.mutation({
      query: (formData) => {
        return {
          url: '/course',
          method: 'POST',
          body: formData,
          headers: {
            'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
          },
        };
      },
      invalidatesTags: ['Course'],
    }),

    purchaseCourse: builder.mutation<CourseDto, { courseId: string }>({
      query: ({ courseId }) => ({
        url: `/course/${courseId}/purchase`,
        method: 'POST',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      invalidatesTags: ['Course'],
    }),

    addMessageForCourse: builder.mutation<string, { courseId: string; message: string; meetLink: string }>({
      query: ({ courseId, message, meetLink }) => ({
        url: `/course/${courseId}/message`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
        body: JSON.stringify({ message, meetLink }),
      }),
      invalidatesTags: ['Course'],
      transformResponse: (response: { data: string }, meta, arg) => {
        return response.data;
      }
    }),
    
    
    getCourseMessages: builder.query({
      query: ({
        pageNo = 0,
        pageSize = 25,
        courseId
      }) => {
        const searchParams = new URLSearchParams({
          pageNo: pageNo.toString(),
          pageSize: pageSize.toString(),
        });
        return {
          url: `/course/${courseId}/message?${searchParams}`,
          method: "GET",
          headers: {
            Accept: "application/json",
            "X-DASHVERSE-TOKEN": Cookies.get("X-DASHVERSE"),
          },
        };
      },
      providesTags: ['Course'],
    }),
    

    getCourseStudents: builder.query<CourseDto, string>({
      query: (courseId) => ({
        url: `/course/${courseId}/students`,
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      providesTags: ['Course'],
    }),

    getAllCourses: builder.query<{ data: CourseDto[]; total: number }, { pageNo?: number; pageSize?: number }>({
      query: ({ pageNo = 0, pageSize = 25 }) => ({
        url: `/course/getAll`,
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
        params: {
          pageNo,
          pageSize,
        },
      }),
      providesTags: ['Course'],
    }),

    getMyCourses: builder.query<{ data: CourseDto[]; total: number }, { pageNo?: number; pageSize?: number }>({
      query: ({ pageNo = 0, pageSize = 25 }) => ({
        url: `/course/myCourses`,
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
        params: {
          pageNo,
          pageSize,
        },
      }),
      providesTags: ['Course'],
    }),

    getCourseIdName: builder.query({
      query: () => ({
        url: `/course/getCourseIdName`,
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        }
      }),
      providesTags: ['Course'],
    }),

    assignInstructor: builder.mutation<RestResponse<string>, { userId: string; courseId: string }>({
      query: ({ userId, courseId }) => ({
        url: '/course/assignInstructor',
        method: 'POST',
        body: new URLSearchParams({ userId, courseId }),
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      transformResponse: (response: RestResponse<string>) => response,
      invalidatesTags: ['Course'],
    }),

    createCourseSession: builder.mutation({
      query: (formData) => {
        return {
          url: '/course/courseSession/create',
          method: 'POST',
          body: formData,
          headers: {
            'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
          },
        };
      },
      invalidatesTags: ['Course'],
    }),

    updateCourseSession: builder.mutation({
      query: (formData) => {
        return {
          url: '/course/courseSession/update',
          method: 'POST',
          body: formData,
          headers: {
            'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
          },
        };
      },
      invalidatesTags: ['Course'],
    }),

    registerCourseSession: builder.mutation<RestResponse<string>, { courseSessionId: string }>({
      query: ({ courseSessionId }) => ({
        url: '/course/courseSession/register',
        method: 'POST',
        body: new URLSearchParams({ courseSessionId }),
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      transformResponse: (response: RestResponse<string>) => response,
      invalidatesTags: ['Course'],
    }),

    deleteCourseSession: builder.mutation<RestResponse<string>, { courseSessionId: string }>({
      query: ({ courseSessionId }) => ({
        url: '/course/courseSession/delete',
        method: 'POST',
        body: new URLSearchParams({ courseSessionId }),
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      transformResponse: (response: RestResponse<string>) => response,
      invalidatesTags: ['Course'],
    }),

    //Should Return meet link, need to capture it and redirect it there.
    joinCourseSession: builder.mutation<RestResponse<string>, { courseSessionId: string }>({
      query: ({ courseSessionId }) => ({
        url: '/course/courseSession/join',
        method: 'POST',
        body: new URLSearchParams({ courseSessionId }),
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
      }),
      transformResponse: (response: RestResponse<string>) => response,
      invalidatesTags: ['Course'],
    }),

    getRegisterCourseSessions: builder.query<{ data: CourseSessionCreateDto[]; total: number }, { pageNo?: number; pageSize?: number }>({
      query: ({ pageNo = 0, pageSize = 25 }) => ({
        url: `/course/courseSession/register/active`,
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
        params: {
          pageNo,
          pageSize,
        },
      }),
      providesTags: ['Course'],
    }),

    getOngoingCourseSessions: builder.query<{ data: CourseSessionCreateDto[]; total: number }, { pageNo?: number; pageSize?: number }>({
      query: ({ pageNo = 0, pageSize = 25 }) => ({
        url: `/course/courseSession/ongoing`,
        method: 'GET',
        headers: {
          'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
        },
        params: {
          pageNo,
          pageSize,
        },
      }),
      providesTags: ['Course'],
    }),

  }),
});

export const {
  useGetCourseByIdQuery,
  useCreateCourseMutation,
  usePurchaseCourseMutation,
  useAddMessageForCourseMutation,
  useGetCourseMessagesQuery,
  useGetAllCoursesQuery,
  useGetMyCoursesQuery,
  useGetCourseStudentsQuery,
  useGetCourseIdNameQuery,
  useAssignInstructorMutation,
  useGetOngoingCourseSessionsQuery,
  useGetRegisterCourseSessionsQuery,
  useCreateCourseSessionMutation,
  useRegisterCourseSessionMutation,
  useJoinCourseSessionMutation
} = courseApiSlice;

import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactModal from "react-modal";
import { toast } from "sonner";
import LoadingSpinner from "../../components/loading/LoadingSpinner.tsx";
import ApplicationDetailModal from "../../components/modal/applicationDetailModal/ApplicationDetailModal.tsx";
import StudentDetailModal from "../../components/modal/studentDetailModal/StudentDetailModal.tsx";
import NoDataFound from "../../components/noDataFound/NoDataFound.tsx";
import {
  useConfirmStudentApplyMutation,
  useGetApplicationsCollegeViewQuery,
} from "../../slices/applicationSlice.ts";
import { useFilter } from "../context/FilterContext.tsx";
import BulkRemarksModal from "../modal/bulkRemarksModal/BulkRemarksModal.tsx";
import FilterOffcanvas from "../offcanvas/filterOffcanvas.tsx";
import styles from "./appliedAdmissionTable.module.css";

ReactModal.setAppElement("#root");

interface Application {
  id: number;
  applicationId: number;
  applicationName: string;
  studentEmail: string;
  StudentRegistrationNo: string;
  remarks: string;
  examinationDate: string;
  status: string;
}

interface StudentDetails {
  name: string;
  email: string;
  schoolExaminationBoard: string;
  schoolExpectedMarks: number;
  schoolMarks: number;
  schoolPassOutYear: number;
  profilePicture: string;
  markSheet: string;
  studentRegistrationNo?: string;
  examinationDate?: number;
  instituteRemarks?: string;
  status: string;
}

interface FormState {
  [key: number]: {
    applicationId: number;
    studentEmail: string;
    applicationName: string;
    studentRegistrationNo: string;
    remarks: string;
    examinationDate: string;
  };
}

const coursesList: string[] = [
  "SCIENCE",
  "MANAGEMENT",
  "HUMANITIES",
  "A_LEVELS",
];

export default function AppliedAdmissionTable() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState<
    string | null
  >(null);

  const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
  const [selectedStudentDetails, setSelectedStudentDetails] =
    useState<StudentDetails | null>(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [collegeStatus, setCollegeStatus] = useState<string>("");
  const [filteredPrograms, setFilteredPrograms] = useState<string[]>([]);

  const openModal = (applicationId: string) => {
    setSelectedApplicationId(applicationId);
    setModalIsOpen(true);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedApplicationId(null);
  };

  const openStudentModal = (student: StudentDetails) => {
    setSelectedStudentDetails(student);
    setIsStudentModalOpen(true);
  };

  const closeStudentModal = () => {
    setIsStudentModalOpen(false);
    setSelectedStudentDetails(null);
  };

  const [formState, setFormState] = useState<FormState>({});
  const [selectedRows, setSelectedRows] = useState<Application[]>([]);
  const { showFilter, toggleShowFilter } = useFilter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [statuses, setStatuses] = useState({});

  const handleStatusChange = (applicationId, newValue) => {
    setStatuses((prev) => ({
      ...prev,
      [applicationId]: newValue,
    }));
  };

  const {
    data: applicationData,
    isLoading,
    error,
  } = useGetApplicationsCollegeViewQuery({
    educationPrograms: filteredPrograms,
    pageNo: page,
    pageSize: rowsPerPage,
  });

  const [confirmStudentApply] = useConfirmStudentApplyMutation();

  useEffect(() => {
    if (error) {
      toast.error("Error fetching applications");
    }
  }, [error]);

  useEffect(() => {
    if (
      applicationData &&
      applicationData.data &&
      applicationData.data.content
    ) {
      const initialFormState = applicationData.data.content.reduce(
        (acc, item) => ({
          ...acc,
          [item.applicationId]: {
            applicationId: item.applicationId,
            studentEmail: item.studentEmail,
            applicationName: item.applicationName,
            StudentRegistrationNo: item.studentRegistrationNo,
            remarks: item.instituteRemarks,
          },
        }),
        {}
      );
      setFormState(initialFormState);
      setTotalElements(applicationData.data.totalElements);
      setTotalPages(applicationData.data.totalPages);
    }
  }, [applicationData]);

  const data = useMemo(
    () => (applicationData ? applicationData.data.content : []),
    [applicationData]
  );

  const handleInputChange = useCallback(
    (id: number, field: string, value: string) => {
      setFormState((prevState) => {
        const newState = {
          ...prevState,
          [id]: {
            ...(prevState as Record<number, any>)[id],
            [field]: value,
          },
        };
        return newState;
      });
    },
    [setFormState, formState]
  );

  useEffect(() => {
    if (
      applicationData &&
      applicationData.data &&
      applicationData.data.content
    ) {
      const initialStatuses = applicationData.data.content.reduce(
        (acc, app) => ({
          ...acc,
          [app.id]: app.status === "APPLIED" ? "COLLEGE_APPROVED" : app.status,
        }),
        {}
      );
      setStatuses(initialStatuses);
    }
  }, [applicationData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowSelect = (row: Application, isChecked: boolean) => {
    setSelectedRows((currentSelectedRows) => {
      const updatedSelectedRows = isChecked
        ? [...currentSelectedRows, row]
        : currentSelectedRows.filter(
            (selectedRow) => selectedRow.id !== row.id
          );

      const newFormState = { ...formState } as FormState;
      updatedSelectedRows.forEach((selectedRow) => {
        if (!newFormState[selectedRow.id]) {
          newFormState[selectedRow.id] = {
            applicationId: selectedRow.applicationId,
            studentEmail: selectedRow.studentEmail,
            applicationName: selectedRow.applicationName,
            StudentRegistrationNumber: selectedRow.studentRegistrationNo,
            instituteRemarks: selectedRow.instituteRemarks,
          };
        }
      });
      setFormState(newFormState);
      return updatedSelectedRows;
    });
  };

  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedRows(data);
    } else {
      setSelectedRows([]);
    }
  };

  const handleCourseSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const course = event.target.name;
    setSelectedCourses((prevSelectedCourses) =>
      prevSelectedCourses.includes(course)
        ? prevSelectedCourses.filter((c) => c !== course)
        : [...prevSelectedCourses, course]
    );
  };

  const applyFilter = async () => {
    setFilteredPrograms(selectedCourses);
  };

  const handleApply = async () => {
    const applicationsToSubmit = selectedRows.map((row) => {
      const currentFormData = formState[row.id] || {};
      const formData = {
        appliedApplicationId: String(row.id),
        studentRegistrationNumber:
          currentFormData.StudentRegistrationNo ||
          currentFormData.StudentRegistrationNumber ||
          "",
        instituteRemarks:
          currentFormData.remarks || currentFormData.instituteRemarks || "",
        status: statuses[row.id] || "COLLEGE_APPROVED",
      };
      return formData;
    });

    await toast.promise(confirmStudentApply(applicationsToSubmit).unwrap(), {
      loading: "Submitting applications...",
      success: (result) => {
        window.location.reload();
        return "Applications submitted successfully!";
      },
      error: "Failed to submit applications",
    });
  };

  const columns: ColumnDef<Application>[] = useMemo(
    () => [
      {
        id: "selection",
        header: () => (
          <input
            type="checkbox"
            onChange={(e) => handleSelectAll(e.target.checked)}
            checked={selectedRows.length === data.length}
            className={styles.bigCheckbox}
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            checked={selectedRows.some((r) => r.id === row.original.id)}
            onChange={(e) => handleRowSelect(row.original, e.target.checked)}
            className={styles.bigCheckbox}
          />
        ),
      },
      {
        header: "Student No",
        accessorKey: "studentNo",
      },
      {
        header: "Student Name",
        accessorKey: "name",
        cell: ({ row }) => (
          <span
            onClick={() =>
              openStudentModal({
                name: row.original.name,
                email: row.original.email,
                schoolExaminationBoard: row.original.schoolExaminationBoard,
                schoolExpectedMarks: row.original.schoolExpectedMarks,
                schoolMarks: row.original.schoolMarks,
                schoolPassOutYear: row.original.schoolPassOutYear,
                profilePicture: row.original.profilePicture,
                markSheet: row.original.markSheet,
              })
            }
            style={{
              cursor: "pointer",
              color: "blue",
            }}
          >
            {row.original.name}
          </span>
        ),
      },
      {
        header: "Application Name",
        accessorKey: "applicationName",
        cell: (info) => (
          <span
            onClick={() => openModal(info.row.original.applicationId)}
            style={{
              cursor: "pointer",
              color: "blue",
            }}
          >
            {info.getValue()}
          </span>
        ),
      },
      {
        header: "Registration Number",
        accessorKey: "studentRegistrationNo",
        cell: (info) => (
          <input
            type="text"
            // value={
            //   formState[info.row.original.applicationId]
            //     ?.StudentRegistrationNo || ""
            // }
            defaultValue={
              formState[info.row.original.id]?.StudentRegistrationNo ??
              info.getValue()
            }
            disabled={!selectedRows.some((r) => r.id === info.row.original.id)}
            className={styles.registrationNumberInput}
            onBlur={(e) =>
              handleInputChange(
                info.row.original.id,
                "StudentRegistrationNo",
                e.target.value
              )
            }
          />
        ),
      },
      {
        header: "Remarks",
        accessorKey: "instituteRemarks",
        cell: (info) => {
          const id = info.row.original.id;
          return (
            <textarea
              name="remarks"
              disabled={
                !selectedRows.some((r) => r.id === info.row.original.id)
              }
              // value={formState[applicationId]?.remarks || ""}
              defaultValue={formState[id]?.remarks ?? info.getValue()}
              id="remarks"
              rows={1}
              title="Click and drag to view full remarks"
              className={styles.remarksInput}
              onBlur={(e) => handleInputChange(id, "remarks", e.target.value)}
            />
          );
        },
      },
      {
        header: () => <div style={{ textAlign: "center" }}>Status</div>,
        accessorKey: "status",
        cell: (info) => {
          const currentStatus =
            statuses[info.row.original.id] ||
            (info.getValue() === "APPLIED"
              ? "COLLEGE_APPROVED"
              : info.getValue());

          return (
            <select
              value={currentStatus}
              onChange={(e) =>
                handleStatusChange(info.row.original.id, e.target.value)
              }
              disabled={
                !selectedRows.some((r) => r.id === info.row.original.id)
              }
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "5px",
                borderColor: "rgba(0,0,0,0.2)",
              }}
            >
              <option value="COLLEGE_APPROVED">College Approved</option>
              <option value="COLLEGE_REJECTED">College Rejected</option>
            </select>
          );
        },
      },
    ],
    [
      selectedRows,
      data.length,
      handleSelectAll,
      handleRowSelect,
      formState,
      handleInputChange,
    ]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleBulkRemarksSubmit = (bulkRemarks: string) => {
    const updatedFormState = { ...formState };
    selectedRows.forEach((row) => {
      const id = row.id;
      if (updatedFormState[id]) {
        updatedFormState[id].remarks = bulkRemarks;
      } else {
        updatedFormState[id] = {
          ...row,
          remarks: bulkRemarks,
        };
      }
    });

    setFormState(updatedFormState);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (applicationData && applicationData.data.content.length === 0) {
    return <NoDataFound text="No content" />;
  }

  return (
    <div className={styles.tableContainer}>
      <BulkRemarksModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleBulkRemarksSubmit}
      />
      {selectedRows.length > 0 && (
        <div className={styles.floatingButtons}>
          <button
            className={styles.floatingSubmitButton}
            onClick={handleApply}
            aria-label="Submit selected rows"
          >
            Submit
          </button>
          {selectedRows.length > 1 && (
            <button
              className={styles.floatingSubmitButton}
              onClick={handleOpenModal}
              aria-label="Submit selected rows"
            >
              Bulk Remarks
            </button>
          )}
        </div>
      )}
      <FilterOffcanvas
        showFilter={showFilter}
        toggleShowFilter={toggleShowFilter}
        selectedCourses={selectedCourses}
        handleCourseSelectionChange={handleCourseSelectionChange}
        applyFilter={applyFilter}
        coursesList={coursesList}
      />
      <table className={styles.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {typeof header.column.columnDef.header === "function"
                    ? header.column.columnDef.header(header.getContext())
                    : header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {selectedApplicationId && (
        <ApplicationDetailModal
          applicationId={selectedApplicationId}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        />
      )}

      {selectedStudentDetails && (
        <StudentDetailModal
          isOpen={isStudentModalOpen}
          onRequestClose={closeStudentModal}
          studentDetails={selectedStudentDetails}
        />
      )}

      <div className={styles.paginationControls}>
        <Pagination
          count={totalPages}
          shape="rounded"
          page={page}
          onChange={(event, value) => {
            setPage(value - 1);
          }}
        />
        <TablePagination
          component="div"
          count={totalElements}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-toolbar p": {
              marginBottom: 0,
            },
          }}
        />
      </div>
    </div>
  );
}

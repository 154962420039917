import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button, Form, Offcanvas } from "react-bootstrap";
import styles from "./filterOffcanvas.module.css";
import { useGetAllInstitutesHavingAdmissionApplicationsQuery } from "../../slices/applicationSlice";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import Error from "../../components/error/Error";

interface FilterOffcanvasProps {
  showFilter: boolean;
  toggleShowFilter: () => void;
  selectedCourses: string[];
  handleCourseSelectionChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  applyFilter: () => void;
  setDateFrom?: React.Dispatch<React.SetStateAction<string>>;
  setDateTo?: React.Dispatch<React.SetStateAction<string>>;
  dateFrom?: string;
  dateTo?: string;
  coursesList: string[];
}

const FilterOffcanvas: React.FC<FilterOffcanvasProps> = ({
  showFilter,
  toggleShowFilter,
  selectedCourses,
  handleCourseSelectionChange,
  applyFilter,
  setDateFrom,
  setDateTo,
  dateFrom,
  dateTo,
  coursesList,
}) => {
  const [value, setValue] = useState(0);
  const [searchInstitute, setSearchInstitute] = useState<string>("");
  const [selectedInstitutes, setSelectedInstitutes] = useState<string[]>([]);

  const {
    data: institutesData,
    isFetching,
    error,
  } = useGetAllInstitutesHavingAdmissionApplicationsQuery();

  const institutes = institutesData?.data ?? [];

  const filteredInstitutes = searchInstitute
    ? institutes.filter((institute) =>
        institute.displayValue
          .toLowerCase()
          .includes(searchInstitute.toLowerCase())
      )
    : institutes;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleInstituteSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const id = event.target.id;
    if (event.target.checked) {
      setSelectedInstitutes((prev) => [...prev, id]);
    } else {
      setSelectedInstitutes((prev) => prev.filter((item) => item !== id));
    }
  };

  const handleApplyFilter = () => {
    applyFilter();
  };

  if (isFetching)
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  if (error)
    return (
      <div>
        <Error />
      </div>
    );

  return (
    <Offcanvas
      show={showFilter}
      onHide={toggleShowFilter}
      placement="end"
      className={styles.offcanvasContainer}
      scroll={true}
      backdrop={true}
      style={{ width: "500px", paddingLeft: "0px" }}
    >
      <Offcanvas.Header closeButton className={styles.offcanvasHeader}>
        <Offcanvas.Title className={styles.Title}>Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ padding: "0", paddingTop: "20px" }}>
        <Box sx={{ flexGrow: 1, display: "flex", height: 500 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            sx={{
              borderRight: 1,
              borderColor: "divider",
              marginLeft: 0,
              paddingLeft: 0,
              width: "150px",
              ".Mui-selected": {
                color: "#3d3773",
              },
              ".MuiTabs-indicator": {
                backgroundColor: "#3d3773",
              },
            }}
          >
            <Tab label="Program" sx={{ fontSize: 13, pr: 3, ml: 0 }} />
            <Tab label="Institute" sx={{ fontSize: 13, pr: 3, ml: 0 }} />
            {setDateFrom && <Tab label="Date" sx={{ fontSize: 13, pr: 3, ml: 0 }} />}
          </Tabs>
          <Box sx={{ flexGrow: 1, ml: 4 }}>
            {value === 0 && (
              <div className={styles.offcanvasCheckboxContainer}>
                {coursesList.map((course, index) => (
                  <Form.Check
                    className={`${styles.formCheck}`}
                    type="checkbox"
                    id={`course-filter-${index}`}
                    label={
                      <span className={styles.formCheckLabel}>{course}</span>
                    }
                    name={course}
                    checked={selectedCourses.includes(course)}
                    onChange={handleCourseSelectionChange}
                    key={index}
                  />
                ))}
              </div>
            )}
            {value === 1 && (
              <div>
                <div className={styles.searchBar}>
                  <Button className={styles.tableFilterButton}>
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </Button>
                  <Form.Control
                    type="search"
                    placeholder="Search institutes..."
                    value={searchInstitute}
                    onChange={(e) => setSearchInstitute(e.target.value)}
                    className={styles.searchInput}
                  />
                </div>
                {filteredInstitutes.map(
                  (
                    institute: {
                      id: string | undefined;
                      displayValue:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined;
                    },
                    index: React.Key | null | undefined
                  ) => (
                    <Form.Check
                      type="checkbox"
                      id={institute.id}
                      label={institute.displayValue}
                      name="institutes"
                      onChange={handleInstituteSelectionChange}
                      key={index}
                      className={styles.collegeCheckbox}
                      checked={selectedInstitutes.includes(institute.id)}
                    />
                  )
                )}
              </div>
            )}
            {setDateFrom &&
            value === 2 && setDateFrom && setDateTo && (
              <div className={styles.dateFilterContainer}>
                <Form.Group>
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    type="date"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>To</Form.Label>
                  <Form.Control
                    type="date"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </Form.Group>
              </div>
            )
          }
          </Box>
        </Box>
        <Button
          className={styles.applyFilterButton}
          onClick={handleApplyFilter}
        >
          Apply Filter
        </Button>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FilterOffcanvas;

import React, {useState } from "react";
import SideBar from "../../components/sideBar/sidebar.tsx";
import {
  collegeMenuIcons,
  collegeMenuItems,
  collegeMenuHref,
  menuHref,
  menuIcons,
  menuItems,
} from "../../constants/sideBarConstants.ts";
import "./college.css";
import CollegeTable from "../../components/table/colegeTable.tsx";
import { Header } from "../../components/header/Header.tsx";
import { useUser } from "../../components/context/userContext";

interface CollegePageProps {
  userName: string;
  userProfileUrl: string;
  currentTab: number;
}

export default function CollegePage() {
  // const { userName, userProfileUrl, currentTab } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = (data:any) => {
    setIsSidebarOpen(data);
  };

  const { user, name } = useUser();

  const isStudent = user?.isStudent || false;
  const isTeacher = user?.isTeacher || false;
  
  return (
    <div className="page-container">
      <div className="sidebar-container">
        <SideBar
          menuItems={isStudent||isTeacher ? menuItems : collegeMenuItems}
          menuHrefs={isStudent||isTeacher ? menuHref : collegeMenuHref}
          menuIcons={isStudent||isTeacher ? menuIcons: collegeMenuIcons}
          userName= {name ? name : "Verse user"}
          userProfileUrl="https://preview.redd.it/mphoqu32zps71.png?auto=webp&s=b65bdbce745ba021669216c28fa454219ce08a35"
          currentTab={3}
          isSideBarOpen={toggleSidebar}
        />
      </div>
      <div className={`content-container ${isSidebarOpen ? "sidebar-open" : ""}`}>
      <Header page="INSTITUTE" searchBar placeholder="Search institute..."></Header>
         <CollegeTable/>
      </div>
    </div>
  );
}

import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { useFetchLoggedInUserDetailsQuery } from '../../slices/authApiSlice';
import LoadingSpinner from '../loading/LoadingSpinner';
import { toast } from 'sonner';

type UserType = {
  name?: string;
  roles?: string[];
  isStudent?: boolean;
  isInstitute?: boolean;
  isAdmin?: boolean;
  isTeacher?: boolean;
} | null;

type UserContextType = {
  user: UserType;
  refetchUserDetails: () => void;
  name: string | null;
  authChecked: boolean;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

type UserProviderProps = {
  children: ReactNode;
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { data: loggedInUserData, isLoading: isLoadingUserDetails, isError, error, refetch } = useFetchLoggedInUserDetailsQuery();
  const [user, setUser] = useState<UserType>(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [name, setName] = useState<string | null>('');

  useEffect(() => {
    
    if (loggedInUserData) {
      const roles = loggedInUserData.data.loggedInUserDto.roles ?? [];
      const name = loggedInUserData.data.loggedInUserDto.name;
      setUser({
        ...loggedInUserData.data.loggedInUserDto,
        isStudent: roles.includes("STUDENT"),
        isInstitute: roles.includes("INSTITUTE"),
        isAdmin: roles.includes("ADMIN"),
        isTeacher: roles.includes("TEACHER"),
      });
      setName(name)
      if (window.location.pathname === '/login' || window.location.pathname === '/') {
        window.location.href = '/dashboard';
      }
    } else if (isError && (error as any).status === 500) {
      toast.info("Session not found, Please login")
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }
  }, [loggedInUserData, isError, refetch]);

  const refetchUserDetails = () => {
    refetch();
  };
  useEffect(() => {
    if (!isLoadingUserDetails && !isError) {
      setAuthChecked(true);
    }
  }, [isLoadingUserDetails, isError]);

  if (isLoadingUserDetails) {
    return <LoadingSpinner />;
  }

  return (
    <UserContext.Provider value={{ user, refetchUserDetails, authChecked, name  }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

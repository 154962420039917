import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { CourseDto } from "../../../../interfaces/course";


const StyledCard = styled(Card)<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  border: isSelected ? "2px solid #ADD8E6" : "1px solid #eee",
  boxShadow: isSelected ? "0px 0px 12px rgba(0, 0, 0, 0.2)" : "none",
  margin: theme.spacing(1),
}));

const StyledCardActionArea = styled(CardActionArea)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledCardMedia = styled(CardMedia)({
  width: "50%",
});

interface MyCourseCardProps {
  course: CourseDto;
  isSelected?: boolean;
}

export const MyCourseCard: React.FC<MyCourseCardProps> = ({ course, isSelected }) => {
  return (
    <StyledCard isSelected={isSelected}>
       <StyledCardActionArea
       disableRipple
      >
        <StyledCardMedia
          component="img"
          image={course.thumbnail ?? ""}
          alt={course.name}
        />
        <StyledCardContent>
          <Typography gutterBottom variant="h5" component="div">
            {course.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {course.shortDescription}
          </Typography>
        </StyledCardContent>
      </StyledCardActionArea>
    </StyledCard>
  );
};

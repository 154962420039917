import React, { useEffect, useState } from 'react';
import Lottie from "lottie-react";
import Confetti from 'react-confetti';
import successAnimation from "../../assets/animation/success.json";
import styles from "./SuccessPage.module.css";
import { useSearchParams } from 'react-router-dom'; 
import { useGetEsewaPaymentResponseQuery } from '../../slices/transactionSlice';
import LoadingSpinner from '../../components/loading/LoadingSpinner';

export default function SuccessPage() {
  const [searchParams] = useSearchParams();
  const paymentData = searchParams.get('data');
  const { data: response, isLoading, isSuccess } = useGetEsewaPaymentResponseQuery(paymentData || "", {
    skip: !paymentData
  });


  const [paymentComplete, setPaymentComplete] = useState(false);

  useEffect(() => {
    if (isSuccess && response && response.data === "COMPLETE") {
      setPaymentComplete(true);
    }
  }, [response, isSuccess]);

  if (isLoading){
    return <LoadingSpinner />;
  }

  if (!paymentComplete) {
    return (
      <div className={styles.errorContainer}>
        <p>There was a problem processing your payment. Please try again or contact support.</p>
      </div>
    );
  }

  return (
    <div className={styles.noDataFoundContainer}>
      <Confetti numberOfPieces={100} gravity={0.1} />
      <Lottie loop={true} animationData={successAnimation} className={styles.lottieAnimation} />
      <p className={styles.successText}>Payment successful!</p>
      <a href='https://www.eduversenepal.com/' className={styles.successButton}>
        <button className={styles.redirectButton}>Home page</button>
      </a>
    </div>
  );
}

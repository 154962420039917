import React from 'react'
import Lottie from "lottie-react";
import noDataFound from "../../assets/animation/noContent.json";
import styles from "./noDataFound.module.css";

interface NoDataFoundProps {
  text: string;
}

const NoDataFound: React.FC<NoDataFoundProps> = ({ text }) => {
  return (
    <div className={styles.noDataFoundContainer}>
        <Lottie loop={true} animationData={noDataFound} className={styles.lottieAnimation} />
        <p className={styles.noDataText}>{text}</p>
    </div>
  )
}

export default NoDataFound;
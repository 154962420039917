import React from 'react';
import styles from './loadingSpinner.module.css';

const LoadingSpinner = () => (
  <div className={styles.loadingSpinnerContainer}>
    <div className={styles.loadingSpinner}></div>
  </div>
);

export default LoadingSpinner;

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export enum RoleType {
  STUDENT = "STUDENT",
  INSTITUTE = "INSTITUTE",
  ADMIN = "ADMIN",
  TEACHER = "TEACHER"
}

export enum EducationLevel {
  SCHOOL = "SCHOOL",
  HIGH_SCHOOL = "HIGH_SCHOOL",
  DIPLOMA = "DIPLOMA",
  B_TECH = "B_TECH",
}
export enum SchoolExaminationBoard {
  NEB = "NEB",
  CBSE = "CBSE",
  O_LEVELS = "O_LEVELS",
  ICSE = "ICSE",
}

export enum AppliedApplicationStatus {
  APPLIED = 'APPLIED',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  CANCELLED = 'CANCELLED',
  COLLEGE_APPROVED = 'COLLEGE_APPROVED',
  COLLEGE_REJECTED = 'COLLEGE_REJECTED',
}

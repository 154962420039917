import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface College {
  
}

const apiUrl = '/api';

export const searchApiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),
  endpoints: (builder) => ({
    searchColleges: builder.query<College[], string>({
      query: (searchQuery) => {
        return `/colleges?query=${searchQuery}`;
      },
    }),
  }),
});

export const { useSearchCollegesQuery } = searchApiSlice;

import React from "react";
import { Button, Offcanvas } from "react-bootstrap";
import styles from "./confirmationOffcanvas.module.css";
import Profile from "../profile/Profile";
import Scrollbars from "react-custom-scrollbars-2";
import { useApplyApplicationMutation } from "../../slices/applicationSlice";
import {
  useMakePaymentMutation,
  useSubmitEsewaPaymentMutation,
} from "../../slices/paymentSlice";
import { toast } from "sonner";

interface ConfirmationOffcanvasProps {
  showConfirmation: boolean;
  applicationId: number;
  toggleShowConfirmation: () => void;
  applicationName: string;
  instituteName: string;
  formFee: number;
  program: string;
  eligibilityCriteria: string;
  description: string;
}

interface EsewaPaymentRequestBody {
  amount: string;
  taxAmount: string;
  totalAmount: string;
  transactionUuid: string;
  productCode: string;
  productServiceCharge: string;
  productDeliveryCharge: string;
  successUrl: string;
  failureUrl: string;
  signedFieldNames: string;
  signature: string;
}

interface ApplyApplicationEsewaRequestDto {
  studentId: string;
  applicationId: string;
  initiatedDate: number;
  esewaPaymentRequestBody: EsewaPaymentRequestBody;
}

const ConfirmationOffcanvas: React.FC<ConfirmationOffcanvasProps> = ({
  showConfirmation,
  toggleShowConfirmation,
  applicationId,
  applicationName,
  instituteName,
  eligibilityCriteria,
  description,
  formFee,
  program,
}) => {
  const [applyAdmission, {}] = useApplyApplicationMutation();
  const [makePayment, { isLoading, error }] = useMakePaymentMutation();

  const esewaCall = (formData: any) => {
    var path = "https://rc-epay.esewa.com.np/api/epay/main/v2/form";

    var form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", path);

    for (var key in formData) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", formData[key]);
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();
  };

  const handleMakePayment = async () => {
    const formDataObj = new FormData();
    if (applicationId) {
      formDataObj.append("applicationId", `${applicationId}`);
    }
    toast.promise(
      makePayment(formDataObj).unwrap()
        .then(response => {
          if (response.data.esewaPaymentRequestBody) {
            const requestBody = response.data.esewaPaymentRequestBody;
            esewaCall(requestBody)
          }
          throw new Error("Payment request body not available");
        }),
      {
        loading: 'Processing...',
        success: 'Payment completed successfully!',
        error: (err) => {
          if (err && err.data && err.data.message) {
            return `Registration failed! ${err.data.message}`;
          }
          return null;
        }
      }
    );
  };

  return (
    <div className={styles.offcanvasContainer}>
      <Offcanvas
        show={showConfirmation}
        onHide={toggleShowConfirmation}
        placement="top"
        className={styles.offcanvasContainer}
        scroll={false}
        backdrop={true}
        style={{ height: "100vh" }}
      >
        <Offcanvas.Header closeButton className={styles.offcanvasHeader}>
          <Offcanvas.Title>
            <i className="fa-solid fa-lock"></i> You are applying with these
            details
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={styles.offcanvasBodyOverride}>
          <div className={styles.contentWrapper}>
            <div className={styles.detailsContainer}>
              <Profile forConfirmation={true} />
              <div className={styles.applicationDetails}>
                <div>
                  <p>
                    <strong>Application Name:</strong> {applicationName}
                  </p>
                  <p>
                    <strong>Institute Name:</strong> {instituteName}
                  </p>
                  <p>
                    <strong>Eligibility Criteria:</strong> {eligibilityCriteria}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Program:</strong> {program}
                  </p>
                  <p>
                    <strong>Form Fee:</strong> Rs.{formFee}
                  </p>
                  <p>
                    <strong>Description:</strong> {description}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <Button
              className={styles.confirmButton}
              onClick={handleMakePayment}
            >
              Proceed to payment
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ConfirmationOffcanvas;

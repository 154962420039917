import { Navigate } from "react-router-dom";
import { useUser } from "../components/context/userContext";
import LoadingSpinner from "../components/loading/LoadingSpinner";
import React from "react";

export const ProtectedRoute = ({ children }) => {
  const { user, authChecked } = useUser();

  if (!authChecked) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export const ProtectedAdmin = ({ children }) => {
  const { user, authChecked } = useUser();

  if (!authChecked) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (user.isAdmin) {
    return children;
  }
};

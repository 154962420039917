import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import LoadingSpinner from "../../components/loading/LoadingSpinner.tsx";
import NoDataFound from "../../components/noDataFound/NoDataFound.tsx";
import { useGetApplicationQuery } from "../../slices/applicationSlice.ts";
import { useFilter } from "../context/FilterContext.tsx";
import ConfirmationOffcanvas from "../offcanvas/ConfirmationOffcanvas.tsx";
import FilterOffcanvas from "../offcanvas/filterOffcanvas.tsx";
import styles from "./addmissionTable.module.css";
import Error from "../../components/error/Error.tsx";

interface Application {
  id: number;
  applicationName: string;
  instituteName: string;
  description: string;
  educationLevel: string;
  educationProgram: string;
  openDate: number;
  deadlineDate: number;
  eligibilityCriteria: string;
  fees: number;
  noticeUrl: string;
  examinationNoticeUrl: string;
}

interface AdmissionTableProps {
  selectedCollege?: string;
}

const coursesList: string[] = [
  "SCIENCE",
  "MANAGEMENT",
  "HUMANITIES",
  "A_LEVELS",
];

export default function AdmissionTable({
  selectedCollege,
}: AdmissionTableProps) {

  const [applicationData, setApplicationData] = useState<Application[]>([]);

  const { searchQuery } = useFilter();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [filteredInstitutes, setFilteredInstitutes] = useState<string[]>([]);

  const {
    data: apiData,
    error: getError,
    isLoading: getLoading,
  } = useGetApplicationQuery({
    applicationName: searchQuery,
    educationPrograms: filteredInstitutes,
    pageNo: page,
    pageSize: rowsPerPage,
  });

  useEffect(() => {
    if (apiData) {
      setApplicationData(apiData.data.content);
      setTotalElements(apiData.data.totalElements);
      setTotalPages(apiData.data.totalPages);
    }
  }, [apiData]);

  const [selectedApplication, setSelectedApplication] =
    useState<Application | null>(null);
  const { showFilter, toggleShowFilter } = useFilter();
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const handleApplyClick = (application: Application) => {
    setSelectedApplication(application);
    setShowConfirm(true);
  };

  const toggleShowConfirm = () => {
    setSelectedApplication(null);
    setShowConfirm(!showConfirm);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = useMemo(() => {
    if (!selectedCollege) return applicationData;
    return applicationData.filter(
      (application) => application.instituteName === selectedCollege
    );
  }, [selectedCollege, applicationData]);

  const handleCourseSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const course = event.target.name;
    setSelectedCourses((prevSelectedCourses) =>
      prevSelectedCourses.includes(course)
        ? prevSelectedCourses.filter((c) => c !== course)
        : [...prevSelectedCourses, course]
    );
  };

  function epochToDate(epochInSeconds: number) {
    const date = new Date(epochInSeconds * 1000);
    const formattedDate =
      date.getFullYear().toString() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");
    return formattedDate;
  }

  const applyFilter = async () => {
    setFilteredInstitutes(selectedCourses);
  };

  const columns: ColumnDef<Application>[] = useMemo(
    () => [
      {
        header: "Application Name",
        accessorKey: "applicationName",
      },
      {
        header: "Institute Name",
        accessorKey: "instituteName",
      },
      {
        header: "Open Date",
        accessorKey: "openDate",
        cell: (info) => `${epochToDate(info.row.original.openDate)}`,
      },
      {
        header: "Deadline",
        accessorKey: "deadlineDate",
        cell: (info) => `${epochToDate(info.row.original.deadlineDate)}`,
      },
      {
        header: "Form Fee",
        accessorKey: "fees",
        cell: (info) => `Rs.${info.row.original.fees}`,
      },
      {
        header: "Program",
        accessorKey: "educationProgram",
      },
      {
        header: "Eligibility Criteria",
        accessorKey: "eligibilityCriteria",
        cell: (info) => (
          <textarea
            rows={info.getValue() ? "1" : "1"}
            style={{
              width: "100%",
              border: "none",
             
              color: info.getValue() ? "initial" : "#888",
              fontStyle: info.getValue() ? "normal" : "italic",
              padding: '2px',
            }}
            title="Click and drag to view full eligibility criteria"
            disabled={true}
            defaultValue={info.getValue() || "No criteria"}
          />
        ),
      },

      {
        header: "Application Notice",
        accessorKey: "noticeUrl",
        cell: (info) => {
          const noticeUrl = info.row.original.noticeUrl;
          return (
            <div className={classNames(styles.centerAlign)}>
              {noticeUrl ? (
                <a href={noticeUrl} target="_blank" rel="noopener noreferrer">
                  <i
                    className={classNames(
                      "fa-solid fa-download",
                      styles.noticeUrl
                    )}
                  ></i>
                </a>
              ) : (
                <span></span>
              )}
            </div>
          );
        },
      },
      {
        header: "Examination Notice",
        accessorKey: "noticeUrl",
        cell: (info) => {
          const examinationNoticeUrl = info.row.original.examinationNoticeUrl;
          return (
            <div className={classNames(styles.centerAlign)}>
              {examinationNoticeUrl ? (
                <a
                  href={examinationNoticeUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className={classNames(
                      "fa-solid fa-download",
                      styles.noticeUrl
                    )}
                  ></i>
                </a>
              ) : (
                <span></span>
              )}
            </div>
          );
        },
      },
      {
        header: () => (
          <div className={classNames(styles.centerAlign)}>Apply</div>
        ),
        id: "apply",
      },
    ],
    []
  );

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (getLoading) {
    return <LoadingSpinner />;
  }

  if (getError) {
    return (
      <>
        <FilterOffcanvas
          showFilter={showFilter}
          toggleShowFilter={toggleShowFilter}
          selectedCourses={selectedCourses}
          handleCourseSelectionChange={handleCourseSelectionChange}
          applyFilter={applyFilter}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          dateFrom={dateFrom}
          dateTo={dateTo}
          coursesList={coursesList}
        />
        <Error />
      </>
    );
  }

  if (apiData && apiData.data.content.length === 0) {
    return (
      <>
        <FilterOffcanvas
          showFilter={showFilter}
          toggleShowFilter={toggleShowFilter}
          selectedCourses={selectedCourses}
          handleCourseSelectionChange={handleCourseSelectionChange}
          applyFilter={applyFilter}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          dateFrom={dateFrom}
          dateTo={dateTo}
          coursesList={coursesList}
        />
        <NoDataFound text="No content" />
      </>
    );
  }

  return (
    <div className={styles.tableContainer}>
      <ConfirmationOffcanvas
        showConfirmation={showConfirm}
        toggleShowConfirmation={toggleShowConfirm}
        applicationId={selectedApplication ? selectedApplication.id : 0}
        applicationName={
          selectedApplication ? selectedApplication.applicationName : ""
        }
        instituteName={
          selectedApplication ? selectedApplication.instituteName : ""
        }
        formFee={selectedApplication ? selectedApplication.fees : 0}
        program={
          selectedApplication ? selectedApplication.educationProgram : ""
        }
        eligibilityCriteria={
          selectedApplication ? selectedApplication.eligibilityCriteria : ""
        }
        description={selectedApplication ? selectedApplication.description : ""}
      />
      <FilterOffcanvas
        showFilter={showFilter}
        toggleShowFilter={toggleShowFilter}
        selectedCourses={selectedCourses}
        handleCourseSelectionChange={handleCourseSelectionChange}
        applyFilter={applyFilter}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        dateFrom={dateFrom}
        dateTo={dateTo}
        coursesList={coursesList}
      />
      <table className={styles.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {typeof header.column.columnDef.header === "function"
                    ? header.column.columnDef.header(header.getContext())
                    : header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {cell.column.id === "apply" ? (
                    <div className={classNames(styles.centerAlign)}>
                      <button
                        className={styles.applyButton}
                        onClick={() => handleApplyClick(row.original)}
                      >
                        Apply
                      </button>
                    </div>
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.paginationControls}>
        <Pagination
          count={totalPages}
          shape="rounded"
          page={page}
          onChange={(event, value) => {
            setPage(value - 1);
          }}
        />
        <TablePagination
          component="div"
          count={totalElements}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-toolbar p": {
              marginBottom: 0,
            },
          }}
        />
      </div>
    </div>
  );
}

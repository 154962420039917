import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import loading from "../../assets/images/loading.webp";
import LoadingSpinner from "../../components/loading/LoadingSpinner.tsx";
import NoDataFound from "../../components/noDataFound/NoDataFound.tsx";
import { useGetAllInstituteDetailsQuery } from "../../slices/collegeApiSlice.ts";
import { useFilter } from "../context/FilterContext.tsx";
import CollegeModal from "../modal/CollegeModal.tsx";
import FilterOffcanvas from "../offcanvas/filterOffcanvas.tsx";
import styles from "./collegeTable.module.css";
import Error from "../../components/error/Error.tsx";

interface College {
  id: number;
  title: string;
  image: string;
  logoUrl: string;
  location: string;
  courses: {
    courseNames: string[];
  };
  description: string;
}

const coursesList: string[] = ["SCIENCE", "MANAGEMENT", "A_LEVELS", "HUMANITIES"];

export default function CollegeTable() {
  const { showFilter, toggleShowFilter } = useFilter();

  const [selectedCollege, setSelectedCollege] = useState<College | null>(null);

  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);

  const [filteredInstitutes, setFilteredInstitutes] = useState<string[]>([]);

  const { searchQuery } = useFilter();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  function mapContentToDesiredFormat(response: { data: { content: any[] } }) {
    return response.data.content.map(
      (item: {
        name: any;
        imageUrl: any;
        location: any;
        educationPrograms: any;
        logoUrl: any;
        shortDiscription: any;
        longDescription: any;
        contactNumber: any;
      }) => ({
        title: item.name,
        image: item.imageUrl || loading,
        location: item.location,
        courses: {
          courseNames: item.educationPrograms,
        },
        logo: item.logoUrl || loading,
        shortDescription: item.shortDiscription,
        description: item.longDescription,
        contactNumber: item.contactNumber,
      })
    );
  }

  const { data: collegeData, isLoading: getLoading, error: getError} =
    useGetAllInstituteDetailsQuery({
      query: searchQuery,
      educationPrograms: filteredInstitutes,
      pageNumber:page,
      pageSize:rowsPerPage,
    });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const data = useMemo(() => {
    if (
      collegeData &&
      collegeData.data &&
      Array.isArray(collegeData.data.content)
    ) {
      setTotalElements(collegeData.data.totalElements);
      setTotalPages(collegeData.data.totalPages);
      return mapContentToDesiredFormat(collegeData);
    }
    return [];
  }, [collegeData]);

  const handleTitleClick = (college: any) => {
    setSelectedCollege(college);
  };

  const handleCloseModal = () => {
    setSelectedCollege(null);
  };

  const columns: ColumnDef<[number]>[] = React.useMemo(
    () => [
      {
        header: () => <span></span>,
        accessorKey: "logo",
        cell: (info) => (
          <div className={styles.logoImageContainer}>
            <img
              src={info.getValue()}
              className={styles.logoImage}
              alt="Institute"
              onLoad={(e) => (e.currentTarget.style.opacity = "1")}
            />
          </div>
        ),
      },
      {
        id: "details",
        header: () => <span></span>,
        cell: (row) => {
          const courses = row.cell.row.original.courses;
          let coursesText = "No courses listed";
          if (
            typeof courses === "object" &&
            courses.courseNames &&
            Array.isArray(courses.courseNames)
          ) {
            coursesText = courses.courseNames.join(", ");
          }

          function trimDescription(description: string) {
            const wordLimit = 20;
            const words = description.split(/\s+/);
            if (words.length > wordLimit) {
              return `${words.slice(0, wordLimit).join(" ")}...`;
            }
            return description;
          }

          return (
            <div
              className={styles.details}
              onClick={() => handleTitleClick(row.cell.row.original)}
            >
              <div className={styles.title}>{row.cell.row.original.title}</div>
              <div className={styles.location}>
                {row.cell.row.original.location}
              </div>
              <div className={styles.courses}>{coursesText}</div>
              <div className={styles.description}>
                {trimDescription(row.cell.row.original.description)}
              </div>
            </div>
          );
        },
      },
      {
        id: "apply",
        header: () => <span></span>,
        cell: () => <button>Apply</button>,
      },
    ],
    []
  );

  // const toggleFilter = () => setShowFilter(!showFilter);

  const handleCourseSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const course = event.target.name;
    setSelectedCourses((prevSelectedCourses) =>
      prevSelectedCourses.includes(course)
        ? prevSelectedCourses.filter((c) => c !== course)
        : [...prevSelectedCourses, course]
    );
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const applyFilter = async () => {
    setFilteredInstitutes(selectedCourses);
  };

  if (getLoading) {
    return <LoadingSpinner />;
  }

  if (getError) {
    return (
      <>
        <FilterOffcanvas
        showFilter={showFilter}
        toggleShowFilter={toggleShowFilter}
        selectedCourses={selectedCourses}
        handleCourseSelectionChange={handleCourseSelectionChange}
        applyFilter={applyFilter}
        coursesList={coursesList}
      />
        <Error/>
      </>
    );
  }

  if (collegeData && collegeData.data.content.length === 0) {
    return <>
    <FilterOffcanvas
        showFilter={showFilter}
        toggleShowFilter={toggleShowFilter}
        selectedCourses={selectedCourses}
        handleCourseSelectionChange={handleCourseSelectionChange}
        applyFilter={applyFilter}
        coursesList={coursesList}
      />
      <NoDataFound text="No content" /></>;
  }

  return (
    <div className={styles.tableContainer}>
      <FilterOffcanvas
        showFilter={showFilter}
        toggleShowFilter={toggleShowFilter}
        selectedCourses={selectedCourses}
        handleCourseSelectionChange={handleCourseSelectionChange}
        applyFilter={applyFilter}
        coursesList={coursesList}
      />
      <table className={classNames(styles.table)}>
        <tbody className={classNames(styles.tbody)}>
          {table.getRowModel().rows.map((row) => (
            <tr className={classNames(styles.tr)} key={row.id}>
              {row.getVisibleCells().map((cell) => {
                let cellClassName = "";
                switch (cell.column.id) {
                  case "image":
                    cellClassName = styles.tdImage;
                    break;
                  case "details":
                    cellClassName = styles.tdDetails;
                    break;
                  case "apply":
                    cellClassName = styles.tdApply;
                    break;
                  default:
                    cellClassName = styles.td;
                }

                return (
                  <td className={classNames(cellClassName)} key={cell.id}>
                    {cell.column.id === "apply" ? (
                      <button
                        className={classNames(styles.applyButton)}
                        onClick={() => handleTitleClick(row.original)}
                      >
                        Apply
                      </button>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.paginationControls}>
        <Pagination
          count={totalPages}
          shape="rounded"
          page={page}
          onChange={(event, value) => {
            setPage(value - 1);
          }}
        />
        <TablePagination
          component="div"
          count={totalElements}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '.MuiTablePagination-toolbar p': {
              marginBottom: 0,
            }
          }}
        />
      </div>
      {selectedCollege && (
        <CollegeModal
          isOpen={true}
          onClose={handleCloseModal}
          title={selectedCollege.title}
          image={selectedCollege.image}
          location={selectedCollege.location}
          courses={
            selectedCollege.courses
              ? selectedCollege.courses.courseNames.join(", ")
              : ""
          }
          description={selectedCollege.description}
        />
      )}
    </div>
  );
}

import React from 'react'
import Lottie from "lottie-react";
import error from "../../assets/animation/error.json";
import styles from "./Error.module.css";

export default function Error() {
  return (
    <div className={styles.noDataFoundContainer}>
        <Lottie loop={true} animationData={error} className={styles.lottieAnimation} />
        <p className={styles.noDataText}>Something went wrong!</p>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import SideBar from "../../components/sideBar/sidebar.tsx";
import {
  teacherMenuHref,
  teacherMenuIcons,
  teacherMenuItems,
  collegeMenuIcons,
  collegeMenuItems,
  collegeMenuHref,
  menuHref,
  menuIcons,
  menuItems,
} from "../../constants/sideBarConstants.ts";
import { Header } from "../../components/header/Header.tsx";
import LandingPage from "../../pages/LandingPage/LandingPage.tsx";
import { useUser } from "../../components/context/userContext";
import { useFetchLoggedInUserDetailsQuery } from "../../slices/authApiSlice.ts";
import { toast } from "sonner";

interface DashboardProps {
  userName: string;
  userProfileUrl: string;
  currentTab: number;
}

export default function Dashboard() {
  const [shouldFetchUserDetails, setShouldFetchUserDetails] = useState(false);
  const { user, name } = useUser();

  useEffect(() => {
    const firstLogin = localStorage.getItem("firstSuccess") === "true";
    if (firstLogin) {
      setShouldFetchUserDetails(true);
      toast.success("Login successful!");
      localStorage.removeItem("firstSuccess");
    }
  }, []);

  const { error, isLoading, refetch } = useFetchLoggedInUserDetailsQuery(
    undefined,
    { skip: !shouldFetchUserDetails }
  );

  useEffect(() => {
    if (shouldFetchUserDetails) {
      refetch();
    }
  }, [shouldFetchUserDetails, refetch]);

  useEffect(() => {
    if (shouldFetchUserDetails) {
      setTimeout(() => {
        toast.success("Login successful!");
      }, 500);
    } else if (error) {
      setTimeout(() => {
        toast.error("Error fetching user details.");
      }, 500);
    }
  }, [isLoading, error]);

  const isStudent = user?.isStudent || false;
  const isTeacher = user?.isTeacher || false;
  const isInstitute = user?.isInstitute || false;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = (data: any) => {
    setIsSidebarOpen(data);
  };

  return (
    <div className="page-container">
      <div className="sidebar-container">
        <SideBar
          menuItems={
            isStudent
              ? menuItems
              : isTeacher
              ? teacherMenuItems
              : collegeMenuItems
          }
          menuHrefs={
            isStudent
              ? menuHref
              : isTeacher
              ? teacherMenuHref
              : collegeMenuHref
          }
          menuIcons={
            isStudent
              ? menuIcons
              : isTeacher
              ? teacherMenuIcons
              : collegeMenuIcons
          }
          userName={name ? name : "Verse user"}
          userProfileUrl="https://preview.redd.it/mphoqu32zps71.png?auto=webp&s=b65bdbce745ba021669216c28fa454219ce08a35"
          currentTab={0}
          isSideBarOpen={toggleSidebar}
        />
      </div>
      <div
        className={`content-container ${isSidebarOpen ? "sidebar-open" : ""}`}
      >
        <Header page="DASHBOARD"></Header>
        <LandingPage />
      </div>
    </div>
  );
}

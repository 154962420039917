import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useFilter } from "../context/FilterContext";
import styles from "./header.module.css";
import { googleLogout } from "@react-oauth/google";
import avatar from "../../assets/images/Logo.png";
import  { useLogoutUserMutation }  from "../../slices/authApiSlice";

import { Navigate, useNavigate } from "react-router-dom";
import {
  useGetProfileQuery,
  profileApiSlice,
} from "../../slices/profileApiSlice";
import Cookies from "js-cookie";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { toast } from "sonner";

type HeaderProps = {
  page: string;
  searchBar?: boolean;
  placeholder?: string;
  // onSearch: (query: string) => void;
};

export function Header(props: HeaderProps) {
  const { page, searchBar, placeholder } = props;
  const { toggleShowFilter, setSearchQuery } = useFilter();
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const navigate = useNavigate();
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };


  const [logoutUser, {}] = useLogoutUserMutation();

  const toggleLogoutPopup = () => setShowLogoutPopup(!showLogoutPopup);

  const toggleNotificationPopup = () =>
    setShowNotificationPopup(!showNotificationPopup);
  const dispatch = useDispatch();

  async function handleLogout() {
    try {
      await googleLogout();
      Cookies.remove("X-DASHVERSE");
      sessionStorage.clear();
      localStorage.clear();
      logoutUser();
      navigate("/login");
      toast.success("Logout successful");
    } catch (err) {
      const errorMessage = err.data?.message || err.message || "An unknown error occurred";
      toast.error(`Logout failed! ${errorMessage}`);
    }
}


  // const {
  //   data: profileData,
  //   error: getError,
  //   isLoading: getLoading,
  // } = useGetProfileQuery();

  var profilePic;

  // if(profileData?.data?.profilePicture){
  //  profilePic =  profileData?.data.profilePicture;
  // }

  // if (getLoading) {
  //   return <LoadingSpinner />;
  // }

  return (
    <div className={styles.header}>
      <div className={styles.logoContainer}>
        <span className={styles.logo}>{page}</span>
      </div>

      {searchBar && (
        <div className={styles.filters}>
          <Button
            // onClick={toggleFilter}
            className={styles.tableFilterButton}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </Button>
          <div className={styles.searchContainer}>
            <input
              type="text"
              placeholder={placeholder}
              className={styles.searchInput}
              onChange={handleInputChange}
              // value={searchQuery}
              // onChange={handleSearchInputChange}
            />
            {/* 
          {searchData && searchData.length > 0 && (
            <div className={styles.dropdown}>
              {searchData.map((college: College) => (
                <div
                  key={college.id}
                  className={styles.dropdownItem}
                  onClick={() => handleTitleClick(college)}
                >
                  {college.title}
                </div>
              ))}
            </div>
          )} */}
          </div>
          <div className={styles.filterButtonContainer}></div>
          <div className={styles.filterButtonContainer}>
            <Button
              // onClick={toggleFilter}
              // className={classNames(styles.tableFilterButton)}
              onClick={toggleShowFilter}
            >
              <i className="fa-solid fa-filter"></i>
            </Button>
          </div>
        </div>
      )}

      <div className={styles.avatarContainer}>
        <div className={styles.iconContainer}>
          {/* <i className="fa-solid fa-file-circle-plus"></i> */}

          {/* <div className={styles.notificationDot}></div>
          <i className="fa-solid fa-bell" onClick={toggleNotificationPopup}></i>
          {showNotificationPopup && (
            <div className={styles.NotificationPopup}>
              <button className={styles.NotificationButton}>
                Notification 1
              </button>
              <button className={styles.NotificationButton}>
                Notification 2
              </button>
              <button className={styles.NotificationButton}>
                Notification 3
              </button>
              <button className={styles.NotificationButton}>
                Notification 4
              </button>
              <button className={styles.NotificationButton}>
                Notification 5
              </button>
            </div>
          )} */}
        </div>

        <img
          src={profilePic ? profilePic : avatar}
          alt="avatar"
          className={styles.avatar}
          onClick={toggleLogoutPopup}
        />
        {showLogoutPopup && (
          <div className={styles.logoutPopup}>
            <button className={styles.logoutButton} onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

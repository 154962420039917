import React, { useState } from "react";
import {
  useAssignToStudentMutation,
  useUpdateRolesMutation,
} from "../../slices/adminSlice";
import { useCreateInstructorMutation } from "../../slices/instructorSlice.ts";
import {
  useAssignInstructorMutation,
  useCreateCourseMutation,
  useCreateCourseSessionMutation,
} from "../../slices/courseSlice.ts";
import { RoleType } from "../../enums/common";
import { Header } from "../../components/header/Header.tsx";
import { toast } from "sonner";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import styles from "./AdminPage.module.css";

const AdminPage: React.FC = () => {
  const [accountId, setAccountId] = useState("");
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [description, setDescription] = useState("");
  const [name, setname] = useState("");
  const [profilePic, setProfilePic] = useState<File | null>(null);
  const [instructorDescription, setInstructorDescription] =
    useState<File | null>(null);

  const [courseName, setCourseName] = useState("");
  const [coursePrice, setCoursePrice] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseDiscountedPrice, setCourseDiscountedPrice] = useState("");
  const [finalRegistrationDate, setFinalRegistrationDate] = useState("");
  const [courseDiscountedPercentage, setCourseDiscountedPercentage] =
    useState("");

  const [sessionName, setSessionName] = useState("");
  const [sessionDescription, setSessionDescription] = useState("");
  const [meetLink, setMeetLink] = useState("");
  const [courseSessionStart, setCourseSessionStart] = useState("");
  const [courseSessionEnd, setCourseSessionEnd] = useState("");
  const [courseSessionStartTime, setCourseSessionStartTime] = useState("");
  const [courseSessionEndTime, setCourseSessionEndTime] = useState("");

  const [studentCourseId, setStudentCourseId] = useState("");
  const [studentEmailId, setStudentEmailId] = useState("");
  const [paidAmt, setPaidAmt] = useState("");

  const [length, setLength] = useState("");
  const [timetableUrl, setTimetableUrl] = useState("");

  const [thumbnail, setThumbnail] = useState<File | null>(null);

  const [timetable, setTimetable] = useState<File | null>(null);

  const [updateRoles, { isLoading: isRolesLoading, error: rolesError }] =
    useUpdateRolesMutation();
  const [
    createInstructor,
    { isLoading: isInstructorLoading, error: instructorError },
  ] = useCreateInstructorMutation();

  const [createCourse, { isLoading: isCourseLoading, error: courseError }] =
    useCreateCourseMutation();

  const [createSession, { isLoading: isSessionLoading, error: sessionError }] =
    useCreateCourseSessionMutation();

  const [assignInstructor, { isLoading: isAssigning, error: assignError }] =
    useAssignInstructorMutation();

  const [
    assignCourse,
    { isLoading: isAssigningCourse, error: courseAssignError },
  ] = useAssignToStudentMutation();

  const handleRoleChange = (role: RoleType, checked: boolean) => {
    setRoles((prev) =>
      checked ? [...prev, role] : prev.filter((r) => r !== role)
    );
  };

  const handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setProfilePic(event.target.files[0]);
    }
  };

  const handleInstructorDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      setInstructorDescription(event.target.files[0]);
    }
  };

  const handleThumbnailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      setThumbnail(event.target.files[0]);
    }
  };

  const handleTimetableChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      setTimetable(event.target.files[0]);
    }
  };

  const handleAssignInstructor = async (event: React.FormEvent) => {
    event.preventDefault();
    await assignInstructor({ userId, courseId });
  };

  const handleAssignStudentToCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    const payload = {
      email: studentEmailId,
      domainId: studentCourseId,
      domainType: "PURCHASE_COURSE_FORM",
      paymentMethod: "GLOBAL_QR",
      transactionStatus: "SUCCESS",
      amountPaid: parseInt(paidAmt),
      refundedAmount: 0.0,
    };
    await assignCourse(payload);
  };

  const handleCreateInstructor = async (event: React.FormEvent) => {
    event.preventDefault();
    if (email && description && name && profilePic) {
      const instructorDto = { name, email, description };
      console.log(instructorDto);
      const instructorBlob = new Blob([JSON.stringify(instructorDto)], {
        type: "application/json",
      });

      const formData = new FormData();
      formData.append("instructorDto", instructorBlob, "instructorDto.json");
      formData.append("profilePicture", profilePic);

      if (instructorDescription) {
        formData.append("instructorDescription", instructorDescription);
      }

      try {
        const result = await createInstructor(formData).unwrap();
        toast.success("Instructor created successfully!");
      } catch (error) {
        const errorMessage =
          error.data?.message || error.message || "An unknown error occurred";
        toast.error(`Failed to create instructor: ${errorMessage}`);
      }
    } else {
      toast.error("Validation error! Ensure all fields are filled correctly.");
    }
  };

  function combineDateAndTime(date, time) {
    const timeParts = time.split(':');
    const dateObject = new Date(date);
  
    dateObject.setHours(parseInt(timeParts[0], 10));
    dateObject.setMinutes(parseInt(timeParts[1], 10));
    if (timeParts.length > 2) {
      dateObject.setSeconds(parseInt(timeParts[2], 10));
    }
  
    return dateObject;
  }

  const handleCreateSession = async (event) => {
    event.preventDefault();

    const startDate = combineDateAndTime(courseSessionStart, courseSessionStartTime);
    const endDate = combineDateAndTime(courseSessionEnd, courseSessionEndTime);
    
    const startDateEpoch = startDate.getTime();
    const endDateEpoch = endDate.getTime();

    console.log(startDateEpoch);
    console.log(endDateEpoch);
    
    
    const payload = {
      sessionName: sessionName,
      sessionDescription: sessionDescription,
      meetLink: meetLink,
      courseSessionStart: startDateEpoch,
      courseSessionEnd: endDateEpoch,
    };

    try {
      await createSession(payload);
      toast.success("session created successfully!");
    } catch (error: any) {
      const errorMessage =
        error.data?.errorMessage ||
        error.message ||
        "An unknown error occurred";
      toast.error(`Failed to create session: ${errorMessage}`);
    }
  };

  const handleCreateCourse = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    const finalRegistrationDateEpoch = new Date(
      finalRegistrationDate
    ).getTime();

    const courseDetailsBlob = new Blob(
      [
        JSON.stringify({
          name: courseName,
          actualPrice: coursePrice,
          discountedPrice: courseDiscountedPrice,
          discountPercentage: courseDiscountedPercentage,
          timeTableUrl: timetableUrl,
          lengthDescription: length,
          finalRegistrationDate: finalRegistrationDateEpoch,
          longDescription: courseDescription,
        }),
      ],
      { type: "application/json" }
    );

    formData.append("courseDetail", courseDetailsBlob, "courseDetail.json");

    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    if (timetable) {
      formData.append("timetable", timetable);
    }

    try {
      const result = await createCourse(formData).unwrap();
      toast.success("Course created successfully!");
    } catch (error) {
      const errorMessage =
        error.data?.errorMessage ||
        error.message ||
        "An unknown error occurred";
      toast.error(`Failed to create course: ${errorMessage}`);
    }
  };

  const handleSubmitRoles = async (event: React.FormEvent) => {
    event.preventDefault();
    await updateRoles({
      accountId,
      roles,
    });
  };

  return (
    <>
      <Header page="ADMIN PANEL"></Header>
      <Box className={styles.adminContainer}>
        <Box className={styles.form}>
          <h3>Change Roles</h3>
          <form onSubmit={handleSubmitRoles}>
            <TextField
              label="Account ID"
              variant="outlined"
              value={accountId}
              onChange={(e) => setAccountId(e.target.value)}
              margin="normal"
              fullWidth
            />
            <FormGroup>
              {Object.values(RoleType).map((role) => (
                <FormControlLabel
                  key={role}
                  control={
                    <Checkbox
                      checked={roles.includes(role)}
                      onChange={(e) => handleRoleChange(role, e.target.checked)}
                      name={role}
                    />
                  }
                  label={role}
                />
              ))}
            </FormGroup>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isRolesLoading}
              startIcon={isRolesLoading ? <CircularProgress size={20} /> : null}
              className={styles.submitButton}
            >
              {isRolesLoading ? "Updating..." : "Update Roles"}
            </Button>
            {rolesError && (
              <Box
                className={styles.error}
              >{`Error: ${rolesError.toString()}`}</Box>
            )}
          </form>
        </Box>
        <Box className={styles.form}>
          <h3>Create Instructor</h3>
          <form onSubmit={handleCreateInstructor}>
            <TextField
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setname(e.target.value)}
              margin="normal"
              fullWidth
            />

            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              margin="normal"
              fullWidth
              multiline
              rows={4}
            />
            <div className={styles.buttonsContainer}>
              <Button
                variant="contained"
                component="label"
                className={styles.uploadButton}
              >
                {profilePic ? profilePic.name : "Upload Profile Picture"}
                <input type="file" hidden onChange={handleProfileChange} />
              </Button>

              <Button
                variant="contained"
                component="label"
                className={styles.uploadButton}
              >
                {instructorDescription
                  ? instructorDescription.name
                  : "Upload Description"}
                <input
                  type="file"
                  hidden
                  onChange={handleInstructorDescriptionChange}
                />
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isInstructorLoading}
                startIcon={
                  isInstructorLoading ? <CircularProgress size={20} /> : null
                }
                className={styles.submitButton}
              >
                {isInstructorLoading ? "Creating..." : "Create Instructor"}
              </Button>
            </div>
            {instructorError && (
              <Box
                className={styles.error}
              >{`Error: ${instructorError.toString()}`}</Box>
            )}
          </form>
        </Box>

        <Box className={styles.form}>
          <h3>Assign Instructor to Course</h3>
          <form onSubmit={handleAssignInstructor}>
            <TextField
              label="User ID"
              variant="outlined"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Course ID"
              variant="outlined"
              value={courseId}
              onChange={(e) => setCourseId(e.target.value)}
              margin="normal"
              fullWidth
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isAssigning}
              startIcon={isAssigning ? <CircularProgress size={20} /> : null}
              className={styles.submitButton}
            >
              {isAssigning ? "Assigning..." : "Assign Instructor"}
            </Button>
            {assignError && (
              <Box
                className={styles.error}
              >{`Error: ${assignError.toString()}`}</Box>
            )}
          </form>
        </Box>

        <Box className={styles.form}>
          <h3>Assign Student to Course</h3>
          <form onSubmit={handleAssignStudentToCourse}>
            <TextField
              label="Student email"
              variant="outlined"
              value={studentEmailId}
              onChange={(e) => setStudentEmailId(e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Course ID"
              variant="outlined"
              value={studentCourseId}
              onChange={(e) => setStudentCourseId(e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Amount Paid"
              variant="outlined"
              value={paidAmt}
              onChange={(e) => setPaidAmt(e.target.value)}
              margin="normal"
              fullWidth
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isAssigningCourse}
              startIcon={
                isAssigningCourse ? <CircularProgress size={20} /> : null
              }
              className={styles.submitButton}
            >
              {isAssigningCourse ? "Assigning..." : "Assigned Course"}
            </Button>
            {courseAssignError && (
              <Box
                className={styles.error}
              >{`Error: ${courseAssignError.toString()}`}</Box>
            )}
          </form>
        </Box>

        <Box className={styles.form}>
          <h3>Create Course</h3>
          <form onSubmit={handleCreateCourse}>
            <TextField
              label="Name"
              variant="outlined"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Price"
              variant="outlined"
              value={coursePrice}
              onChange={(e) => setCoursePrice(e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Discounted Price"
              variant="outlined"
              value={courseDiscountedPrice}
              onChange={(e) => setCourseDiscountedPrice(e.target.value)}
              margin="normal"
              fullWidth
            />

            <TextField
              label="Discounted Percentage"
              variant="outlined"
              value={courseDiscountedPercentage}
              onChange={(e) => setCourseDiscountedPercentage(e.target.value)}
              margin="normal"
              fullWidth
            />

            <TextField
              label="Length"
              variant="outlined"
              value={length}
              onChange={(e) => setLength(e.target.value)}
              margin="normal"
              fullWidth
            />

            <TextField
              label="Final Registration Date"
              type="date"
              variant="outlined"
              value={finalRegistrationDate}
              onChange={(e) => setFinalRegistrationDate(e.target.value)}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label="Description"
              variant="outlined"
              value={courseDescription}
              onChange={(e) => setCourseDescription(e.target.value)}
              margin="normal"
              fullWidth
              multiline
              rows={3}
            />
            <div className={styles.buttonsContainer}>
              <Button
                variant="contained"
                component="label"
                className={styles.uploadButton}
              >
                {thumbnail ? thumbnail.name : "Upload Course Thumbnail"}
                <input type="file" hidden onChange={handleThumbnailChange} />
              </Button>

              <Button
                variant="contained"
                component="label"
                className={styles.uploadButton}
              >
                {timetable ? timetable.name : "Upload Timetable"}
                <input type="file" hidden onChange={handleTimetableChange} />
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isCourseLoading}
                startIcon={
                  isCourseLoading ? <CircularProgress size={20} /> : null
                }
                className={styles.submitButton}
              >
                {isCourseLoading ? "Creating..." : "Create Course"}
              </Button>
            </div>
            {courseError && (
              <Box
                className={styles.error}
              >{`Error: ${courseError.toString()}`}</Box>
            )}
          </form>
        </Box>

        <Box className={styles.form}>
          <h3>Create Session</h3>
          <form onSubmit={handleCreateSession}>
            <TextField
              label="session Name"
              variant="outlined"
              value={sessionName}
              onChange={(e) => setSessionName(e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              label="session Description"
              variant="outlined"
              value={sessionDescription}
              onChange={(e) => setSessionDescription(e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              label="meetLink"
              variant="outlined"
              value={meetLink}
              onChange={(e) => setMeetLink(e.target.value)}
              margin="normal"
              fullWidth
            />

            <TextField
              label="Session Start Date"
              variant="outlined"
              type="date"
              value={courseSessionStart}
              onChange={(e) => setCourseSessionStart(e.target.value)}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label="Session Start Time"
              variant="outlined"
              type="time"
              value={courseSessionStartTime}
              onChange={(e) => setCourseSessionStartTime(e.target.value)}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label="Session End Date"
              variant="outlined"
              type="date"
              value={courseSessionEnd}
              onChange={(e) => setCourseSessionEnd(e.target.value)}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label="Session End Time"
              variant="outlined"
              type="time"
              value={courseSessionEndTime}
              onChange={(e) => setCourseSessionEndTime(e.target.value)}
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />

            <div className={styles.buttonsContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSessionLoading}
                startIcon={
                  isSessionLoading ? <CircularProgress size={20} /> : null
                }
                className={styles.submitButton}
              >
                {isSessionLoading ? "Creating..." : "Create Session"}
              </Button>
            </div>
            {sessionError && (
              <Box
                className={styles.error}
              >{`Error: ${sessionError.toString()}`}</Box>
            )}
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AdminPage;

import React from 'react';
import styles from './footer.module.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import  Logo  from '../../assets/images/invertedLogo.png'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.logoContainer}>
        <img src={Logo} alt="Dashverse"/>
        <h6>Dashverse Pvt Ltd</h6>
        </div>
        <div className={styles.socialMediaSection}>
          <h4>Follow Us</h4>
          <div className={styles.icons}>
            <a href="https://www.facebook.com/profile.php?id=61559831763388" className={styles.iconLink}><FaFacebookF /></a>
            <a href="#" className={styles.iconLink}><FaWhatsapp /></a>
            <a href="https://www.instagram.com/eduversenepal/" className={styles.iconLink}><FaInstagram /></a>
          </div>
        </div>
        <div className={styles.contactSection}>
          <h4>Contact Us</h4>
          <p>Email: dashverse@gmail.com / eduversenepal@gmail.com</p>
          <p>Phone: +977 9766540865 / +977 9766540865</p>
        </div>
       
      </div>
    </footer>
  );
};

export default Footer;

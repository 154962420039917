import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseUrl, esewaBaseUrl } from '../constants/routeConstants';
import Cookies from 'js-cookie';

interface EsewaPaymentResponse {

}

interface EsewaPaymentRequestBody {
    amount: string;
    taxAmount: string;
    totalAmount: string;
    transactionUuid: string;
    productCode: string;
    productServiceCharge: string;
    productDeliveryCharge: string;
    successUrl: string;
    failureUrl: string;
    signedFieldNames: string;
    signature: string;
  }



  export const paymentSlice = createApi({
    reducerPath: 'esewaPaymentApi',
    baseQuery: fetchBaseQuery({ baseUrl: BaseUrl }),
    endpoints: (builder) => ({
      makePayment: builder.mutation<EsewaPaymentResponse, FormData>({
        query: (formData) => ({
          url: '/admissionApplication/apply',
          method: 'POST',
          headers: {
            'X-DASHVERSE-TOKEN': Cookies.get('X-DASHVERSE'),
          },
          body: formData,
        }),
      }),
      submitEsewaPayment: builder.mutation<EsewaPaymentResponse, EsewaPaymentRequestBody>({
        query: (formData) => ({
          url: `${esewaBaseUrl}/epay/main/v2/form`,
          method: 'POST',
          body : formData,
        }),
      }),
    }),
  });

export const { useMakePaymentMutation, useSubmitEsewaPaymentMutation } = paymentSlice;

import React from "react";
import Modal from "react-modal";
import styles from "./StudentDetailModal.module.css";

interface StudentDetails {
  name: string;
  email: string;
  schoolExaminationBoard: string;
  schoolExpectedMarks: number;
  schoolMarks: number;
  schoolPassOutYear: number;
  profilePicture: string;
  markSheet: string;
}

interface StudentDetailModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  studentDetails: StudentDetails;
}

const StudentDetailModal: React.FC<StudentDetailModalProps> = ({
  isOpen,
  onRequestClose,
  studentDetails,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Student Details"
      ariaHideApp={false}
      className={styles.modalContent}
      overlayClassName={styles.modalBackdrop}
    >
      <div className={styles.card}>
        <div className={styles.leftPanel}>
          <div className={styles.header}>
            <img
              src={studentDetails.profilePicture}
              alt="Profile"
              className={styles.profilePic}
            />
          </div>
          <div className={styles.body}>
            <div className="row">
              <div className="col-md-6">
                <p className={styles.name}>Name: {studentDetails.name}</p>

                <p className={styles.detail}>
                  Board: {studentDetails.schoolExaminationBoard}
                </p>
                <p className={styles.detail}>Email: {studentDetails.email}</p>
              </div>
              <div className="col-md-6">
                <p className={styles.detail}>
                  Expected Marks: {studentDetails.schoolExpectedMarks}
                </p>
                <p className={styles.detail}>
                  Marks Obtained: {studentDetails.schoolMarks}
                </p>
                <p className={styles.detail}>
                  Pass Out Year: {studentDetails.schoolPassOutYear}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightPanel}>
          <iframe
            src={studentDetails.markSheet}
            className={styles.marksheet}
            title="Mark Sheet"
          ></iframe>
        </div>
      </div>
      <button onClick={onRequestClose} className={styles.closeButton}>
        &times;
      </button>
    </Modal>
  );
};

export default StudentDetailModal;

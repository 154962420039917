import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Grid from "@mui/material/Grid";
import { CourseDto } from "interfaces/course";
import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "sonner";
import fullStack from "../../../../assets/images/fullStack.webp";
import LoadingSpinner from "../../../../components/loading/LoadingSpinner";
import Error from "../../../../components/error/Error";
import {
  useAddMessageForCourseMutation,
  useGetAllCoursesQuery,
  usePurchaseCourseMutation,
} from "../../../../slices/courseSlice";
import CourseCard from "../CourseCard/CourseCard";
import styles from "./CourseGrid.module.css";
import { useNavigate } from 'react-router-dom';

import { styled, Typography } from "@mui/material";

Modal.setAppElement("#root");

const CourseGrid: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxWords = 20;
  const navigate = useNavigate();
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<CourseDto | null>(null);
  const [message, setMessage] = useState("");
  const [addMessageForCourse, { isLoading: isAddingMessage }] =
    useAddMessageForCourseMutation();
  const [purchaseCourse, { isLoading: isPurchasing }] =
    usePurchaseCourseMutation();

  const pageNumber = 0;
  const pageSize = 10;

  const { data, error, isLoading } = useGetAllCoursesQuery({
    pageNo: pageNumber,
    pageSize: pageSize,
  });

  const instructorImage =
    "https://studenthub.africa/app/uploads/careers/4b-Q_D2rFlXcIzkWk2zkCLW6g5BUj6tP.jpg";

  const StyledTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    textAlign: "center",
    fontWeight: 600,
    marginBottom: "30px",
  }));

  const StyledParagraph = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
  }));

  const IconButton = styled(Typography)(({ theme }) => ({
    width: "150px",
    textAlign: "center",
    borderRadius: "5px",
    padding: "5px",
    transition: "background-color 0.3s, transform 0.3s ease-in",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.light,
      transform: "scale(1.1)",
      color: "#ffffff",
    },
  }));

  const StyledDate = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    // fontStyle: "italic",
    fontSize: "1.15rem",
    color: "red",
  }));

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(undefined, {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  const esewaCall = (formData: any) => {
    var path = "https://rc-epay.esewa.com.np/api/epay/main/v2/form";
    var form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", path);

    for (var key in formData) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", formData[key]);
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();
  };

  const openModal = (course: CourseDto) => {
    setSelectedCourse(course);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const getDisplayText = (description: string) => {
    const words = description.split(" ");

    if (words.length > maxWords) {
      const displayText = isExpanded
        ? description
        : words.slice(0, maxWords).join(" ") + "...";
      return displayText;
    }

    return description;
  };

  // const handleMakePayment = async (course: CourseDto) => {
  //   if (course && course.id) {
  //     toast.promise(
  //       purchaseCourse({ courseId: course.id })
  //         .unwrap()
  //         .then((response) => {
  //           if (response?.data.esewaPaymentRequestBody) {
  //             const requestBody = response?.data.esewaPaymentRequestBody;
  //             esewaCall(requestBody);
  //           }
  //           throw new Error("Payment request body not available");
  //         }),
  //       {
  //         loading: "Processing...",
  //         success: "Payment completed successfully!",
  //         error: (err) => {
  //           if (err && err.data && err.data.message) {
  //             return `Course purchase failed! ${err.data.message}`;
  //           }
  //           return null;
  //         },
  //       }
  //     );
  //   }
  // };

  const handleMakePayment = (course: CourseDto) => {
    navigate('/payment', { state: { course } });
  };

  if (isLoading) return <LoadingSpinner />;
  if (error) return <Error/>;

  return (
    <>
      <Grid container spacing={2} className={styles.gridContainer}>
        {data?.data.content.map((course: CourseDto) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            key={course.id}
            className={styles.cardItem}
          >
            <CourseCard
              course={course}
              onCardClick={() => openModal(course)}
              onPurchase={() => handleMakePayment(course)}
            />
          </Grid>
        ))}
      </Grid>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
        contentLabel="Course Details"
      >
        <div className={styles.modalHeader}>
          <button onClick={closeModal} className={styles.closeButton}>
            &times;
          </button>
        </div>
        <div className="modalBody">
          {selectedCourse && (
            <div>
              <StyledTitle variant="h5">{selectedCourse.name}</StyledTitle>
              <div className={styles.flexbox}>
                <div className={styles.imageContainer}>
                  <img
                    src={selectedCourse.thumbnail}
                    alt={selectedCourse.name}
                    className={styles.courseImage}
                  />
                </div>
                <div className={styles.detailContainer}>
                  <StyledParagraph>
                    {selectedCourse.longDescription}
                  </StyledParagraph>

                  <h6 style={{ color: "blue" }}>
                    Course duration- {selectedCourse.lengthDescription}
                  </h6>
                  <StyledDate>
                    Final Registration Date:{" "}
                    {formatDate(selectedCourse.finalRegistrationDate)}
                  </StyledDate>
                  <h5>By- Instructor {selectedCourse.instructorName}</h5>
                  <div className={styles.aboutInstructor}>
                    <div className={styles.instructorImage}>
                      <img
                        src={selectedCourse.instructorProfileUrl ?? ""}
                        alt="Instructor"
                        className={styles.courseImage}
                      />
                    </div>
                    <div className={styles.instructorDetails}>
                      <p>
                        {getDisplayText(
                          selectedCourse.instructorDescription ??
                            "No description"
                        )}
                        {selectedCourse.instructorDescription &&
                          selectedCourse.instructorDescription?.split(" ")
                            .length > maxWords && (
                            <button
                              onClick={toggleText}
                              className={styles.showMoreButton}
                            >
                              {isExpanded ? " Show Less" : "...Show More"}
                            </button>
                          )}
                      </p>
                    </div>
                  </div>
                  <div className={styles.hyperlineButtons}>
                    {selectedCourse.timeTableUrl && (
                      <IconButton
                        color="primary"
                        onClick={() =>
                          window.open(selectedCourse.timeTableUrl, "_blank")
                        }
                      >
                        <OpenInNewIcon /> View Timetable
                      </IconButton>
                    )}

                    {selectedCourse.instructorDescriptionUrl && (
                      <IconButton
                        color="primary"
                        onClick={() =>
                          window.open(
                            selectedCourse.instructorDescriptionUrl,
                            "_blank"
                          )
                        }
                      >
                        <OpenInNewIcon /> View profile
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CourseGrid;

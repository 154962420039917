// Layout.tsx
import React from 'react';
import Footer from '../footer/Footer.tsx';
import styles from './Layout.module.css';
import { Toaster } from 'sonner';


const Layout: React.FC<{children: React.ReactNode}> = ({ children }) => {
  return (
    <div className={styles.layout}>
       <Toaster richColors />
      <main className={styles.content}>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;

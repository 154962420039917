import React, { useState } from "react";
import Modal from "react-modal";
import styles from "./bulkRemarksModal.module.css";

Modal.setAppElement("#root");

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (bulkRemarks: string) => void;
}

const BulkRemarksModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
    const [remarks, setRemarks] = useState("");
    const handleRemarksChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRemarks(e.target.value);
      };

      const handleSubmit = () => {
        onSubmit(remarks);
        onClose(); 
      };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modalContent}
      overlayClassName={styles.modalBackdrop}
    >
      <div className={styles.modalHeader}>
      <button onClick={onClose} className={styles.closeButton}>
        &times;
      </button>
      </div>
      <div className={styles.modalBody}>
        <span>Add Bulk Remarks For Selected Rows</span>
        <textarea value={remarks} onChange={handleRemarksChange} className={styles.textArea}></textarea>
        <button onClick={handleSubmit} className={styles.submitButton}>Submit</button>
        <div className={styles.tableContainer}>
        </div>
      
      </div>
    </Modal>
  );
};

export default BulkRemarksModal;

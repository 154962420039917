import React from "react";
import { LOGO_PNG_URL } from "../../constants/appConstants.ts";
import styles from "./login.module.css";

import { GoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import userAnimation from "../../assets/animation/userAnimation2.json";
import logo_inverted from '../../assets/images/logoWithHat_Inverted.png';
import { useVerifyGoogleTokenMutation } from "../../slices/authApiSlice.ts";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface ICredentialResponse {
  clientId: string | undefined;
  credential: string;
  select_by: string;
}

interface Slide {
  image: string;
  title: string;
  description: string;
}

const slides: Slide[] = [
  {
    image: "https://images.unsplash.com/photo-1555099962-4199c345e5dd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Slide 1",
    description: "Live Online Classes",
  },
  {
    image:
      "https://images.unsplash.com/photo-1484417894907-623942c8ee29?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Slide 2",
    description: "One Stop After Completing 10th",
  },
  {
    image:
      "https://images.unsplash.com/photo-1535982330050-f1c2fb79ff78?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Slide 3",
    description: "College Applications Made Easier",
  },
];

export default function Login() {
  const navigate = useNavigate();
  const [verifyGoogleToken, { isLoading, isSuccess, isError }] =
    useVerifyGoogleTokenMutation();

  const handleLogin = async (credentialResponse: ICredentialResponse) => {
    const token = credentialResponse.credential;
    try {
      const result = await verifyGoogleToken(token).unwrap();
      Cookies.set("X-DASHVERSE", result.data.sessionId, { expires: 1 });
      localStorage.setItem("firstSuccess", "true");
      window.location.href = "/dashboard";
    } catch (err) {
      const errorMessage =
        err.data?.message || err.message || "An unknown error occurred";
      toast.error(`Login failed! ${errorMessage}`);
      return;
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.logoSection}>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={1}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          style={{ width: "100%", height: "100%" }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className={styles.swiperSlide}>
              <img
                src={slide.image}
                alt={slide.title}
                className={styles.slideImage}
              />
              <div className={styles.slideContent}>
                <h2 className={styles.slideTitle}>
                  {" "}
                  <img
                    src={logo_inverted}
                    alt="App Logo"
                    className={styles.logo}
                  />
                </h2>
                <p className={styles.slideDescription}>{slide.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.signInSection}>
        <div className={styles.contentWrapper}>
          <Lottie loop={false} animationData={userAnimation} />
          <div
            className={`${styles.googleButtonWrapper} ${styles.googleButtonLarge}`}
          >
            <div className={styles.googleButtonWrapper}>
              <GoogleLogin shape="pill" onSuccess={handleLogin} />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <img
            src={LOGO_PNG_URL}
            alt="Dashverse Logo"
            className={styles.footerLogo}
          />
          <div className={styles.footerText}>
            <h3>Dashverse</h3>
            <p>Pvt. Ltd.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

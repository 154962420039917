import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { FilterProvider } from '../components/context/FilterContext';
import { UserProvider } from "../components/context/userContext";
import Layout from "../components/layout/Layout";
import { routes } from "../constants/routeConstants";
import AdminPage from "../pages/AdminPage/AdminPage";
import AdmissionPage from "../pages/AdmissionsPage/Admissions";
import AppliedAdmissionPage from "../pages/AppliedAdmissionPage/AppliedAdmission";
import CollegePage from "../pages/CollegePage/college";
import CoursePage from "../pages/CoursePage/CoursePage";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login/Login";
import MyCoursePage from "../pages/MyCoursesPage/MyCoursesPage";
import NewApplicationPage from "../pages/NewApplicationPage/NewApplicationPage";
import SuccessPage from "../pages/SuccessPage/SuccessPage";
import UserPage from "../pages/UserPage/user";
import { store } from "../store/store";
import { ProtectedAdmin, ProtectedRoute } from "../utils/protectedRoute";
import { createRoute } from "../utils/route";
import NotFoundPage from "./notFound/NotFound";
import Payment from "../pages/MyCoursesPage/components/Payment/Payment";

const App: React.FC = () => {

   //const clientId = "266434592650-6fjnev7bfeilal02f5sokkgeo6pbn14c.apps.googleusercontent.com"; //local
   const clientId = "266434592650-43m34ipcero2hdr2064rna0eafv6e3cd.apps.googleusercontent.com"; //prod

  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={clientId}>
        <UserProvider>
        <FilterProvider>
          <BrowserRouter>
            <Routes>
              <Route path={createRoute([routes.HOME])} element={<Layout><Login /></Layout>} />
              <Route path={createRoute([routes.COLLEGE])} element={<ProtectedRoute><Layout><CollegePage /></Layout></ProtectedRoute>} />
              <Route path={createRoute([routes.BRIDGE_COURSE])} element={<ProtectedRoute><Layout><CoursePage /></Layout></ProtectedRoute>} />
              <Route path={createRoute([routes.PROFILE])} element={<ProtectedRoute><Layout><UserPage /></Layout></ProtectedRoute>} />
              <Route path={createRoute([routes.APPLICATION])} element={<ProtectedRoute><Layout><NewApplicationPage /></Layout></ProtectedRoute>} />
              <Route path={createRoute([routes.ADMISSION])} element={<ProtectedRoute><Layout><AdmissionPage /></Layout></ProtectedRoute>} />
              <Route path={createRoute([routes.APPLIED])} element={<ProtectedRoute><Layout><AppliedAdmissionPage /></Layout></ProtectedRoute>} />
              <Route path={createRoute([routes.LOGIN])} element={<Layout><Login /></Layout>} />
              <Route path={createRoute([routes.MYCOURSES])} element={<ProtectedRoute><Layout><MyCoursePage /></Layout></ProtectedRoute>} />
              <Route path={createRoute([routes.DASHBOARD])} element={<ProtectedRoute><Layout><Dashboard /></Layout></ProtectedRoute>} />
              <Route path={createRoute([routes.ADMIN])} element={<ProtectedAdmin><Layout><AdminPage /></Layout></ProtectedAdmin>} />
              <Route path={createRoute([routes.PAYMENT])} element={<ProtectedRoute><Layout><Payment /></Layout></ProtectedRoute>} />
              <Route path="/successresponse" element={<Layout><SuccessPage /></Layout>} />
              <Route path="*" element={<Layout><NotFoundPage /></Layout>} />
            </Routes>
          </BrowserRouter>
        </FilterProvider>
        </UserProvider>
      </GoogleOAuthProvider>
    </Provider>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import "./sidebar.css";

import {
  DASHVERSE,
  LOGO_PNG_INVERTED_URL,
} from "../../constants/appConstants.ts";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { googleLogout } from "@react-oauth/google";
import { Navigate, useNavigate } from "react-router-dom";
import  { useLogoutUserMutation }  from "../../slices/authApiSlice";
import logo_inverted from '../../assets/images/logoWithHat_Inverted.png'
import {
  useGetProfileQuery,
  profileApiSlice,
} from "../../slices/profileApiSlice";

interface SideBarProps {
  menuItems?: string[];
  menuIcons?: string[];
  menuHrefs?: string[];
  logoUrl?: string;
  userName?: string;
  userId?: number;
  userProfileUrl?: string;
  userType?: string;
  currentTab: number;
  isSideBarOpen: (data: boolean) => void;
}

export default function SideBar(props: SideBarProps) {
  const [isButtonActive, setIsButtonActive] = useState(false);

  const navigate = useNavigate();
  const [logoutUser, {}] = useLogoutUserMutation();
  useEffect(() => {
    props.isSideBarOpen(isButtonActive);
  }, [isButtonActive, props]);

  useEffect(() => {
    const sidebar = document.querySelector(".sidebar");
    const closeBtn = document.querySelector("#btn");
    const searchBtn = document.querySelector(".bx-search");
    const logoImg = document.querySelector(".logo-icon");

    if (sidebar && closeBtn && searchBtn && logoImg) {
      const menuBtnChange = () => {
        if (sidebar.classList.contains("open")) {
          closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");
          // closeBtn.classList.replace("fa-bars", "fa-lines-leaning");
          closeBtn.classList.add("rotate-90");
          logoImg.classList.add("show-logo");
          setIsButtonActive(true);
        } else {
          closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");
          closeBtn.classList.replace("fa-lines-leaning", "fa-bars");
          closeBtn.classList.remove("rotate-90");
          logoImg.classList.remove("show-logo");
          setIsButtonActive(false);
        }
      };

      closeBtn.addEventListener("click", () => {
        sidebar.classList.toggle("open");
        menuBtnChange();
      });

      searchBtn.addEventListener("click", () => {
        sidebar.classList.toggle("open");
        menuBtnChange();
      });

      return () => {
        closeBtn.removeEventListener("click", () => {
          sidebar.classList.toggle("open");
          menuBtnChange();
        });

        searchBtn.removeEventListener("click", () => {
          sidebar.classList.toggle("open");
          menuBtnChange();
        });
      };
    }
  }, []);

  const handleLogout = async () => {
    try {
      await googleLogout();
      Cookies.remove("X-DASHVERSE");
      sessionStorage.clear();
      localStorage.clear();
      logoutUser();
      navigate("/login");
      toast.success("Logout successful");
    } catch (err) {
      toast.error(`Logout failed! ${err.data?.message || err.message || "An unknown error occurred"}`);
    }
  }
  

  const {
    menuItems,
    menuIcons,
    userName,
    userProfileUrl,
    userType,
    currentTab,
  } = props;

  const renderMenuItems = () => {
    if (!menuItems || !menuIcons) return null;

    return menuItems.map((menuItem, index) => {
      const isActive = index === currentTab;

      return (
        <li key={index} className={isActive ? "active" : ""}>
          <a href={props.menuHrefs ? props.menuHrefs[index] : "#"}>
            <i className={menuIcons[index]}></i>
            <span className="links_name">{menuItem}</span>
          </a>
          <span className="tooltip">{menuItem}</span>
        </li>
      );
    });
  };

  return (

      <div className="SideBarContainer">
        <div className="sidebar">
          <div className="logo-details">
            <img className="logo-icon" src={logo_inverted} alt="Logo" />
            <div className="logo_name">Eduverse</div>
            <i className="fa-solid fa-bars" id="btn"></i>
          </div>
          <ul className="nav-list">
            <li className="search-container">
              <i className="bx-search fa-solid fa-magnifying-glass"></i>
              <input type="text" placeholder="Search..." />
              <span className="tooltip">Search</span>
            </li>
            {renderMenuItems()}
            <li className="profile">
              <div className="profile-details">
                <img src={LOGO_PNG_INVERTED_URL} alt="profileImg" />
                <div className="name_job">
                  <div className="name">{userName}</div>
                  <div className="job">{userType}</div>
                </div>
              </div>
              <i className="fa-solid fa-right-from-bracket" id="log_out" onClick={handleLogout} style={{ cursor: 'pointer' }}></i> 
            </li>
          </ul>
        </div>
      </div>

  );
}
function dispatch(arg0: { payload: undefined; type: `${string}/resetApiState`; }) {
  throw new Error("Function not implemented.");
}


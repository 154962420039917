import React, { useState } from "react";
import SideBar from "../../components/sideBar/sidebar";
import {
  collegeMenuIcons,
  collegeMenuItems,
  collegeMenuHref,
  menuHref,
  menuIcons,
  menuItems,
  teacherMenuHref,
  teacherMenuIcons,
  teacherMenuItems
} from "../../constants/sideBarConstants";
import { useUser } from "../../components/context/userContext";
import { Header } from "../../components/header/Header";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import MyCourseLayout from "./components/MyCourseLayout/MyCourseLayout";
import { useGetMyCoursesQuery } from "../../slices/courseSlice";
import NoDataFound from "../../components/noDataFound/NoDataFound";
import Error from "../../components/error/Error"

export default function MyCoursePage() {
  const { user, name } = useUser();

  const isStudent = user?.isStudent || false;
  const isTeacher = user?.isTeacher || false;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const pageNumber = 0;
  const pageSize = 10;

  const {
    data: coursesData,
    isLoading: isLoadingCourses,
    isError: isCoursesError,
  } = useGetMyCoursesQuery({
    pageNo: pageNumber,
    pageSize: pageSize,
  });

  const toggleSidebar = (isOpen: boolean) => {
    setIsSidebarOpen(isOpen);
  };

  if (isLoadingCourses) {
    return <LoadingSpinner />;
  }

  if (isCoursesError) {
    return <div><Error/></div>;
  }

  const courses = coursesData?.data || [];

  return (
    <div className="page-container">
      <div className="sidebar-container">
        <SideBar
          menuItems={
            isStudent
              ? menuItems
              : isTeacher
              ? teacherMenuItems
              : collegeMenuItems
          }
          menuHrefs={
            isStudent ? menuHref : isTeacher ? teacherMenuHref : collegeMenuHref
          }
          menuIcons={
            isStudent
              ? menuIcons
              : isTeacher
              ? teacherMenuIcons
              : collegeMenuIcons
          }
          userName={name ? name : "Verse user"}
          userProfileUrl="https://preview.redd.it/mphoqu32zps71.png?auto=webp&s=b65bdbce745ba021669216c28fa454219ce08a35"
          currentTab={isStudent ? 6 : ( isTeacher ? 2 :3)}
          isSideBarOpen={setIsSidebarOpen}
        />
      </div>
      <div
        className={`content-container ${isSidebarOpen ? "sidebar-open" : ""}`}
      >
        <Header page="My Courses" />
        {(isStudent || isTeacher) && courses.length > 0 ? (
          <MyCourseLayout courses={courses} />
        ) : (
          <NoDataFound text="No content" />
        )}
      </div>
    </div>
  );
}

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState, useEffect } from "react";
import styles from "./Session.module.css";
import { Button } from "@mui/material";
import {
  useGetOngoingCourseSessionsQuery,
  useGetRegisterCourseSessionsQuery,
  useRegisterCourseSessionMutation,
  useJoinCourseSessionMutation
} from "../../slices/courseSlice";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import { toast } from "sonner";
import Error from "../../components/error/Error";
import { CourseSessionCreateDto } from "../../interfaces/courseSession";
import NoDataFound from "../../components/noDataFound/NoDataFound";
import { useNavigate } from "react-router-dom";

export default function Session({ pageNo = 0, pageSize = 25 }) {
  const [tabValue, setTabValue] = useState(0);

  const [registerSession, { isLoading: isregisterSessionLoading, error: registerSessionError }] =
  useRegisterCourseSessionMutation();

  const [joinSession, { isLoading: isjoinSessionLoading, error: joinSessionError }] =
  useJoinCourseSessionMutation();

  const { data, error, isLoading } = useGetOngoingCourseSessionsQuery({
    pageNo,
    pageSize,
  });

  const {
    data: registerData,
    error: registerError,
    isLoading: registerLoading,
  } = useGetRegisterCourseSessionsQuery({ pageNo, pageSize });

  if (isLoading || registerLoading ) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error || registerError ) {
    return (
      <div>
        <Error />
      </div>
    );
  }



  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  async function registerCourse(course: CourseSessionCreateDto) {
    try {
      await registerSession({ courseSessionId: course.courseSessionId }).unwrap();
      toast.success("Session Registered successfully!");
    } catch (err) {
      const errorMessage = err.data?.message || err.message || "An unknown error occurred";
      toast.error(`Failed to register session: ${errorMessage.split(' (')[0]}`);
    }
  }

  function epochToDateAndTime(epoch) {
    if (!epoch) return "Invalid date";
    const date = new Date(epoch);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  }
  
  

  async function joinMeet(course: CourseSessionCreateDto) {
    try {
      const result  = await joinSession({ courseSessionId: course.courseSessionId }).unwrap();
      toast.success("Joining...");
      let url = result.data;
      if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'https://' + url;
      }
      if (url) {
        window.open(url, '_blank');
      } else {
        toast.error("No valid URL returned.");
      }
    } catch (err) {
      const errorMessage = err.data?.message || err.message || "An unknown error occurred";
      toast.error(`Failed to join session: ${errorMessage.split(' (')[0]}`);
    }
  }

  return (
    <div>
      <div className={styles.tabContainer}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="course tabs"
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Register Session" />
          <Tab label="Ongoing Session" />
        </Tabs>

        {tabValue === 0 && (
        <div className={styles.ongoingSession}>
          {registerData?.data?.content?.length === 0 ? (
            <NoDataFound text="No Sessions To Register" />
          ) : (
            registerData.data.content.map(
              (course: CourseSessionCreateDto) => (
                <div key={course.courseSessionId} className={styles.courseItem}>
                  <span className={styles.courseTitle}>
                    {course.sessionName}
                  </span>
                  <div className={styles.timings}>
                      <span className={styles.time}>
                        {epochToDateAndTime(course.courseSessionStart)}
                      </span>
                      <span className={styles.time}>
                        -
                      </span>
                      <span className={styles.time}>
                        {epochToDateAndTime(course.courseSessionEnd)}
                      </span>
                  <Button
                    variant="contained"
                    color="primary"
                    href={course.meetLink}
                    target="_blank"
                    onClick={() => registerCourse(course)}
                    rel="noopener noreferrer"
                    disabled={course.isRegistered}
                  >
                    {course.isRegistered ? "Registered" : "Register"}
                  </Button>
                  </div>
                </div>
              )
            )
          )}
        </div>
      )}

        {tabValue === 1 && (
          <div className={styles.registerSession}>
            {tabValue === 1 && (
              <div className={styles.registerSession}>
                {data?.data?.content?.length === 0 ? (
                  <NoDataFound text="No Active Sessions"/>
                ) : (
                  data?.data?.content?.map((course: CourseSessionCreateDto) => (
                    <div
                      key={course.courseSessionId}
                      className={styles.courseItem}
                    >
                      <span className={styles.courseTitle}>
                        {course.sessionName}
                      </span>
                      <div className={styles.timings}>
                      <span className={styles.time}>
                        {epochToDateAndTime(course.courseSessionStart)}
                      </span>
                      <span className={styles.time}>
                        -
                      </span>
                      <span className={styles.time}>
                        {epochToDateAndTime(course.courseSessionEnd)}
                      </span>
                      
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => joinMeet(course)}
                      > 
                        Join
                      </Button>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

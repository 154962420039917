export const routes = {
    HOME: '/',
    COLLEGE: '/institute',
    USER: '/user',
    ID: ':id',
    DETAIL: '/detail',
    PROFILE: '/profile',
    APPLICATION: '/newapplication',
    ADMISSION: '/admissions',
    APPLIED: '/applied',
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    BRIDGE_COURSE: '/courses',
    MYCOURSES: '/mycourses',
    ADMIN: '/admin',
    PAYMENT: '/payment'
}

export const BaseUrl = "https://edv-api.eduversenepal.com/api/v1"

//export const BaseUrl = "http://localhost:6967/api/v1"
export const esewaBaseUrl = "https://rc-epay.esewa.com.np/api"
export const sucessUrl = "https://www.eduversenepal.com/successresponse/"